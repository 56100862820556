import React, {
	type ReactNode,
	useCallback,
	useLayoutEffect,
	useRef,
	type MouseEvent,
} from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_CardList$key as CardListType } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_CardList.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types';
import { getMaxIssuesPerPage } from '../../../../common/constants';
import { isNonNullish, isNormalClick } from '../../../../common/utils';
import { markOnce, marks } from '../../../../common/utils/performance-analytics';
import {
	useSelectedIssueIndex,
	useFocusedIssueIndex,
} from '../../../../controllers/selected-issue-state';
import Card, { type CardProps, EmptyCard } from './card';
import CardEmptyList from './card-empty-list';
import CardSkeleton from './card-skeleton';
import KeyboardShortcuts from './keyboard-shortcuts';
import messages from './messages';

export type CardListProps = {
	fragment: CardListType;
	loading: boolean;
	onNavigateToIssue: (issueKey: string) => void;
};

type CardWithAnalyticsProps = {
	fragment: CardProps['fragment'] | undefined;
	index: number;
};

const BLANK_CARD_ANALYTICS_ID = 'blankIssueCard';
const ISSUE_CARD_ANALYTICS_ID = 'issueCard';

const CardWithAnalytics = ({ index, fragment }: CardWithAnalyticsProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [selectedIssueIndex, { setSelectedIssueByIndex }] = useSelectedIssueIndex();
	const [focusedIssueIndex, { setFocusedIssueByIndex }] = useFocusedIssueIndex();
	const isSelected = selectedIssueIndex === index;
	const isFocused = focusedIssueIndex === index;
	const cardRef = useRef<HTMLAnchorElement>(null);

	useLayoutEffect(() => {
		if (isSelected) {
			// Scroll to the card whenever it is selected and focus on it
			cardRef.current?.scrollIntoView && cardRef.current?.scrollIntoView({ block: 'nearest' });
			isFocused && cardRef.current?.focus();
		}
	}, [isSelected, isFocused]);

	const onClickWithAnalytics = useCallback(
		(event: MouseEvent) => {
			const id = fragment ? ISSUE_CARD_ANALYTICS_ID : BLANK_CARD_ANALYTICS_ID;
			const attributes = {
				keyboardShortcut: false,
				issueIndex: index + 1,
			};

			if (isNormalClick(event)) {
				event.preventDefault();
				const analyticsEvent = createAnalyticsEvent({
					action: 'selected',
					actionSubject: 'card',
				});
				fireUIAnalytics(analyticsEvent, id, attributes);
			} else if (fg('nin_critical_accessibility_issues')) {
				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'card',
				});
				fireUIAnalytics(analyticsEvent, id, attributes);
			}
		},
		[createAnalyticsEvent, fragment, index],
	);

	const onCardFocus = useCallback(() => {
		setFocusedIssueByIndex(index);
		setSelectedIssueByIndex(index, true);
	}, [index, setFocusedIssueByIndex, setSelectedIssueByIndex]);

	const onContextMenuHandler = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'contextMenu clicked',
			actionSubject: 'card',
		});

		fireUIAnalytics(analyticsEvent, ISSUE_CARD_ANALYTICS_ID, {
			keyboardShortcut: false,
			issueIndex: index + 1,
		});
	}, [createAnalyticsEvent, index]);

	return fragment == null ? (
		<EmptyCard ref={cardRef} isSelected={isSelected} onClick={onClickWithAnalytics} />
	) : (
		<Card
			ref={cardRef}
			fragment={fragment}
			onClick={onClickWithAnalytics}
			onFocus={onCardFocus}
			isSelected={isSelected}
			onContextMenu={onContextMenuHandler}
		/>
	);
};

const CardList = ({ fragment, loading, onNavigateToIssue }: CardListProps) => {
	markOnce(marks.ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_START);

	// Analytics
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// Main
	const [focusedIssueIndex, { setFocusedIssueByIndex }] = useFocusedIssueIndex();

	const maxIssuesPerPage = getMaxIssuesPerPage();

	const { formatMessage } = useIntl();

	useLayoutEffect(() => {
		markOnce(marks.ISSUE_RESULTS_DETAIL_VIEW_CARD_LIST_END);
	}, []);

	const fragmentData = useFragment<CardListType>(
		graphql`
			fragment main_issueNavigator_CardList on JiraIssueConnection {
				edges {
					node {
						...main_issueNavigator_Card_fragment
					}
				}
			}
		`,
		fragment,
	);

	const onIssueChangeEvent = useCallback(
		(issueKey: IssueKey, issueIndex: number | null) => {
			if (issueIndex !== null) {
				setFocusedIssueByIndex(issueIndex);
			}
			const id = issueKey ? ISSUE_CARD_ANALYTICS_ID : BLANK_CARD_ANALYTICS_ID;

			const analyticsEvent = createAnalyticsEvent({
				action: 'selected',
				actionSubject: 'card',
			});

			fireUIAnalytics(analyticsEvent, id, {
				keyboardShortcut: true,
				issueIndex: issueIndex === null ? null : issueIndex + 1,
			});
		},
		[createAnalyticsEvent, setFocusedIssueByIndex],
	);

	if (loading) {
		return (
			<>
				{[...Array(maxIssuesPerPage)].map<ReactNode>((__, index) => (
					<CardSkeleton key={index} issueIndex={index} issueCount={maxIssuesPerPage} />
				))}
			</>
		);
	}

	const cards: ReactNode[] =
		fragmentData?.edges
			?.filter(isNonNullish)
			.map((edge, index: number) => (
				<CardWithAnalytics key={index} index={index} fragment={edge.node ?? undefined} />
			)) ?? [];

	if (cards.length === 0) {
		return <CardEmptyList />;
	}

	return (
		<>
			<KeyboardShortcuts
				focusedIssueIndex={focusedIssueIndex}
				onIssueChangeEvent={onIssueChangeEvent}
				onNavigateToIssue={onNavigateToIssue}
				disableListControls={loading}
			/>
			<StyledList role="listbox" aria-label={formatMessage(messages.issues)}>
				{cards}
			</StyledList>
		</>
	);
};

export default CardList;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledList = styled.ul({
	listStyle: 'none',
	paddingLeft: '0px',
});
