/**
 * @generated SignedSource<<799ba38a75f146ef55bef017268bb473>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_Card_fragment$data = {
  readonly fieldSetsForIssueSearchView: {
    readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Card_fieldSetsForIssueSearchView">;
  };
  readonly key: string;
  readonly " $fragmentType": "main_issueNavigator_Card_fragment";
};
export type main_issueNavigator_Card_fragment$key = {
  readonly " $data"?: main_issueNavigator_Card_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Card_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "filterId"
    },
    {
      "kind": "RootArgument",
      "name": "namespace"
    },
    {
      "kind": "RootArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_Card_fragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "key"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Variable",
            "name": "filterId",
            "variableName": "filterId"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "amountOfColumns"
          },
          {
            "kind": "Variable",
            "name": "namespace",
            "variableName": "namespace"
          },
          {
            "kind": "Variable",
            "name": "viewId",
            "variableName": "viewId"
          }
        ],
        "concreteType": "JiraIssueFieldSetConnection",
        "kind": "LinkedField",
        "name": "fieldSetsForIssueSearchView",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "main_issueNavigator_Card_fieldSetsForIssueSearchView"
          }
        ]
      },
      "action": "THROW",
      "path": "fieldSetsForIssueSearchView"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "74c85821f3ec64a36da871208deee94f";

export default node;
