import React from 'react';
import { styled } from '@compiled/react';
import { NativeIssueTableSkeleton } from '@atlassian/jira-skeletons';

export const ListViewSkeleton = () => (
	<StyledListViewContainer>
		<NativeIssueTableSkeleton />
	</StyledListViewContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledListViewContainer = styled.div({
	overflow: 'hidden',
});
