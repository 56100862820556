import { useEffect, useState } from 'react';
import type { JqlUtils as JqlUtilsType } from '@atlassian/jira-jql-utils';

export const AsyncJqlUtils = async (): Promise<JqlUtilsType> => {
	const module = await import(
		/* webpackChunkName: "async-jira-jql-utils" */ '@atlassian/jira-jql-utils'
	);
	const { JqlUtils } = module;
	return new JqlUtils();
};

export const useJqlUtils = (): JqlUtilsType | undefined => {
	const [Utils, setUtils] = useState<JqlUtilsType | undefined>();
	const asyncEffect = async () => {
		const ResolvedUtils = await AsyncJqlUtils();
		setUtils(ResolvedUtils);
	};

	useEffect(() => {
		asyncEffect();
	}, []);

	return Utils;
};
