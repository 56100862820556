import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

/**
 * Currently feature gates doens't support prerequisites with LD
 * This is a workaround till we cleanup all our LD feature flags
 * https://atlassian.slack.com/archives/C06PDP3LZ25/p1721095935311979
 */
export const isJqlBuilderTextSearchInputConcurrencyFix = () =>
	ff('nin_expose_text_option_jql_builder_4kwzo') &&
	fg('jql-builder-text-search-input-concurrency-fix');
