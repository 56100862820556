import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { isJqlBuilderTextSearchInputConcurrencyFix } from '@atlassian/jira-jql-builder-common';

/**
 * Get feature flags and feature gates for the issue navigator performance metrics
 */
export const getIssueNavigatorFFAndFG = () => ({
	isCohorted: ff('nin.global-scope_aqec8'),
	isTableRedesignEnabled: ff('jsv-one-issue-table-spreadsheet-ui_ygrnf'),
	isNinExposeTextOption: ff('nin_expose_text_option_jql_builder_4kwzo'),
	isNinIssueLinksFieldNativeCellEnabled: ff(
		'extend-popup-cell-with-custom-override-content-and-parameters_obikd',
	),
	isOrderPickerFfEnabled: ff('jql-builder-basic-order-by-select_n25vm'),
	isNinShowFieldOptionsAsOnlyUnselectableEnabled: ff(
		'nin-show-field-options-as-only-unselectable_0s1y2',
	),
	isNinShowOutOfContextFieldsAsOnlyUnselectableEnabled: ff(
		'nin-enable-show-out-of-context-fields-as-only-unselectable_lmsy7',
	),
	isSoftDeprecateEpicLinkInNinEnabled: ff('com.atlassian.rm.jpo.deprecate-epic-link-nin'),
	isSoftDeprecateParentLinkInNinEnabled: ff('com.atlassian.rm.jpo.deprecate-parent-link-nin'),
	isJqlBuilderTextSearchInputConcurrencyFix: isJqlBuilderTextSearchInputConcurrencyFix(),
	isJqlBuilderLoadBasicSync: fg('jql-builder-load-basic-sync'),
	isJscM1ApiUpdatesEnabled: fg('jira_spreadsheet_component_m1_api_updates'),
});
