import React, { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useIssueSearchQuery } from '../../../services/issue-search-query';
import messages from './messages';

const RefreshButton = () => {
	const { isRefreshing, onIssueSearchRefresh } = useIssueSearchQuery();

	const handleClick = useCallback(
		(_: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'reloadIssueListButton');
			onIssueSearchRefresh();
		},
		[onIssueSearchRefresh],
	);
	const { formatMessage } = useIntl();

	return (
		<IconButton
			appearance="subtle"
			onClick={handleClick}
			testId="issue-navigator.common.ui.refresh-button.refresh-button"
			label={formatMessage(messages.tooltip)}
			icon={(iconProps) => <RefreshIcon {...iconProps} size="small" />}
			isLoading={isRefreshing}
		/>
	);
};

export default RefreshButton;
