import React, {
	type ComponentType,
	useCallback,
	useLayoutEffect,
	type ReactNode,
	useMemo,
	useRef,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
} from 'react';
import { css, styled } from '@compiled/react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { PermissionErrorView, UnknownErrorView } from '@atlassian/jira-issue-view-errors';
import {
	ChangeEventTypes,
	type ChangeEvent,
} from '@atlassian/jira-issue-view-model/src/change-type';
import { IssueBoundary } from '@atlassian/jira-issue-view/src/async';
import type IssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/issue-app';
import { getIssueContainerMaxWidth } from '@atlassian/jira-issue-view/src/views/issue-details/issue-layout/constants';
import { IssueMutation, MutationSource, useEcClient } from '@atlassian/jira-jsis-ec-client';
import {
	useAnalyticsEvents,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_IssueAppWithData$key as IssueAppKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueAppWithData.graphql';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types';
import { SpaStatePageErrorReady } from '@atlassian/jira-spa-state-controller';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useStartNinIssueAppAnalytics } from '../../../controllers/issue-app-page-segment-analytics';
import {
	selectionState,
	useIssueSelectionState,
	useSelectedIssueDebouncedState,
	useSelectedIssueStateActions,
	// eslint-disable-next-line jira/import/no-restricted-paths
} from '../../../controllers/selected-issue-state';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useIssueSearchQuery } from '../../../services/issue-search-query';
import { ANALYTICS_SOURCE } from '../../constants';
import { markOnce, marks } from '../../utils/performance-analytics';
import HeaderActions from './header-actions';

const isEcClientEnabled = () => {
	const experiences: string[] = getFeatureFlagValue<string>(
		'odin.ec.client.integration.issueview',
		'',
	).split(',');

	return Array.isArray(experiences) && experiences.includes('nin-issue-view');
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncIssueAppComponent = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
		),
	{ ssr: false },
);

type IssueAppProps = JSX.LibraryManagedAttributes<
	typeof IssueAppType,
	ComponentProps<typeof IssueAppType>
>;

type OnIssueKeyChangeArgument = {
	fromIssueKey: IssueKey;
	toIssueKey: IssueKey;
	meta: {
		location: string;
	};
};

type IssueAppWithBoundaryProps = {
	/**
	 * Flag to adjust skeleton spacing based on whether the component is in an embedded or full page layout.
	 */
	isEmbedView: boolean;
} & IssueAppProps;

const AsyncIssueAppUsingLoadingPhases = ({ isEmbedView, ...rest }: IssueAppWithBoundaryProps) => (
	<IssueBoundary
		packageName="issue-navigator"
		isEmbedView={isEmbedView}
		render={() => <UnknownErrorView />}
	>
		<AsyncIssueAppComponent {...rest} />
	</IssueBoundary>
);

const issueCardChangeExcludedEventTypes: string[] = [
	ChangeEventTypes.FIELD_CHANGE_REQUESTED,
	ChangeEventTypes.FIELD_CHANGE_FAILED,
	ChangeEventTypes.CHILD_ISSUE_ADDED,
	ChangeEventTypes.ISSUE_CHILDREN_ORDER_CHANGED,
];
export type IssueAppWithDataProps = {
	/**
	 * Flag to adjust the container spacing based on whether the component is in an embedded or full page layout.
	 */
	isEmbedView: boolean;
	/**
	 * Relay fragment for issue search data.
	 */
	issueResults: IssueAppKey | null;
	/**
	 * Injected IssueApp dependency.
	 */
	IssueApp?: ComponentType<IssueAppWithBoundaryProps>;
	/**
	 * Optional react node to render in the header above the issue app.
	 */
	extraHeaderActions?: ReactNode;
};

const IssueAppWithData = ({
	IssueApp = AsyncIssueAppUsingLoadingPhases,
	extraHeaderActions,
	isEmbedView,
	issueResults,
}: IssueAppWithDataProps) => {
	markOnce(marks.ISSUE_APP_START);
	useLayoutEffect(() => {
		markOnce(marks.ISSUE_APP_END);
	}, []);

	const [selectedIssueKey] = useSelectedIssueDebouncedState();

	const [, { deselectIssue, exitFullPageIssueAppMode, setSelectedIssueByKey }] =
		useSelectedIssueStateActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let saveIssueMutationToCache: any;

	if (isEcClientEnabled()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		saveIssueMutationToCache = useEcClient().saveIssueMutationToCache;
	}

	const issueResultsData = useFragment<IssueAppKey>(
		graphql`
			fragment main_issueNavigator_IssueAppWithData on JiraIssueConnection {
				...headerActions_issueNavigator
			}
		`,
		issueResults,
	);

	const { onInteractive } = useStartNinIssueAppAnalytics(selectedIssueKey);

	const issueAppMetrics = useMemo(
		() => ({
			onInteractive,
		}),
		[onInteractive],
	);

	const { onDeleteIssue, onIssueByFieldsRefetch } = useIssueSearchQuery();

	const refetchCallback = useRef(onIssueByFieldsRefetch);
	refetchCallback.current = onIssueByFieldsRefetch;

	const onIssueDeleteSuccess = useCallback(
		({ issueKey: deletedIssueKey }: { issueKey: string }) => {
			const issueKeyToSelect = onDeleteIssue(deletedIssueKey);
			if (!issueKeyToSelect.length) {
				deselectIssue();
				// Return to list view if we were in full page mode
				exitFullPageIssueAppMode();
			} else {
				setSelectedIssueByKey(toIssueKey(issueKeyToSelect));
			}
		},
		[deselectIssue, exitFullPageIssueAppMode, onDeleteIssue, setSelectedIssueByKey],
	);

	const onIssueKeyChange = useCallback(
		(issueKeys: OnIssueKeyChangeArgument) => {
			setSelectedIssueByKey(issueKeys.toIssueKey);
		},
		[setSelectedIssueByKey],
	);

	const [issueSelection] = useIssueSelectionState();

	// When we're in non-embedded view (i.e. full page) we need to define a max width to ensure correct full page
	// styling within the issue app.
	const maxWidth = !isEmbedView ? getIssueContainerMaxWidth() : undefined;

	if (issueSelection === selectionState.BLANK_ISSUE_SELECTED) {
		return (
			<IssueAppContainer isEmbedView={isEmbedView}>
				<HeaderActions
					issueResults={issueResultsData}
					extraHeaderActions={extraHeaderActions}
					isEmbedView={isEmbedView}
					maxWidth={maxWidth}
				/>
				<PermissionErrorView />
			</IssueAppContainer>
		);
	}
	if (!selectedIssueKey) {
		return <SpaStatePageErrorReady />;
	}

	return (
		<IssueAppContainer isEmbedView={isEmbedView}>
			<HeaderActions
				issueResults={issueResultsData}
				extraHeaderActions={extraHeaderActions}
				isEmbedView={isEmbedView}
				maxWidth={maxWidth}
			/>
			<IssueAppHeightAdjustment>
				<ContextualAnalyticsData sourceName="issueNavigatorEmbeddedIssueView" sourceType={SCREEN}>
					<IssueApp
						metrics={issueAppMetrics}
						key={selectedIssueKey}
						isEmbedView={isEmbedView}
						analyticsSource={ANALYTICS_SOURCE}
						issueKey={toIssueKey(selectedIssueKey)}
						issueMaxWidth={maxWidth}
						onIssueKeyChange={onIssueKeyChange}
						onChange={(event: ChangeEvent) => {
							if (event && !issueCardChangeExcludedEventTypes.includes(event.type)) {
								refetchCallback.current(String(selectedIssueKey));
							}

							if (event?.type === 'FIELD_CHANGED') {
								const analyticsEventObj = createAnalyticsEvent({});

								const analyticsDataKey = {
									analyticsEventObj,
									analyticsMetadata: {
										scenario: 'nin-issue-view',
									},
								};

								if (isEcClientEnabled()) {
									saveIssueMutationToCache(
										new IssueMutation(event?.issueId, MutationSource.UPDATE),
										analyticsDataKey,
										{
											errorMsg: 'FAILED_NIN_ISSUEVIEW',
											error: true,
										},
									);
								}
							}
						}}
						onIssueDeleteSuccess={onIssueDeleteSuccess}
						shouldSetInitialFocus={false}
					/>
				</ContextualAnalyticsData>
			</IssueAppHeightAdjustment>
		</IssueAppContainer>
	);
};

export default IssueAppWithData;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueAppContainer = styled.div<{
	isEmbedView: boolean;
}>(
	{
		position: 'relative',
		flexGrow: 1,
		height: '100%',
		/* Setting the min width to 0 allows the div to grow/shrink to the size of the flex parent, instead of being based 
    on the intrinsic content size. */
		minWidth: 0,
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEmbedView }) =>
		!isEmbedView
			? /* When we're not in embedded view (i.e. full page mode) we need additional padding on top of our container. */
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					paddingTop: token('space.400', '32px'),
				})
			: undefined,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueAppHeightAdjustment = styled.div({
	height: '100%',
	/* Setting the min-height to 0 allows the div to grow/shrink to the size of the flex parent, instead of being based 
    on the intrinsic content height. */
	minHeight: 0,
});
