/**
 * @generated SignedSource<<82a0bbdfb49e3e57e7b008772adacdee>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_CardList$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Card_fragment">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_CardList";
};
export type main_issueNavigator_CardList$key = {
  readonly " $data"?: main_issueNavigator_CardList$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_CardList">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_CardList",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "main_issueNavigator_Card_fragment"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "8ff286c4c7d319d2f77b839644584988";

export default node;
