import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import type { groupGrant_issueNavigatorCustomFilters$key } from '@atlassian/jira-relay/src/__generated__/groupGrant_issueNavigatorCustomFilters.graphql';
import { SectionBody } from '../styled';
import messages from './messages';

type Props = {
	entity: groupGrant_issueNavigatorCustomFilters$key;
};

export const GroupGrant = ({ entity }: Props) => {
	const { formatMessage } = useIntl();

	const groupGrant = useFragment(
		graphql`
			fragment groupGrant_issueNavigatorCustomFilters on JiraShareableEntityGroupGrant {
				group {
					name
				}
			}
		`,
		entity,
	);

	return (
		<SectionBody>
			{groupGrant.group &&
				groupGrant.group?.name !== undefined &&
				formatMessage(messages.groupName, {
					name: groupGrant.group?.name,
				})}
		</SectionBody>
	);
};
