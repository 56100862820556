import { defineMessages } from '@atlassian/jira-intl'; // TODO - Port i18n string translations to new module ID keys
/* eslint-disable jira/i18n/id-named-by-package */

export default defineMessages({
	infoText: {
		id: 'issue-navigator.navigator.content.issue-list.issue-card-skeleton.error-info.info-text',
		defaultMessage: "We couldn't load this issue.",
		description: '',
	},
	reloadIssue: {
		id: 'issue-navigator.navigator.content.issue-list.issue-card-skeleton.error-info.reload-issue',
		defaultMessage: 'Reload issue',
		description: '',
	},
});
