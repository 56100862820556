import React, { useCallback, type MouseEvent, forwardRef } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ChevronDown from '@atlaskit/icon/glyph/chevron-down';
import Tooltip from '@atlaskit/tooltip';
import DisabledButtonWithTooltip from '@atlassian/jira-disabled-button-with-tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useSelectedIssueStateActions } from '../../../../../../controllers/selected-issue-state';
import messages from './messages';

export type Props = {
	totalResultCount: number;
	/**
	 * Position of the currently selected issue in the search results.
	 */
	selectedIssuePosition: number;
	isCappingIssueSearchResult: boolean;
};

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	<StyledTooltipTag ref={ref as (instance: HTMLElement | null) => void} {...props} />
));

const IssuePaginationNextButton = ({
	selectedIssuePosition,
	totalResultCount,
	isCappingIssueSearchResult,
}: Props) => {
	const { formatMessage } = useIntl();

	const isDisabled = selectedIssuePosition === totalResultCount;
	const [, { getNextIssue, selectNextIssue }] = useSelectedIssueStateActions();
	const { issueKey } = getNextIssue();
	const nextIssueMessage = issueKey
		? formatMessage(messages.nextIssueTooltip, { issueKey })
		: formatMessage(messages.nextIssueWithoutKeyTooltip);

	const onClick = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			if (selectNextIssue()) {
				fireUIAnalytics(analyticsEvent, 'nextIssue');
			}
		},
		[selectNextIssue],
	);

	return isDisabled && isCappingIssueSearchResult ? (
		<Tooltip content={formatMessage(messages.disabledButtonTooltip)} tag={TooltipTag}>
			<DisabledButtonWithTooltip
				tabIndex={0}
				isDisabled
				aria-label={formatMessage(messages.disabledButtonTooltip)}
				data-testid="issue-navigator.common.ui.issue-app.header-actions.issue-pagination.next-button.next-disabled-button"
			>
				<Button
					appearance="subtle"
					testId="issue-navigator.common.ui.issue-app.header-actions.issue-pagination.next-button"
					iconAfter={<ChevronDown size="medium" label="" />}
					isDisabled={isDisabled}
					onClick={onClick}
					spacing="none"
				/>
			</DisabledButtonWithTooltip>
		</Tooltip>
	) : (
		<Tooltip content={isDisabled ? null : nextIssueMessage}>
			{(tooltipProps) => (
				<Button
					appearance="subtle"
					{...tooltipProps}
					testId="issue-navigator.common.ui.issue-app.header-actions.issue-pagination.next-button"
					iconAfter={<ChevronDown size="medium" label="" />}
					isDisabled={isDisabled}
					onClick={onClick}
					spacing="none"
				/>
			)}
		</Tooltip>
	);
};

export default IssuePaginationNextButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTooltipTag = styled.div({
	display: 'inline-flex',
});
