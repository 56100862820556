import React, { type ReactNode, useCallback, useRef } from 'react';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import Tooltip from '@atlaskit/tooltip';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';
import { EntryKeys } from '../../constants';
import { useFeedbackCollectorActions } from '../../controllers';
import { messages } from './messages';
import { StyledButton } from './styled';
import type { ButtonAppearance, FeedbackCollectorButtonProps } from './types';

const TooltipWrapper = ({
	children,
	appearance,
	customLabel,
}: {
	children: ReactNode;
	appearance: ButtonAppearance;
	customLabel?: string;
}) => {
	const { formatMessage } = useIntl();
	if (appearance === 'icon') {
		return (
			<Tooltip content={customLabel || formatMessage(messages.leaveFeedback)} position="top">
				{children}
			</Tooltip>
		);
	}
	return <>{children}</>;
};

/**
 * Provides a user interface component that invites users to submit feedback. This button is adaptable in style to fit
 * various UI contexts and leverages internationalization for accessible labeling. When interacted with, it initiates
 * the feedback collection workflow, guiding the user through the feedback submission process. The component
 * is designed with extensibility in mind, allowing for customized appearances and labels to enhance user experience.
 */
export const FeedbackCollectorButtonOld = ({
	buttonAppearance = 'normal',
	testId,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	icon: Icon = FeedbackIcon,
	feedbackButtonCustomLabel,
	...feedbackCollectorProps
}: FeedbackCollectorButtonProps) => {
	const { formatMessage } = useIntl();
	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const onOpen = useCallback(
		() =>
			openFeedbackCollector({
				...feedbackCollectorProps,
				triggerRef: buttonRef,
			}),
		[feedbackCollectorProps, openFeedbackCollector],
	);
	const displayAsLink = buttonAppearance === 'link';
	return (
		<TooltipWrapper appearance={buttonAppearance} customLabel={feedbackButtonCustomLabel}>
			<StyledButton
				testId={testId}
				ref={buttonRef}
				appearance={displayAsLink ? 'link' : 'subtle'}
				isHighlight={buttonAppearance === 'highlight'}
				spacing={displayAsLink ? 'none' : 'default'}
				onClick={onOpen}
				iconBefore={displayAsLink ? undefined : <Icon label="" />}
				aria-label={feedbackButtonCustomLabel || formatMessage(messages.leaveFeedback)}
			>
				{buttonAppearance !== 'icon' &&
					(feedbackButtonCustomLabel || formatMessage(messages.leaveFeedback))}
			</StyledButton>
		</TooltipWrapper>
	);
};

const FeedbackCollectorButtonNew = ({
	buttonAppearance = 'normal',
	testId,
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	icon: Icon = FeedbackIcon,
	feedbackButtonCustomLabel,
	...feedbackCollectorProps
}: FeedbackCollectorButtonProps) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const onOpen = useCallback(() => {
		// Check if feedbackContext already contains the cloud ID entry
		const hasCloudId = feedbackCollectorProps?.feedbackContext?.some(
			(entry) => entry.id === EntryKeys.CLOUD_ID,
		);
		// If not, add cloud id to feedback context
		const updatedFeedbackContext = hasCloudId
			? feedbackCollectorProps.feedbackContext
			: [
					...(feedbackCollectorProps.feedbackContext || []),
					{
						id: EntryKeys.CLOUD_ID,
						value: cloudId,
					},
				];
		openFeedbackCollector({
			...feedbackCollectorProps,
			feedbackContext: updatedFeedbackContext,
			triggerRef: buttonRef,
		});
	}, [cloudId, feedbackCollectorProps, openFeedbackCollector]);

	const displayAsLink = buttonAppearance === 'link';
	return (
		<TooltipWrapper appearance={buttonAppearance} customLabel={feedbackButtonCustomLabel}>
			<StyledButton
				testId={testId}
				ref={buttonRef}
				appearance={displayAsLink ? 'link' : 'subtle'}
				isHighlight={buttonAppearance === 'highlight'}
				spacing={displayAsLink ? 'none' : 'default'}
				onClick={onOpen}
				iconBefore={displayAsLink ? undefined : <Icon label="" />}
				aria-label={feedbackButtonCustomLabel || formatMessage(messages.leaveFeedback)}
			>
				{buttonAppearance !== 'icon' &&
					(feedbackButtonCustomLabel || formatMessage(messages.leaveFeedback))}
			</StyledButton>
		</TooltipWrapper>
	);
};

export const FeedbackCollectorButton = componentWithFF(
	'jff.magma.passing-cloudid-in-feedback-button_hif5t',
	FeedbackCollectorButtonNew,
	FeedbackCollectorButtonOld,
);
