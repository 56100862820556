import React, {
	type ComponentType,
	type ReactNode,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type PropsWithChildren,
} from 'react';
import { ReportErrors } from '@atlassian/jira-errors-handling';

type ReportErrorsConfig = Flow.Diff<
	JSX.LibraryManagedAttributes<typeof ReportErrors, ComponentProps<typeof ReportErrors>>,
	{
		children: ReactNode;
	}
>;

/**
 * Wraps the provided component in a `ReportErrors` component.
 */
export const withReportErrors =
	<Props extends {}>(
		WrappedComponent: ComponentType<Props>,
		config: ReportErrorsConfig,
	): ComponentType<Props> =>
	(props: PropsWithChildren<Props>) => (
		<ReportErrors {...config}>
			<WrappedComponent {...props} />
		</ReportErrors>
	);
