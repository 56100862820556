import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import TotalIssueCount from '../total-issue-count';
import messages from './messages';

type Props = {
	startIssueRange: number;
	endIssueRange: number;
	total: number;
	countExceedsMaxResults: boolean;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ startIssueRange, endIssueRange, total, countExceedsMaxResults }: Props) => {
	const { formatMessage } = useIntl();

	if (total <= 0) {
		return <>{formatMessage(messages.issueRangeUnknown, { startIssueRange, endIssueRange })}</>;
	}

	return (
		<TotalIssueCount
			prependMessage={messages.issueRangeKnown}
			prependMessageValues={{
				startIssueRange,
				endIssueRange,
			}}
			stableCount={total}
			isCapped={countExceedsMaxResults}
		/>
	);
};
