import React, { type ComponentType, useLayoutEffect } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_DetailView$key as DetailViewFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_DetailView.graphql';
import UFOLabel from '@atlassian/jira-ufo-label';
import { PACKAGE_NAME, TEAM_NAME } from '../../../common/constants';
import IssueAppWithData, { type IssueAppWithDataProps } from '../../../common/ui/issue-app';
import { withReportErrors } from '../../../common/ui/with-report-errors';
import { markOnce, marks } from '../../../common/utils/performance-analytics';
import CardContainer from './card-container';
import CardList from './card-list';

type Props = {
	loading: boolean;
	onNavigateToIssue: (issueKey: string) => void;
	issueResults: DetailViewFragment;
	isFeedbackButtonDisabled?: boolean;
} & {
	IssueApp?: ComponentType<IssueAppWithDataProps>;
};

const ConditionalContentContainer = ({ children }: { children: React.ReactNode }) =>
	getWillShowNav4() ? (
		<Box xcss={ContentContainerStyles}>{children}</Box>
	) : (
		<ContentStyledContainer>{children}</ContentStyledContainer>
	);

const DetailView = ({
	IssueApp = IssueAppWithData,
	issueResults,
	isFeedbackButtonDisabled,
	...props
}: Props) => {
	markOnce(marks.ISSUE_RESULTS_DETAIL_VIEW_START);
	useLayoutEffect(() => {
		markOnce(marks.ISSUE_RESULTS_DETAIL_VIEW_END);
	}, []);

	const detailViewData = useFragment<DetailViewFragment>(
		graphql`
			fragment main_issueNavigator_DetailView on JiraIssueConnection {
				...main_issueNavigator_CardList
				...cardContainer_issueNavigator
				...main_issueNavigator_IssueAppWithData
			}
		`,
		issueResults,
	);

	return (
		<UFOLabel name="detail-view">
			<ConditionalContentContainer>
				<CardContainer
					issueResults={detailViewData}
					isFeedbackButtonDisabled={isFeedbackButtonDisabled}
				>
					<CardList fragment={detailViewData} {...props} />
				</CardContainer>
				<IssueApp isEmbedView issueResults={detailViewData} />
				<FireScreenAnalytics />
			</ConditionalContentContainer>
		</UFOLabel>
	);
};

// re-render regression detection
DetailView.whyDidYouRender = true;

export default withReportErrors<Props>(DetailView, {
	id: 'ui.issue-results.detail-view.unhandled',
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
	sendToPrivacyUnsafeSplunk: true,
	attributes: isLoaderErrorAttributes,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentStyledContainer = styled.div({
	display: 'flex',
	height: '100%',
	flexGrow: 1,
	overflow: 'hidden',
});

const ContentContainerStyles = xcss({
	display: 'flex',
	height: '100%',
	flexGrow: 1,
	overflow: 'hidden',
	flexBasis: 0,
});
