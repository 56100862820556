import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { type AnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/import/no-restricted-paths
import type { UncappedTotalIssueCountFetcher } from '../../../../services/issue-search-total-count';
import messages from '../messages';

type Props = {
	fetch: UncappedTotalIssueCountFetcher;
	displayText: string;
};

const LoadUncappedCountButton = ({ fetch, displayText }: Props) => {
	const { formatMessage } = useIntl();

	const fetchCount = useCallback(
		(event: React.MouseEvent<HTMLElement>, analyticsEvent: AnalyticsEvent) => {
			fetch();
			fireUIAnalytics(analyticsEvent, 'totalIssueCountButton');
		},
		[fetch],
	);

	return (
		<Tooltip content={formatMessage(messages.cappedCountTooltip)} tag="span">
			<Button
				tabIndex={0}
				appearance="link"
				spacing="none"
				onClick={fetchCount}
				aria-label={formatMessage(messages.cappedCountTooltip)}
				testId="issue-navigator.common.ui.total-issue-count.load-uncapped-count-button.button"
			>
				{displayText}
			</Button>
		</Tooltip>
	);
};

export default LoadUncappedCountButton;
