import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ProfileCardAction } from '@atlaskit/profilecard';
import { fireUIAnalytics, fireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';

export const generateUserLink = (userId: string): string => `/jira/people/${userId}`;
export const generateTeamLink = (teamId: string): string => `/jira/people/team/${teamId}`;

export const getDefaultActionProp = (label: string, profileUrl: string): ProfileCardAction => ({
	id: 'view-profile',
	label,
	callback: (event: Event) => {
		event.stopPropagation();
		window.open(profileUrl);
	},
});

export const isTeamProfileCardEventContentRendered = (analyticsPayload?: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}): boolean =>
	analyticsPayload?.action === 'rendered' && analyticsPayload?.actionSubjectId === 'content';

export const fireLegacyTeamAnalytics = (analyticsEvent: UIAnalyticsEvent) => {
	if (analyticsEvent?.payload?.eventType === 'ui') {
		fireUIAnalytics(analyticsEvent, `legacyTeamProfileCard ${analyticsEvent.payload.action}}`);

		if (isTeamProfileCardEventContentRendered(analyticsEvent?.payload)) {
			fireScreenAnalytics(analyticsEvent);
		}
	}
};
