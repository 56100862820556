import debounce from 'lodash/debounce';
import type { OptionResponse, EndCursor } from '../types';

export type DataLoader = (
	search: string,
	enableFullList: boolean,
	endCursor: EndCursor,
) => Promise<OptionResponse>;

export const createGenericDebouncableDataLoader = (dataLoader: DataLoader) => {
	const debouncedDataLoader = debounce(
		(
			search: string,
			enableFullList: boolean,
			endCursor: EndCursor,
			cb: (data: OptionResponse) => void,
		) =>
			dataLoader(search, enableFullList, endCursor).then((data) => {
				cb(data);
				return data;
			}),
		500,
	);

	return (
		search: string,
		enableFullList: boolean,
		endCursor: EndCursor,
		cb: (data: OptionResponse) => void,
	) => {
		// No need to debounce When no search string is provided.
		// We want the initial list displaying as quickly as possible when a user reopens the dropdown.
		// It will be cached by the GraphQL client and debouncing will show an unneccessary spinner.
		if (!search) {
			debouncedDataLoader.cancel();
			return dataLoader(search, enableFullList, endCursor).then((data) => {
				cb(data);
				return data;
			});
		}
		return debouncedDataLoader(search, enableFullList, endCursor, cb);
	};
};

export default createGenericDebouncableDataLoader;
