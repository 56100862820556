import React, { useCallback, type ReactNode, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { Box } from '@atlaskit/primitives';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message';
import { useIntl } from '@atlassian/jira-intl';
import { type NoColumnsProps, components } from '@atlassian/jira-native-issue-table';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller';
import { useSelectedViewState } from '../../../../controllers/selected-view-state';
import messages from './messages';

const Bold = ({ children }: { children: ReactNode }) => <b>{children}</b>;

const NoColumnsAnonymous = ({ defaultNoColumnsGlyph }: { defaultNoColumnsGlyph: JSX.Element }) => {
	const { formatMessage } = useIntl();
	const [, { setView }] = useSelectedViewState();

	const onClick = useCallback(
		(event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'button clicked', 'noColumnsButton', {
				isAnon: true,
			});
			setView('detail', toIssueKey(''));
		},
		[setView],
	);

	const component = (
		<EmptyState
			header={formatMessage(messages.noColumnsHeaderAnonymous)}
			description={
				<FormattedI18nMessage
					componentsMapping={{ bold: Bold }}
					message={formatMessage(messages.noColumnsDescriptionAnonymous, {
						boldStart: '{boldStart}',
						boldEnd: '{boldEnd}',
					})}
				/>
			}
			testId="issue-navigator.ui.issue-results.list-view.no-columns.no-columns-anonymous"
			primaryAction={
				<Button appearance="primary" onClick={onClick}>
					{formatMessage(messages.switchView)}
				</Button>
			}
			renderImage={() => defaultNoColumnsGlyph}
		/>
	);
	return <Box paddingBlock="space.800">{component}</Box>;
};

export const NoColumns = ({ defaultNoColumnsGlyph, hasColumnConfiguration }: NoColumnsProps) => {
	const isAnonymous = useIsAnonymous();
	return isAnonymous ? (
		<NoColumnsAnonymous defaultNoColumnsGlyph={defaultNoColumnsGlyph} />
	) : (
		<components.NoColumns
			defaultNoColumnsGlyph={defaultNoColumnsGlyph}
			hasColumnConfiguration={hasColumnConfiguration}
		/>
	);
};
