import React, { type FC, type MouseEvent } from 'react';
import { stringify } from 'query-string';
import Button from '@atlaskit/button';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { Link } from '@atlassian/jira-router';
import messages from './messages';

export type AdvancedSearchButtonProps = {
	jql: string;
	filterId?: string;
	isModifiedFilter?: boolean;
};

const AdvancedSearchButton: FC<AdvancedSearchButtonProps> = ({
	jql,
	filterId,
	isModifiedFilter = false,
}: AdvancedSearchButtonProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const jqlString = isModifiedFilter ? jql : undefined;

	const href = `/issues/?${stringify({ jql: jqlString, filter: filterId })}`;

	const fireClickAnalytics = (e: MouseEvent<HTMLElement>, leftClick: boolean) => {
		const analyticsEvent = createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' });
		const withCmd = e.ctrlKey || e.metaKey;
		fireUIAnalytics(analyticsEvent, 'advancedSearchButton', {
			openedIn: leftClick && !withCmd ? 'sameTab' : 'newTab',
		});
	};

	return (
		<Button
			testId="issue-navigator-action-advanced-search-button.advanced-search-button"
			onContextMenu={(e) => fireClickAnalytics(e, false)}
			onClick={(e) => fireClickAnalytics(e, true)}
			href={href}
			component={ff('nin.global-scope_aqec8') ? Link : undefined}
		>
			{' '}
			{formatMessage(messages.allIssues)}
		</Button>
	);
};

export default AdvancedSearchButton;
