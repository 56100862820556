import {
	defineMessages,
	type MessageDescriptorV2 as MessageDescriptor,
} from '@atlassian/jira-intl';

type Keys = 'heading' | 'windowTitleMigration';

type Messages = Record<Keys, MessageDescriptor>;

const messages: Messages = defineMessages({
	heading: {
		id: 'project-issue-navigator.heading',
		defaultMessage: 'Issues',
		description: 'Page heading title',
	},
	windowTitleMigration: {
		id: 'project-issue-navigator.window-title-migration',
		defaultMessage: '{projectName} - Issues',
		description: 'Window title of the project issue navigator page',
	},
});

export default messages;
