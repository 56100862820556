import log from '@atlassian/jira-common-util-logging/src/log';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { ERROR_ID } from './constants';

export const getMalformedMessage = ({
	message,
	openTags,
	closeTags,
}: {
	message: string;
	openTags: string[];
	closeTags: string[];
}) =>
	[...openTags, ...closeTags].reduce((acc, tag) => acc.replace(new RegExp(tag, 'g'), ''), message);

export const logMalformedMessageError = ({
	message,
	packageName,
}: {
	message: string;
	packageName?: string;
}) => {
	if (packageName !== null && packageName !== undefined) {
		const errorMessage = `Malformed message: ${message}`;

		// Send error log to analytics pipeline (i.e. SFX) and Sentry
		fireErrorAnalytics({
			meta: {
				packageName,
				id: ERROR_ID,
			},
			attributes: {
				message: errorMessage,
			},
		});

		// Send error log to Splunk
		const location = `${packageName}.${ERROR_ID}`;
		log.unsafeErrorWithCustomerData(location, errorMessage);
	}
};

export class MalformedMessageError extends Error {
	constructor() {
		super('Malformed i18n message');
	}
}
