import React, { useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import {
	graphql,
	commitLocalUpdate,
	useRelayEnvironment,
	usePreloadedQuery,
	type PreloadedQuery,
} from 'react-relay';
import type { ContentProps } from '@atlaskit/popup';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { mainPopupContentFilterByAriQuery } from '@atlassian/jira-relay/src/__generated__/mainPopupContentFilterByAriQuery.graphql';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller';
import { Description } from './description';
import { Owner } from './owner';
import { Permissions } from './permissions';
import { Subscriptions } from './subscriptions';

type PropsWithQueryRef = ContentProps & {
	queryReference: PreloadedQuery<mainPopupContentFilterByAriQuery>;
	onSuccess: () => void;
	onOpenEditFilterModal: () => void;
	onOpenNewSubscriptionModal: () => void;
};

export const PopupContent = ({
	queryReference,
	update,
	onSuccess: onSuccessParent,
	onOpenEditFilterModal,
	onOpenNewSubscriptionModal,
}: PropsWithQueryRef) => {
	const isAnonymous = useIsAnonymous();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		jira: { filter },
	} = usePreloadedQuery(
		graphql`
			query mainPopupContentFilterByAriQuery($filterAri: ID!, $isAnonymous: Boolean!) {
				jira @required(action: THROW) {
					filter(id: $filterAri) @required(action: THROW) {
						__id
						name
						... on JiraCustomFilter {
							...description_issueNavigatorCustomFilters
							...owner_issueNavigatorCustomFilters @skip(if: $isAnonymous)
							...permissions_issueNavigatorCustomFilters
							...subscriptions_issueNavigatorCustomFilters @skip(if: $isAnonymous)
						}
					}
				}
			}
		`,
		queryReference,
	);

	useEffect(() => {
		if (filter) {
			update();
		}
	}, [update, filter]);

	const environment = useRelayEnvironment();
	const onSuccess = useCallback(() => {
		// Invalidate store in case popup is unmounted before query can be reloaded
		commitLocalUpdate(environment, (store) => {
			const filterRecord = store.get(filter.__id);
			if (filterRecord) {
				filterRecord.invalidateRecord();
			}
		});
		// Call the success callback from parent in case popup stays open
		onSuccessParent();
	}, [environment, filter, onSuccessParent]);

	useEffect(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'viewed',
				actionSubject: 'inlineDialog',
			}),
			'filterDetails',
		);
	}, [createAnalyticsEvent]);
	return (
		<>
			<FilterTitle>{filter.name}</FilterTitle>
			<Description filter={filter} onOpenEditFilterModal={onOpenEditFilterModal} />
			{!isAnonymous && <Owner filter={filter} />}
			<Permissions filter={filter} onOpenEditFilterModal={onOpenEditFilterModal} />
			{!isAnonymous && (
				<Subscriptions
					filter={filter}
					onOpenNewSubscriptionModal={onOpenNewSubscriptionModal}
					onSuccess={onSuccess}
				/>
			)}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterTitle = styled.h3({
	overflowWrap: 'break-word',
});
