import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { useIntl } from '@atlassian/jira-intl';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

/**
 * Custom message to show when no options are visible in the column picker. This allows additional help text/content to
 * be rendered using the children prop.
 */
const NoOptionsMessage = ({ children }: { children?: ReactNode }) => {
	const { formatMessage } = useIntl();
	return (
		<Container>
			<IconContainer>
				<Error label="" />
			</IconContainer>
			<StyledTitle>{formatMessage(messages.title)}</StyledTitle>
			{children}
			<FireUiAnalytics actionSubject="emptyState" action="viewed" actionSubjectId="columnPicker" />
		</Container>
	);
};

export default NoOptionsMessage;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${token('space.100', '8px')} ${token('space.250', '20px')} ${gridSize * 1.2}px ${token(
		'space.250',
		'20px',
	)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		width: '72px',
		height: '72px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTitle = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	marginBottom: token('space.100', '8px'),
});
