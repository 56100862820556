import React from 'react';
import {
	ProjectContextProvider,
	type ProjectContextData,
	type ProjectContext as ProjectContextType,
} from '@atlassian/jira-providers-project-context';
import { Redirect } from '@atlassian/jira-router';
import type { ProjectContext as ProjectContextFromResourceType } from '@atlassian/jira-router-resources-project-context';
import { redirectPath } from './constants';
import type { Props } from './types';

export const isProjectArchivedCondition = (
	projectContextData: ProjectContextType | ProjectContextFromResourceType | null,
) => (projectContextData && projectContextData.isProjectArchived) || false;

const RedirectIfProjectArchived = ({ projectKey }: Props) => {
	if (projectKey === null) {
		return null;
	}

	return (
		<ProjectContextProvider>
			{(projectContext: ProjectContextData) =>
				isProjectArchivedCondition(projectContext.data) ? <Redirect to={redirectPath} /> : null
			}
		</ProjectContextProvider>
	);
};

export const RedirectToArchivedPage = () => <Redirect to={redirectPath} />;

export default RedirectIfProjectArchived;
