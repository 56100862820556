import React from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useRouter } from '@atlassian/jira-router';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller';
import messages from './messages';

type Props = {
	isOpen?: boolean;
};

const QuickFiltersDropdown = ({ isOpen = false }: Props) => {
	const { formatMessage } = useIntl();
	const [{ match }] = useRouter();

	const urlPrefix = match.url.endsWith('/') ? `${match.url}?filter=` : `${match.url}/?filter=`;

	const isAnonymous = useIsAnonymous();

	return (
		<DropdownMenu
			trigger={formatMessage(messages.quickFilterDropdownTrigger)}
			shouldRenderToParent
			defaultOpen={isOpen}
			testId="issue-navigator-action-quick-filters"
		>
			<DropdownItemGroup>
				{!isAnonymous && (
					<DropdownItem href={`${urlPrefix}myopenissues`}>
						{formatMessage(messages.myOpenIssues)}
					</DropdownItem>
				)}
				{!isAnonymous && (
					<DropdownItem href={`${urlPrefix}reportedbyme`}>
						{formatMessage(messages.reportedByMe)}
					</DropdownItem>
				)}
				<DropdownItem href={`${urlPrefix}allopenissues`}>
					{formatMessage(messages.openIssues)}
				</DropdownItem>
				<DropdownItem href={`${urlPrefix}doneissues`}>
					{formatMessage(messages.doneIssues)}
				</DropdownItem>
				{!isAnonymous && (
					<DropdownItem href={`${urlPrefix}recentlyviewed`}>
						{formatMessage(messages.viewedRecently)}
					</DropdownItem>
				)}
				<DropdownItem href={`${urlPrefix}resolvedrecently`}>
					{formatMessage(messages.resolvedRecently)}
				</DropdownItem>
				<DropdownItem href={`${urlPrefix}updatedrecently`}>
					{formatMessage(messages.updatedRecently)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export default QuickFiltersDropdown;
