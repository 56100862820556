/**
 * @generated SignedSource<<39b9b6eadc1c9787774f142b4f7d9df9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cardContainer_issueNavigator$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_Paginator">;
  readonly " $fragmentType": "cardContainer_issueNavigator";
};
export type cardContainer_issueNavigator$key = {
  readonly " $data"?: cardContainer_issueNavigator$data;
  readonly " $fragmentSpreads": FragmentRefs<"cardContainer_issueNavigator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "cardContainer_issueNavigator",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_Paginator"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "fca6b24420a589562ac27147066c1572";

export default node;
