import React from 'react';
import { styled } from '@compiled/react';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { MAX_COLUMNS } from '@atlassian/jira-native-issue-table';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

export const ColumnPickerMaxSelectedColumns = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<MessageContainer>
				<EditorPanelIcon label="" />
				<Message>{formatMessage(messages.maxSelectedColumns, { maxColumns: MAX_COLUMNS })}</Message>
			</MessageContainer>
			<FireUiAnalytics
				actionSubject="maxSelectedFieldsMessage"
				action="viewed"
				actionSubjectId="columnPicker"
			/>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageContainer = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N20),
	padding: `${token('space.100', '8px')} ${token('space.100', '8px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	marginTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Message = styled.p({
	margin: `0 0 0 ${token('space.100', '8px')}`,
	font: token('font.body.small'),
});
