import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import noop from 'lodash/noop';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { fireErrorAnalytics, isLoaderErrorAttributes } from '@atlassian/jira-errors-handling';
import { ff } from '@atlassian/jira-feature-flagging';
import { type FlagConfiguration, useFlagService } from '@atlassian/jira-flags';
import AsyncAttachFileDialog from '@atlassian/jira-issue-operations-attach-file-dialog';
import { DialogContextContainer } from '@atlassian/jira-issue-operations-dialog-context-provider';
import {
	type SelectValue,
	type Props as IssueTableProps,
	AsyncNativeIssueTable,
	REORDER_COLUMNS,
	MAX_COLUMNS,
	MODIFY_COLUMNS,
} from '@atlassian/jira-native-issue-table';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_ListView_filter$key as FilterFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_filter.graphql';
import type { main_issueNavigator_ListView_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_issueResults.graphql';
import type {
	main_issueNavigator_ListView_view$key as ViewFragment,
	main_issueNavigator_ListView_view$data,
} from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_view.graphql';
import type {
	main_replaceListViewFieldSetsMutation as ReplaceListViewFieldSetsMutation,
	main_replaceListViewFieldSetsMutation$data as ReplaceListViewFieldSetsMutationData,
} from '@atlassian/jira-relay/src/__generated__/main_replaceListViewFieldSetsMutation.graphql';
import type {
	main_updateFieldSetPreferencesMutation as UpdateFieldSetPreferencesMutation,
	main_updateFieldSetPreferencesMutation$data as UpdateFieldSetPreferencesMutationData,
} from '@atlassian/jira-relay/src/__generated__/main_updateFieldSetPreferencesMutation.graphql';
import { useCloudId, useIsAnonymous } from '@atlassian/jira-tenant-context-controller';
import UFOLabel from '@atlassian/jira-ufo-label';
import { PACKAGE_NAME, TEAM_NAME } from '../../../common/constants';
import type { OverridableIssueTableProps, SortDirection } from '../../../common/types';
import { withReportErrors } from '../../../common/ui/with-report-errors';
import { isFilterViewId, parseIssueNavigatorViewIdOrDefault } from '../../../common/utils';
import { markOnce, marks } from '../../../common/utils/performance-analytics';
import { ColumnPickerMessageContext } from '../../../controllers/column-picker-message-context';
import { useSelectedIssueIndex } from '../../../controllers/selected-issue-state';
import { useSelectedViewState } from '../../../controllers/selected-view-state';
import { useSortFieldAndDirection } from '../../../controllers/sort-field-and-direction-state';
import { useActiveJql } from '../../../services/active-jql';
import { useColumnLoader } from '../../../services/fetch-column-picker-options';
import { useIssueSearchQuery } from '../../../services/issue-search-query';
import { ColumnPickerContainer } from './column-picker-container';
import ColumnPickerPopupComponent from './column-picker-popup-component';
import { useFooterOverride } from './footer';
import { IssueCount } from './issue-count';
import messages from './messages';
import { NoColumns } from './no-columns';
import { NoIssues } from './no-issues';
import { AsyncRegisterShortcutDialogActions } from './register-shortcut-dialog-actions/async';
import { dragAndDropExperience } from './utils/drag-and-drop-experience';
import { useColumnWidthUpdater } from './utils/use-column-width-updater';
import { useEventHandlers } from './utils/use-event-handlers';

export type Props = {
	issueResults: IssueResultsFragment;
	loading: boolean;
	view: ViewFragment;
	onSelectedRowChange?: (rowIndex: number) => void;
	onChangeColumnConfiguration?: () => void;
	isFeedbackButtonDisabled?: boolean;
	issueTableProps?: OverridableIssueTableProps;
	filter: FilterFragment | null;
};

const reorderColumnsErrorFlag: FlagConfiguration = {
	type: 'error',
	title: messages.reorderColumnsErrorTitle,
	description: messages.columnConfigMutationErrorDescription,
	isAutoDismiss: true,
};

const resetColumnsErrorFlag: FlagConfiguration = {
	type: 'error',
	title: messages.resetColumnsErrorTitle,
	description: messages.columnConfigMutationErrorDescription,
	isAutoDismiss: true,
};

type UseResettingReturn = {
	isRestoringDefaultColumns: boolean;
	setIsRestoringDefaultColumns: (isRestoringDefaultColumns: boolean) => void;
};

const TableContainer = ({ children }: { children: React.ReactNode }) =>
	getWillShowNav4() ? <Box xcss={TableContainerStyles}>{children}</Box> : children;

export const useRestoreDefaultColumnConfigState = (): UseResettingReturn => {
	const [isRestoringDefaultColumns, setIsRestoringDefaultColumns] = useState(false);
	return { isRestoringDefaultColumns, setIsRestoringDefaultColumns };
};

export const useCanEditColumnConfiguration = (): boolean => {
	const isAnonymous = useIsAnonymous();
	const { isFilterEditable } = useActiveJql();
	const [{ view }] = useSelectedViewState();
	const { hasViewIdChanged, isRefreshing, isFetching } = useIssueSearchQuery();
	const { isRestoringDefaultColumns } = useRestoreDefaultColumnConfigState();

	return (
		!isAnonymous &&
		(!isFilterViewId(view) || isFilterEditable === true) &&
		!(isFetching && hasViewIdChanged) &&
		!isRefreshing &&
		!isRestoringDefaultColumns
	);
};

export const useConditionalOnRestoreDefaults = (
	onRestoreDefaultColumns: () => void,
	viewData: main_issueNavigator_ListView_view$data,
) => {
	const { isFilterEditable } = useActiveJql();
	const [{ view: selectedView }] = useSelectedViewState();
	return !(isFilterViewId(selectedView) && isFilterEditable === false) &&
		viewData?.hasDefaultFieldSets === false
		? onRestoreDefaultColumns
		: undefined;
};

/**
 *
 * the presence of conditionalOnChange determines whether the user has column configuration capabilities.
 * hence we only define conditionalOnChange hook when a user can edit a column
 */
export const useConditionalOnChange = (
	onColumnsChange: NonNullable<IssueTableProps['onColumnsChange']>,
) => {
	const canEditColumns = useCanEditColumnConfiguration();
	return canEditColumns ? onColumnsChange : undefined;
};

export const useColumnLoaderForSelectedView = (selectedColumns: SelectValue) => {
	const [{ view: selectedView }] = useSelectedViewState();
	const canEditColumns = useCanEditColumnConfiguration();
	return useColumnLoader(selectedView, canEditColumns, selectedColumns);
};

/**
 *
 * only a logged in user can view the column picker,
 * hence we only define the useColumnLoader hook for logged in users.
 */
export const useConditionalColumnLoader = () => {
	const isAnonymous = useIsAnonymous();
	return !isAnonymous ? useColumnLoaderForSelectedView : undefined;
};

export const useColumnPickerLoading = () => {
	const { hasViewIdChanged, isFetching } = useIssueSearchQuery();
	const { isRestoringDefaultColumns } = useRestoreDefaultColumnConfigState();

	return {
		isColumnPickerLoading: isRestoringDefaultColumns || (isFetching && hasViewIdChanged),
	};
};

const ListView = ({
	loading,
	onChangeColumnConfiguration,
	onSelectedRowChange,
	issueResults,
	view,
	isFeedbackButtonDisabled,
	issueTableProps,
	filter,
}: Props) => {
	markOnce(marks.ISSUE_RESULTS_LIST_VIEW_START);

	useLayoutEffect(() => {
		markOnce(marks.ISSUE_RESULTS_LIST_VIEW_END);
	}, []);

	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment main_issueNavigator_ListView_issueResults on JiraIssueConnection {
				...registerShortcutDialogActions_issueNavigator_RegisterShortcutsDialogActions
				...ui_nativeIssueTable_NativeIssueTable_issues
				pageCursors(maxCursors: 7) {
					around {
						pageNumber
						isCurrent
					}
				}
			}
		`,
		issueResults,
	);

	const viewData = useFragment<ViewFragment>(
		graphql`
			fragment main_issueNavigator_ListView_view on JiraIssueSearchView
			@argumentDefinitions(
				fetchColumnWidths: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/nin-resizable-columns.relayprovider"
				}
			) {
				id @required(action: THROW)
				viewId @required(action: THROW)
				hasDefaultFieldSets
				fieldSets(first: $amountOfColumns, filter: { fieldSetSelectedState: SELECTED })
					@required(action: THROW) {
					__id
					totalCount
					...ui_nativeIssueTable_NativeIssueTable_columns
						@arguments(fetchColumnWidths: $fetchColumnWidths)
				}
			}
		`,
		view,
	);

	const filterData = useFragment<FilterFragment>(
		graphql`
			fragment main_issueNavigator_ListView_filter on JiraFilter {
				name
			}
		`,
		filter,
	);

	const [selectedIssueIndex, { selectIssueOnPage }] = useSelectedIssueIndex();

	const currentPage =
		issueResultsData.pageCursors?.around?.find(
			(
				cursor:
					| {
							readonly isCurrent: boolean | null | undefined;
							readonly pageNumber: number | null | undefined;
					  }
					| null
					| undefined,
			) => cursor?.isCurrent,
		)?.pageNumber ?? 1;
	const eventHandlers = useEventHandlers();

	const [{ field, direction }, { setFieldAndDirection }] = useSortFieldAndDirection();
	const { onIssueSearchForCurrentPage } = useIssueSearchQuery();

	const { isRestoringDefaultColumns, setIsRestoringDefaultColumns } =
		useRestoreDefaultColumnConfigState();

	const onModifyColumns = useCallback(() => {
		onChangeColumnConfiguration && onChangeColumnConfiguration();
		onIssueSearchForCurrentPage();
	}, [onChangeColumnConfiguration, onIssueSearchForCurrentPage]);

	const onPageChange = useCallback(
		(cursor: string, shouldSelectLastIssue: boolean) => {
			selectIssueOnPage(cursor, shouldSelectLastIssue);
		},
		[selectIssueOnPage],
	);

	const onSortOrderChange = useCallback(
		(sortField: string, sortDirection: SortDirection) =>
			setFieldAndDirection(sortField, sortDirection, []),
		[setFieldAndDirection],
	);

	const overriddenTableComponents = issueTableProps?.components;
	const issueCreateProjectKey = issueTableProps?.issueCreateProjectKey;

	const hasMaxSelectedColumns = viewData?.fieldSets?.totalCount
		? viewData?.fieldSets?.totalCount >= MAX_COLUMNS
		: false;

	const isAnonymous = useIsAnonymous();

	const { showFlag } = useFlagService();

	const Footer = useFooterOverride(isFeedbackButtonDisabled);

	const tableComponents = useMemo(
		// We shallow merge these objects to make it easier for issue-navigator consumers to revert custom column
		// picker components, which are very NIN specific. This decision can be reconsidered if we need to introduce
		// additional nested custom components in the issue table and make those available to all consumers.
		() => ({
			columnPicker: {
				Container: ({ children }: { children: React.ReactNode }) => (
					<ColumnPickerContainer
						viewIdFromResponse={parseIssueNavigatorViewIdOrDefault(viewData.viewId)}
						isRestoringDefaults={isRestoringDefaultColumns}
					>
						{children}
					</ColumnPickerContainer>
				),
				PopupComponent: ColumnPickerPopupComponent,
			},
			Footer,
			IssueCount,
			NoColumns,
			NoIssues,
			...overriddenTableComponents,
		}),
		[Footer, isRestoringDefaultColumns, overriddenTableComponents, viewData.viewId],
	);

	const [replaceListViewFieldSets] = useMutation<ReplaceListViewFieldSetsMutation>(graphql`
		mutation main_replaceListViewFieldSetsMutation(
			$id: ID!
			$input: JiraReplaceIssueSearchViewFieldSetsInput
			$fieldSetsInput: JiraFieldSetsMutationInput
			$amountOfColumns: Int!
			$includeView: Boolean!
		) @raw_response_type {
			jira {
				replaceIssueSearchViewFieldSets(id: $id, input: $input, fieldSetsInput: $fieldSetsInput) {
					success
					view @include(if: $includeView) {
						viewId
						...main_issueNavigator_ListView_view
					}
				}
			}
		}
	`);

	const onColumnsChange = useCallback<NonNullable<IssueTableProps['onColumnsChange']>>(
		(columnIds, changeType, optimisticFieldSets) => {
			// This handler can be called from a native event listener hence React does not batch state updates.
			// We manually batch this update for better rendering performance and more accurate measurement of
			// drag-and-drop interaction performance. We can remove this with React 18's automatic batching.
			ReactDOM.unstable_batchedUpdates(() => {
				if (changeType === REORDER_COLUMNS) {
					dragAndDropExperience.start();
				}
				replaceListViewFieldSets({
					variables: {
						id: viewData.id,
						amountOfColumns: MAX_COLUMNS,
						fieldSetsInput: { replaceFieldSetsInput: { nodes: columnIds } },
						includeView: changeType === REORDER_COLUMNS,
					},
					optimisticResponse:
						changeType === REORDER_COLUMNS && optimisticFieldSets
							? {
									jira: {
										replaceIssueSearchViewFieldSets: {
											success: true,
											view: {
												id: viewData.id,
												viewId: viewData.viewId,
												hasDefaultFieldSets: viewData.hasDefaultFieldSets,
												fieldSets: {
													...optimisticFieldSets,
												},
											},
										},
									},
								}
							: undefined,
					onCompleted(data: ReplaceListViewFieldSetsMutationData) {
						if (data.jira?.replaceIssueSearchViewFieldSets?.success) {
							if (changeType === MODIFY_COLUMNS) {
								onModifyColumns();
							} else if (changeType === REORDER_COLUMNS) {
								dragAndDropExperience.success();
							}
						} else {
							showFlag(reorderColumnsErrorFlag);
							fireErrorAnalytics({
								meta: {
									id: 'commitReplaceListViewFieldSetsMutationUnsuccessful',
									packageName: PACKAGE_NAME,
									teamName: TEAM_NAME,
								},
								sendToPrivacyUnsafeSplunk: true,
								attributes: {
									changeType,
								},
							});
							if (changeType === REORDER_COLUMNS) {
								dragAndDropExperience.failure();
							}
						}
					},
					onError(error: Error) {
						showFlag(reorderColumnsErrorFlag);
						fireErrorAnalytics({
							meta: {
								id: 'commitReplaceListViewFieldSetsMutationError',
								packageName: PACKAGE_NAME,
								teamName: TEAM_NAME,
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
							attributes: {
								changeType,
							},
						});
						if (changeType === REORDER_COLUMNS) {
							dragAndDropExperience.failure();
						}
					},
				});
			});
		},
		[
			replaceListViewFieldSets,
			onModifyColumns,
			showFlag,
			viewData.hasDefaultFieldSets,
			viewData.id,
			viewData.viewId,
		],
	);

	const [updateFieldSetPreferences] = useMutation<UpdateFieldSetPreferencesMutation>(graphql`
		mutation main_updateFieldSetPreferencesMutation(
			$cloudId: ID!
			$input: JiraFieldSetPreferencesMutationInput!
		) {
			jira {
				updateUserFieldSetPreferences(cloudId: $cloudId, fieldSetPreferencesInput: $input)
					@optIn(to: "JiraUpdateUserFieldSetPreferences") {
					success
				}
			}
		}
	`);

	const cloudId = useCloudId();

	const updateColumnWidth = ff('nin-resizable-columns_8z4eo')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useColumnWidthUpdater(viewData.fieldSets.__id)
		: noop;

	const onColumnResize = useCallback<NonNullable<IssueTableProps['onColumnResize']>>(
		(columnId, width) => {
			// skip mutation for anonymous users as they don't have access to user preferences
			if (!isAnonymous) {
				// we intentionally don't optimistically update the store to avoid rolling back column widths
				// in case of a mutation error, which would be very jarring for the user
				updateFieldSetPreferences({
					variables: {
						cloudId,
						input: {
							nodes: [
								{
									fieldSetId: columnId,
									width: width ?? null,
								},
							],
						},
					},
					onCompleted(data: UpdateFieldSetPreferencesMutationData) {
						if (!data.jira?.updateUserFieldSetPreferences?.success) {
							// silently fail as column will remain at the desired width until the next page load
							fireErrorAnalytics({
								meta: {
									id: 'commitUpdateFieldSetPreferencesMutationUnsuccessful',
									packageName: PACKAGE_NAME,
									teamName: TEAM_NAME,
								},
								sendToPrivacyUnsafeSplunk: true,
							});
						}
					},
					onError(error: Error) {
						// silently fail as column will remain at the desired width until the next page load
						fireErrorAnalytics({
							meta: {
								id: 'commitUpdateFieldSetPreferencesMutationError',
								packageName: PACKAGE_NAME,
								teamName: TEAM_NAME,
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
						});
					},
				});
			}

			// commit local update to the store independently of the mutation result to preserve user's desired width
			updateColumnWidth(columnId, width);
		},
		[cloudId, isAnonymous, updateColumnWidth, updateFieldSetPreferences],
	);

	useLayoutEffect(() => {
		dragAndDropExperience.markInlineResponse();
	});

	const contextValue = useMemo(
		() => ({
			hasMaxSelectedColumns,
			filterName: filterData?.name,
		}),
		[filterData?.name, hasMaxSelectedColumns],
	);
	const { onIssueSearchRefresh } = useIssueSearchQuery();

	const onRestoreDefaultColumns = useCallback<
		NonNullable<IssueTableProps['onRestoreDefaultColumns']>
	>(() => {
		setIsRestoringDefaultColumns(true);
		replaceListViewFieldSets({
			variables: {
				id: viewData.id,
				amountOfColumns: MAX_COLUMNS,
				includeView: true,
				fieldSetsInput: { resetToDefaultFieldSets: true },
			},
			onCompleted(data: ReplaceListViewFieldSetsMutationData) {
				setIsRestoringDefaultColumns(false);
				if (data.jira?.replaceIssueSearchViewFieldSets?.success) {
					onIssueSearchRefresh();
				} else {
					showFlag(resetColumnsErrorFlag);
					fireErrorAnalytics({
						meta: {
							id: 'commitReplaceListViewUsingResetFieldSetsMutationUnsuccessful',
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
			onError(error: Error) {
				setIsRestoringDefaultColumns(false);
				showFlag(resetColumnsErrorFlag);
				fireErrorAnalytics({
					meta: {
						id: 'commitReplaceListViewUsingResetFieldSetsMutationError',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		});
	}, [
		replaceListViewFieldSets,
		onIssueSearchRefresh,
		setIsRestoringDefaultColumns,
		showFlag,
		viewData.id,
	]);

	/**
	 * returns undefined if there are default field sets
	 * returns undefined if the user is on filter tab but cannot edit filter
	 */
	const conditionalOnRestoreDefaults = useConditionalOnRestoreDefaults(
		onRestoreDefaultColumns,
		viewData,
	);

	const conditionalOnChange: typeof onColumnsChange | undefined =
		useConditionalOnChange(onColumnsChange);

	const { isColumnPickerLoading } = useColumnPickerLoading();

	const conditionalColumnLoader: IssueTableProps['useColumnLoader'] | undefined =
		useConditionalColumnLoader();

	return (
		<UFOLabel name="list-view">
			<DialogContextContainer>
				<ContextualAnalyticsData attributes={{ currentPage, isAnonymous }}>
					<AsyncRegisterShortcutDialogActions
						issues={issueResultsData}
						selectedIssueIndex={selectedIssueIndex}
						eventHandlers={eventHandlers}
					/>
					<ColumnPickerMessageContext.Provider value={contextValue}>
						<TableContainer>
							<AsyncNativeIssueTable
								issues={issueResultsData}
								columns={viewData.fieldSets}
								onColumnsChange={conditionalOnChange}
								onRestoreDefaultColumns={conditionalOnRestoreDefaults}
								useColumnLoader={conditionalColumnLoader}
								loading={loading}
								eventHandlers={eventHandlers}
								onSelectedRowChange={onSelectedRowChange}
								onPageChange={onPageChange}
								selectedRow={selectedIssueIndex}
								sortField={field}
								sortDirection={direction}
								onSortChange={onSortOrderChange}
								components={tableComponents}
								issueSearchBaseUrl="" // Relative to current route rendering the issue navigator
								isColumnPickerLoading={isColumnPickerLoading}
								issueCreateProjectKey={issueCreateProjectKey}
								onColumnResize={ff('nin-resizable-columns_8z4eo') ? onColumnResize : undefined}
							/>
						</TableContainer>
					</ColumnPickerMessageContext.Provider>
				</ContextualAnalyticsData>
				<AsyncAttachFileDialog />
				<FireScreenAnalytics />
			</DialogContextContainer>
		</UFOLabel>
	);
};

// re-render regression detection
ListView.whyDidYouRender = true;

export default withReportErrors<Props>(ListView, {
	id: 'ui.issue-results.list-view.unhandled',
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
	sendToPrivacyUnsafeSplunk: true,
	attributes: isLoaderErrorAttributes,
});

const TableContainerStyles = xcss({
	flexGrow: 1,
	flexBasis: 0,
	display: 'flex',
	height: '100%',
	overflow: 'hidden',
	flexDirection: 'column',
});
