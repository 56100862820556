// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import ErrorImage from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntl } from '@atlassian/jira-intl';
import ErrorInfo from './error-info';
import messages from './messages';
import {
	SkeletonContainer,
	SkeletonBackground,
	ColumnMask,
	SummaryMask,
	FooterMask,
	TextLineMask,
	TypeMask,
	IssueKeyMask,
	SeparatorMask,
	BlankMask,
	Wrapper,
	ErrorIconContainer,
	ErrorWrapper,
	errorWrapperSelector,
	skeletonBackgroundSelector,
	columnMaskSelector,
} from './styled';

type PropsOld = {
	issueIndex: number;
	issueCount: number;
	hasError?: boolean;
	onReady?: () => void;
};

type PropsNew = {
	issueIndex: number;
	issueCount: number;
	hasError?: boolean;
};

type State = {
	isHovering: boolean;
};
const IssueCardSkeletonNew = ({ hasError = false, issueIndex, issueCount }: PropsNew) => {
	const { formatMessage } = useIntl();

	return (
		<Wrapper
			role="option"
			aria-selected={false}
			aria-label={formatMessage(messages.issueLoadingLabel, { number: issueIndex })}
			isFirstCard={issueIndex === 0}
			isLastCard={issueIndex + 1 === issueCount}
			hasError={hasError}
		>
			<SkeletonContainer>
				<SkeletonBackground
					hasError={hasError}
					data-component-selector={skeletonBackgroundSelector}
				/>
				<ColumnMask data-component-selector={columnMaskSelector}>
					<SummaryMask>
						<TextLineMask fontSize={20} lineHeight={24} intrusion={20} />
						<TextLineMask fontSize={20} lineHeight={24} intrusion={60} />
					</SummaryMask>
					<FooterMask>
						<TypeMask />
						<SeparatorMask />
						<IssueKeyMask />
						{hasError ? (
							<ErrorIconContainer>
								<ErrorImage primaryColor={token('color.icon.danger', colors.R400)} label="" />
							</ErrorIconContainer>
						) : (
							<BlankMask />
						)}
					</FooterMask>
				</ColumnMask>
				<ErrorWrapper data-component-selector={errorWrapperSelector}>
					<ErrorInfo />
				</ErrorWrapper>
			</SkeletonContainer>
		</Wrapper>
	);
};

// eslint-disable-next-line jira/react/no-class-components
class IssueCardSkeletonOld extends PureComponent<PropsOld, State> {
	static defaultProps = {
		hasError: false,
		onReady: noop,
	};

	componentDidMount() {
		this.props.onReady && this.props.onReady();
	}

	render() {
		const { hasError, issueIndex, issueCount } = this.props;
		return (
			<Wrapper
				isFirstCard={issueIndex === 0}
				isLastCard={issueIndex + 1 === issueCount}
				role="listitem"
				hasError={hasError}
			>
				<SkeletonContainer>
					<SkeletonBackground
						hasError={hasError ?? false}
						data-component-selector={skeletonBackgroundSelector}
					/>
					<ColumnMask data-component-selector={columnMaskSelector}>
						<SummaryMask>
							<TextLineMask fontSize={20} lineHeight={24} intrusion={20} />
							<TextLineMask fontSize={20} lineHeight={24} intrusion={60} />
						</SummaryMask>
						<FooterMask>
							<TypeMask />
							<SeparatorMask />
							<IssueKeyMask />
							{hasError ? (
								<ErrorIconContainer>
									<ErrorImage primaryColor={token('color.icon.danger', colors.R400)} label="" />
								</ErrorIconContainer>
							) : (
								<BlankMask />
							)}
						</FooterMask>
					</ColumnMask>
					<ErrorWrapper data-component-selector={errorWrapperSelector}>
						<ErrorInfo />
					</ErrorWrapper>
				</SkeletonContainer>
			</Wrapper>
		);
	}
}

const IssueCardSkeleton = componentWithFG(
	'nin_critical_accessibility_issues',
	IssueCardSkeletonNew,
	IssueCardSkeletonOld,
);

export default IssueCardSkeleton;
