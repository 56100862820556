/**
 * @generated SignedSource<<57be5be30230a7d7baac9f6c8f3cf1c8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueAppWithData$data = {
  readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueNavigator">;
  readonly " $fragmentType": "main_issueNavigator_IssueAppWithData";
};
export type main_issueNavigator_IssueAppWithData$key = {
  readonly " $data"?: main_issueNavigator_IssueAppWithData$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueAppWithData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueAppWithData",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "headerActions_issueNavigator"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "ac9a92f45a0e344788b01afcbb4c01aa";

export default node;
