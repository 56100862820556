import getMeta from '@atlassian/jira-get-meta';
import type IssueMutation from '../issue-mutation';

/**
 * A method to merge issue mutation data
 * Accepts two objects of issue mutations and returns a merged object
 */
export function mergeData(
	oldData: {
		[key: string]: IssueMutation;
	},
	newData: {
		[key: string]: IssueMutation;
	},
): {
	[key: string]: IssueMutation;
} {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};

	Object.keys(oldData).forEach((issueId) => {
		result[issueId] = oldData[issueId];
	});

	Object.keys(newData).forEach((issueId) => {
		result[issueId] = newData[issueId];
	});

	return result;
}

/**
 * A method to merge parsed search and match reponse data
 * Accepts two arrays of Object and returns a merged array of Object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeResponseData(searchData: any[], matchData: any[]): any[] {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const mergedData: Array<undefined | any> = [];
	const issueData = new Map();

	if (matchData.length) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		matchData.forEach((element: any) => {
			issueData.set(element.id, element);
		});
	}

	if (searchData.length) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		searchData.forEach((element: any) => {
			// TODO: if issueVersion in Match is less than Search, we should not be overwriting that issueID.
			if (issueData.has(element.id)) {
				mergedData.push(issueData.get(element.id));
				issueData.delete(element.id);
			} else mergedData.push(element);
		});
	}

	if (issueData.size) {
		issueData.forEach((value) => {
			mergedData.push(value);
		});
	}

	return mergedData;
}
/**
 * a method to generate analytics event data
 * Accepts custom data from different experiences and returns the event data as an object
 */
export function createAnalyticsMetadata(customKeys?: Object) {
	return {
		shard: getMeta('ajs-shard') || '',
		userId: getMeta('ajs-atlassian-account-id') || '',
		projectId: getMeta('ajs-project-key') || '',
		...customKeys,
	};
}
