/**
 * @generated SignedSource<<bd6f89d65d09d1c53243ea16d9b03f55>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueResults_view$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_view">;
  readonly " $fragmentType": "main_issueNavigator_IssueResults_view";
};
export type main_issueNavigator_IssueResults_view$key = {
  readonly " $data"?: main_issueNavigator_IssueResults_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueResults_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_ListView_view"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "6e530ce64251d4ecc796b64331bee0d5";

export default node;
