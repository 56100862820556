import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import type { edit_issueNavigatorCustomFilters$key } from '@atlassian/jira-relay/src/__generated__/edit_issueNavigatorCustomFilters.graphql';
import { GroupGrant } from '../../../../../common/ui/details-popup/group-grant';
import { ProjectGrant } from '../../../../../common/ui/details-popup/project-grant';
import { ProjectRoleGrant } from '../../../../../common/ui/details-popup/project-role-grant';
import { SectionBody, SectionBodyTitle } from '../../../../../common/ui/details-popup/styled';
import { UnknownProject } from '../../../../../common/ui/details-popup/unknown-project';
import { UsersGrant } from '../../../../../common/ui/details-popup/users-grant';
import messages from './messages';

type Props = {
	filter: edit_issueNavigatorCustomFilters$key;
};

export const Edit = ({ filter }: Props) => {
	const { formatMessage } = useIntl();

	const { editGrants } = useFragment(
		graphql`
			fragment edit_issueNavigatorCustomFilters on JiraCustomFilter {
				editGrants {
					edges {
						node {
							__typename
							...projectGrant_issueNavigatorCustomFilters
							...projectRoleGrant_issueNavigatorCustomFilters
							...groupGrant_issueNavigatorCustomFilters
							... on JiraShareableEntityUserGrant {
								user {
									name
								}
							}
						}
					}
				}
			}
		`,
		filter,
	);

	const users = useMemo(
		() =>
			editGrants?.edges && editGrants.edges?.length > 0
				? editGrants?.edges
						.filter(
							(shareableEntity) =>
								shareableEntity?.node &&
								shareableEntity.node.__typename === 'JiraShareableEntityUserGrant',
						)
						.map((shareableEntity) =>
							shareableEntity?.node?.user ? shareableEntity.node.user.name : '',
						)
				: [],
		[editGrants?.edges],
	);

	return (
		<>
			<SectionBodyTitle>{formatMessage(messages.editabledBy)}</SectionBodyTitle>
			{editGrants?.edges && editGrants.edges?.length > 0 ? (
				<>
					{
						// using index as key because these relations don't have individual IDs
						editGrants.edges.map((shareableEntity, key) => {
							if (shareableEntity?.node) {
								const { __typename } = shareableEntity?.node;

								if (__typename === 'JiraShareableEntityProjectGrant') {
									return <ProjectGrant key={key} entity={shareableEntity.node} />;
								}
								if (__typename === 'JiraShareableEntityProjectRoleGrant') {
									return <ProjectRoleGrant key={key} entity={shareableEntity.node} />;
								}
								if (__typename === 'JiraShareableEntityGroupGrant') {
									return <GroupGrant key={key} entity={shareableEntity.node} />;
								}
								if (__typename === 'JiraShareableEntityUnknownProjectGrant') {
									return <UnknownProject key={key} />;
								}
							}

							return null;
						})
					}
					{users.length > 0 && <UsersGrant users={users} />}
				</>
			) : (
				<SectionBody>{formatMessage(messages.private)}</SectionBody>
			)}
		</>
	);
};
