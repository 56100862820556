import { useCallback } from 'react';
import { useResolvedJql } from '../../services/active-jql';
import { useIssueSearchQuery } from '../../services/issue-search-query';

export const useJqlSearch = (changeJql?: (jql: string, clearFilter?: boolean) => void) => {
	const query = useResolvedJql();

	const { onIssueSearch } = useIssueSearchQuery();

	const onSearch = useCallback(
		(jql: string) => {
			if (jql === query) {
				// This will execute a new search against the network with current JQL input arguments
				onIssueSearch();
			} else {
				changeJql && changeJql(jql);
			}
		},
		[query, onIssueSearch, changeJql],
	);

	return { onSearch, query };
};
