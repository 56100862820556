import { defineMessages } from '@atlassian/jira-intl'; // TODO - Port i18n string translations to new module ID keys
/* eslint-disable jira/i18n/id-named-by-package */

// TODO - Port i18n string translations to new module ID keys
export default defineMessages({
	tooltip: {
		id: 'issue-navigator.refresh-button.tooltip',
		defaultMessage: 'Refresh',
		description: 'The tooltip on refresh button on the issue list',
	},
	loading: {
		id: 'issue-navigator.refresh-button.loading',
		defaultMessage: 'Loading',
		description: 'The aria-label attribute for spinner on the issue list',
	},
});
