import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import InfoIcon from '@atlaskit/icon/glyph/editor/info';
import { Inline, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { subscriptions_issueNavigatorCustomFilters$key } from '@atlassian/jira-relay/src/__generated__/subscriptions_issueNavigatorCustomFilters.graphql';
import { HEADER_HEIGHT, SectionHeader } from '../../../../common/ui/details-popup/section-header';
import { Section, PlaceholderText } from '../../../../common/ui/details-popup/styled';
import { getManageSubscriptionsLink } from '../../../../common/utils';
import { AddSubscriptionModalProvider } from './add-subscription-modal';
import messages from './messages';

type Props = {
	filter: subscriptions_issueNavigatorCustomFilters$key;
	onOpenNewSubscriptionModal: () => void;
	onSuccess: () => void;
};

export const Subscriptions = ({ filter, onOpenNewSubscriptionModal, onSuccess }: Props) => {
	const { formatMessage } = useIntl();

	const data = useFragment(
		graphql`
			fragment subscriptions_issueNavigatorCustomFilters on JiraCustomFilter {
				filterId
				...addSubscriptionModal_issueNavigatorCustomFilters_AddSubscriptionModalProvider
				emailSubscriptions(last: 5) @required(action: THROW) {
					edges @required(action: THROW) {
						node {
							id
							user {
								name
							}
							group {
								name
							}
						}
					}
				}
			}
		`,
		filter,
	);

	return (
		<Section>
			<SectionHeader
				actions={
					<Inline space="space.050" alignBlock="center" shouldWrap>
						<AddSubscriptionModalProvider
							filter={data}
							onOpenNewSubscriptionModal={onOpenNewSubscriptionModal}
							onSuccess={onSuccess}
						>
							{({ isLoading, open, preload }) => (
								<Button
									appearance="link"
									spacing="none"
									onClick={open}
									onMouseOver={preload}
									isDisabled={isLoading}
									testId="issue-navigator-custom-filters.ui.details-popup.popup-content.subscriptions.add-subscription-button"
								>
									{formatMessage(messages.addSubscription)}
								</Button>
							)}
						</AddSubscriptionModalProvider>
						{data.emailSubscriptions.edges.length > 0 && (
							<>
								<Text>•</Text>
								<Link
									href={getManageSubscriptionsLink(data.filterId)}
									onClick={(_: unknown, analyticsEvent: UIAnalyticsEvent) =>
										fireUIAnalytics(analyticsEvent, 'manageSubscriptions')
									}
									data-testid="issue-navigator-custom-filters.ui.details-popup.popup-content.subscriptions.manage-subscriptions-link"
								>
									{formatMessage(messages.manageSubscriptions)}
								</Link>
							</>
						)}
					</Inline>
				}
			>
				<Inline space="space.025" alignBlock="center">
					<Text weight="semibold">{formatMessage(messages.subscriptions)}</Text>
					{!data.emailSubscriptions.edges.length && (
						<Tooltip
							content={formatMessage(messages.subscriptionsInfoTooltip)}
							// @ts-expect-error Base type is too broad for component
							tag={InfoIconWrapper}
						>
							<InfoIcon label={formatMessage(messages.subscriptionsInfoTooltip)} size="medium" />
						</Tooltip>
					)}
				</Inline>
			</SectionHeader>

			{data.emailSubscriptions.edges.length ? (
				<>
					<p>{formatMessage(messages.hasTheFollowingSubscriptions)}</p>
					<SubscriptionList>
						{data.emailSubscriptions.edges.map((edge) =>
							edge?.node ? (
								<li key={edge.node.id}>
									{edge.node.group ? edge.node.group.name : edge.node.user?.name}
								</li>
							) : null,
						)}
					</SubscriptionList>
				</>
			) : (
				<p>
					<PlaceholderText>{formatMessage(messages.subscriptionsPlaceholder)}</PlaceholderText>
				</p>
			)}
		</Section>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InfoIconWrapper = styled.span({
	marginTop: token('space.negative.050', '-4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${HEADER_HEIGHT}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubscriptionList = styled.ul({
	listStyleType: 'none',
	padding: 0,
});
