import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { NinChangeboardingTourCard } from '@atlassian/jira-issue-navigator-changeboarding';
import { toSearchModeWithBasicModeFallback } from '@atlassian/jira-jql-builder/src/common/utils';
import type { spotlight_issueNavigator_IssueNavigatorSpotlight$key as UserPreferencesFragmentKey } from '@atlassian/jira-relay/src/__generated__/spotlight_issueNavigator_IssueNavigatorSpotlight.graphql';
import { convertToView } from '../../common/utils';
import { useSelectedViewState } from '../../controllers/selected-view-state';

type Props = {
	hasIssueResults: boolean;
	userPreferences: UserPreferencesFragmentKey | null;
	isAiEnabled: boolean;
};

const IssueNavigatorSpotlight = ({ hasIssueResults, userPreferences, isAiEnabled }: Props) => {
	const [{ view }] = useSelectedViewState();

	const searchModeResponse = useFragment<UserPreferencesFragmentKey>(
		graphql`
			fragment spotlight_issueNavigator_IssueNavigatorSpotlight on JiraUserPreferences {
				jqlBuilderSearchMode
			}
		`,
		userPreferences,
	);

	const selectedSearchMode = toSearchModeWithBasicModeFallback(
		searchModeResponse?.jqlBuilderSearchMode,
	);

	return (
		<NinChangeboardingTourCard
			mode={selectedSearchMode}
			view={convertToView(view)}
			hasIssueResults={hasIssueResults}
			isAiEnabled={isAiEnabled}
		/>
	);
};

export default IssueNavigatorSpotlight;
