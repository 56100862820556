import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	subscriptions: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.subscriptions',
		defaultMessage: 'Subscriptions',
		description: 'Header describing the section that lists filter subscriptions',
	},
	addSubscription: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.add-subscription',
		defaultMessage: 'Add subscription',
		description: 'Text label for a button that opens the "add subscription" dialog',
	},
	manageSubscriptions: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.manage-subscriptions',
		defaultMessage: 'Manage subscriptions',
		description: 'Text label for a link to the "view subscriptions" page',
	},
	subscriptionsPlaceholder: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.subscriptions-placeholder',
		defaultMessage: "This filter doesn't have any subscriptions.",
		description: 'Placeholder text for when the filter has no subscriptions',
	},
	subscriptionsInfoTooltip: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.subscriptions-info-tooltip',
		defaultMessage: 'Get regular updates from issues in this filter.',
		description:
			'Tooltip text for the "info" icon, providing users with context about what Subscriptions are',
	},
	hasTheFollowingSubscriptions: {
		id: 'issue-navigator-custom-filters.details-popup.popup-content.subscriptions.has-the-following-subscriptions',
		defaultMessage: 'This filter has the following subscriptions:',
		description: 'Text used to explain the list of subscriptions that appears below this text.',
	},
});
