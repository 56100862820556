// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ReactNode, type ComponentType, type ComponentProps } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import type { cardContainer_issueNavigator$key as CardContainerFragment } from '@atlassian/jira-relay/src/__generated__/cardContainer_issueNavigator.graphql';
import Feedback from '../../../../common/ui/feedback';
import RefreshButton from '../../../../common/ui/refresh-button';
import OrderDropdownDI from './order-dropdown';
import Paginator from './paginator';
import SortButtonDI from './sort-button';

type Props = {
	issueResults: CardContainerFragment;
	OrderDropdown: ComponentType<
		JSX.LibraryManagedAttributes<typeof OrderDropdownDI, ComponentProps<typeof OrderDropdownDI>>
	>;
	SortButton: ComponentType<
		JSX.LibraryManagedAttributes<typeof SortButtonDI, ComponentProps<typeof SortButtonDI>>
	>;
	children: ReactNode;
	isFeedbackButtonDisabled?: boolean;
};

const CardContainer = ({
	OrderDropdown,
	SortButton,
	children,
	issueResults,
	isFeedbackButtonDisabled,
}: Props) => {
	const data = useFragment<CardContainerFragment>(
		graphql`
			fragment cardContainer_issueNavigator on JiraIssueConnection {
				...main_issueNavigator_Paginator
			}
		`,
		issueResults,
	);

	return (
		<>
			<StyledContainer data-testid="issue-navigator.ui.issue-results.detail-view.card-container.styled-container">
				<HeaderStyledContainer>
					<OrderDropdown />
					<SortButton />
					<RefreshButton />
				</HeaderStyledContainer>
				<CardListStyledContainer>{children}</CardListStyledContainer>
				<FooterStyledContainer>
					<Paginator issueResults={data} />
				</FooterStyledContainer>
				{!isFeedbackButtonDisabled && (
					<FeedbackWrapper>
						<Feedback />
					</FeedbackWrapper>
				)}
			</StyledContainer>
		</>
	);
};

CardContainer.defaultProps = {
	OrderDropdown: OrderDropdownDI,
	SortButton: SortButtonDI,
};

export default CardContainer;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${gridSize / 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N20),
	padding: token('space.025', '2px'),
	marginBottom: token('space.100', '8px'),
	marginRight: token('space.200', '16px'),
	minWidth: '256px',
	maxWidth: '256px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardListStyledContainer = styled.div({
	overflow: 'auto',
	flex: '1 1 100vh',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderStyledContainer = styled.div({
	display: 'flex',
	flexGrow: 1,
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
	paddingBottom: token('space.025', '2px'),
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterStyledContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	padding: `${token('space.025', '2px')} 0 0 0`,
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeedbackWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `calc(100% + ${gridSize * 2}px)`,
	paddingTop: token('space.200', '16px'),
	paddingBottom: token('space.025', '2px'),
	display: 'flex',
	justifyContent: 'center',
	marginTop: 0,
	marginBottom: token('space.negative.100', '-8px'),
	marginLeft: token('space.negative.100', '-8px'),
	marginRight: token('space.negative.100', '-8px'),
});
