import React from 'react';
import { styled } from '@compiled/react';
import QuestionCircle from '@atlaskit/icon/glyph/question-circle';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { SectionBody } from '../styled';
import messages from './messages';

export const UnknownProject = () => {
	const { formatMessage } = useIntl();

	return (
		<SectionBody>
			{formatMessage(messages.unknownProject)}
			{/* @ts-expect-error Incompatible ref type in tooltip */}
			<Tooltip content={formatMessage(messages.infoTooltip)} tag={QuestionCircleIconWrapper}>
				<QuestionCircle label={formatMessage(messages.infoTooltip)} size="small" />
			</Tooltip>
		</SectionBody>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QuestionCircleIconWrapper = styled.span({
	display: 'inline-block',
	paddingLeft: token('space.050', '4px'),
	verticalAlign: 'middle',
	cursor: 'pointer',
});
