import React, { createRef, useCallback, useState, useEffect, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	children: ReactNode;
	maxHeight?: number;
};

const DEFAULT_MAX_HEIGHT = 200;

export const ShowMore = ({ children, maxHeight = DEFAULT_MAX_HEIGHT }: Props) => {
	const contentRef = createRef<HTMLParagraphElement>();
	const [shouldShowShowMore, setShouldShowShowMore] = useState(false);
	const [collapsed, setCollapsed] = useState(true);
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (contentRef.current !== null && contentRef.current.clientHeight > maxHeight) {
			setShouldShowShowMore(true);
		}
	}, [contentRef, maxHeight]);

	const toggleShowMore = useCallback(() => {
		setCollapsed(!collapsed);
	}, [collapsed]);

	return (
		<>
			<Container collapsed={collapsed} maxHeight={maxHeight}>
				<p ref={contentRef}>{children}</p>
			</Container>
			{shouldShowShowMore && (
				<ButtonContainer>
					<Button appearance="link" spacing="none" onClick={toggleShowMore}>
						{collapsed ? formatMessage(messages.showMore) : formatMessage(messages.showLess)}
					</Button>
				</ButtonContainer>
			)}
		</>
	);
};

type ContainerProps = { collapsed: boolean; maxHeight: string | number };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<ContainerProps>(
	{
		margin: `${token('space.100', '8px')} 0`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ collapsed, maxHeight }) =>
		collapsed
			? `
                max-height: ${maxHeight}px;
                overflow: hidden;
            `
			: '',
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		p: {
			overflowWrap: 'break-word',
		},
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		fontWeight: 'normal',
	},
});
