import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import OpTrigger from '@atlassian/jira-common-util-op-triggers/src/on-mount';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling';
import { ff } from '@atlassian/jira-feature-flagging';
import { FetchError } from '@atlassian/jira-fetch';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_IssueResults_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_issueResults.graphql';
import type { main_issueNavigator_IssueResults_view$key as ViewFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_view.graphql';
import type { main_issueNavigator_IssueResults_viewResult$key as ViewResultFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_viewResult.graphql';
import type { main_issueNavigator_ListView_filter$key as FilterFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_filter.graphql';
import { useRouterActions } from '@atlassian/jira-router';
import UFOLabel from '@atlassian/jira-ufo-label';
import {
	ANALYTICS_DETAIL_VIEW_SOURCE_NAME,
	ANALYTICS_LIST_VIEW_SOURCE_NAME,
	PACKAGE_NAME,
	TEAM_NAME,
} from '../../common/constants';
import type { OverridableIssueTableProps } from '../../common/types';
import { ListViewSkeleton } from '../../common/ui/loading-skeleton';
import { withReportErrors } from '../../common/ui/with-report-errors';
import { markOnce, marks } from '../../common/utils/performance-analytics';
import { useSelectedIssueIndex } from '../../controllers/selected-issue-state';
import { useSelectedViewState } from '../../controllers/selected-view-state';
import { SortContainer } from '../../controllers/sort-field-and-direction-state';
import { useResolvedJql } from '../../services/active-jql';
import { useIssueSearchQuery } from '../../services/issue-search-query';
import DetailView from './detail-view';
import ErrorView, { RecoverableErrorViewBoundary } from './error-view';
import ListView from './list-view/main';

type Props = {
	issueResults: IssueResultsFragment | null;
	view: ViewFragment | null;
	viewResult: ViewResultFragment | null;
	onChangeColumnConfiguration?: () => void;
	onChangeJql?: (jql: string) => void;
	onCaughtIssueSearchError?: (error?: Error) => void;
	isFeedbackButtonDisabled?: boolean;
	issueTableProps?: OverridableIssueTableProps;
	filter: FilterFragment | null;
};

const IssueResults = ({
	onChangeColumnConfiguration,
	onChangeJql,
	onCaughtIssueSearchError,
	issueResults,
	view,
	viewResult,
	isFeedbackButtonDisabled,
	issueTableProps,
	filter,
}: Props) => {
	markOnce(marks.ISSUE_RESULTS_START);
	const [, { setSelectedIssueByIndex }] = useSelectedIssueIndex();

	const [{ view: selectedView }] = useSelectedViewState();
	const jql = useResolvedJql();

	const { push } = useRouterActions();

	const onSelectedRowChange = useCallback(
		(rowIndex: number) => {
			setSelectedIssueByIndex(rowIndex, false, false, true);
		},
		[setSelectedIssueByIndex],
	);

	const { isFetching } = useIssueSearchQuery();

	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment main_issueNavigator_IssueResults_issueResults on JiraIssueConnection {
				...main_issueNavigator_ListView_issueResults
				...main_issueNavigator_DetailView
				issueSearchError {
					__typename
				}
			}
		`,
		issueResults,
	);

	const viewData = useFragment<ViewFragment>(
		graphql`
			fragment main_issueNavigator_IssueResults_view on JiraIssueSearchView {
				...main_issueNavigator_ListView_view
			}
		`,
		view,
	);

	const viewResultData = useFragment<ViewResultFragment>(
		graphql`
			fragment main_issueNavigator_IssueResults_viewResult on JiraIssueSearchViewResult {
				... on JiraIssueSearchView {
					...main_issueNavigator_ListView_view
				}
				... on QueryError {
					message
					extensions {
						statusCode
					}
					__typename
				}
			}
		`,
		viewResult,
	);

	const viewResultError = ff(
		'handle-404-filter-errors-gracefully-in-the-ui-by-showing-filter-not-found-view_t3d2m',
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(
				() =>
					viewResultData?.__typename === 'QueryError' &&
					viewResultData?.extensions?.[0]?.statusCode !== undefined &&
					viewResultData?.extensions?.[0]?.statusCode !== null
						? new FetchError(
								viewResultData?.extensions?.[0]?.statusCode,
								viewResultData?.message ?? '',
							)
						: undefined,
				[viewResultData],
			)
		: undefined;

	useLayoutEffect(() => {
		markOnce(marks.ISSUE_RESULTS_END);
	}, []);

	const onNavigateToIssue = useCallback((issueKey: string) => push(`/browse/${issueKey}`), [push]);

	const sourceName =
		selectedView === 'detail' ? ANALYTICS_DETAIL_VIEW_SOURCE_NAME : ANALYTICS_LIST_VIEW_SOURCE_NAME;
	let ListViewComponent;

	if (ff('handle-404-filter-errors-gracefully-in-the-ui-by-showing-filter-not-found-view_t3d2m')) {
		if (
			!issueResultsData ||
			issueResultsData.issueSearchError?.__typename === 'JiraServerError' ||
			!viewResultData ||
			viewResultData?.__typename === 'QueryError'
		) {
			return isFetching ? <ListViewSkeleton /> : <ErrorView error={viewResultError} />;
		}

		ListViewComponent = (
			<ListView
				loading={isFetching}
				onSelectedRowChange={onSelectedRowChange}
				issueResults={issueResultsData}
				view={viewResultData}
				onChangeColumnConfiguration={onChangeColumnConfiguration}
				isFeedbackButtonDisabled={isFeedbackButtonDisabled}
				issueTableProps={issueTableProps}
				filter={filter}
			/>
		);
	} else {
		const isFailedIssueSearch =
			!issueResultsData ||
			issueResultsData.issueSearchError?.__typename === 'JiraServerError' ||
			!viewData;
		if (isFailedIssueSearch) {
			return isFetching ? <ListViewSkeleton /> : <ErrorView error={viewResultError} />;
		}

		ListViewComponent = (
			<ListView
				loading={isFetching}
				onSelectedRowChange={onSelectedRowChange}
				issueResults={issueResultsData}
				view={viewData}
				onChangeColumnConfiguration={onChangeColumnConfiguration}
				isFeedbackButtonDisabled={isFeedbackButtonDisabled}
				issueTableProps={issueTableProps}
				filter={filter}
			/>
		);
	}

	return (
		<UFOLabel name="issue-results">
			<ContextualAnalyticsData sourceName={sourceName} sourceType={SCREEN}>
				<RecoverableErrorViewBoundary
					recoveryProp={isFetching}
					onCaughtIssueSearchError={onCaughtIssueSearchError}
				>
					<SortContainer jql={jql} onChangeJql={onChangeJql}>
						{selectedView !== 'detail' ? (
							ListViewComponent
						) : (
							<DetailView
								loading={isFetching}
								onNavigateToIssue={onNavigateToIssue}
								issueResults={issueResultsData}
								isFeedbackButtonDisabled={isFeedbackButtonDisabled}
							/>
						)}
					</SortContainer>
					<OpTrigger
						op={() => {
							onCaughtIssueSearchError && onCaughtIssueSearchError(undefined);
						}}
					/>
				</RecoverableErrorViewBoundary>
			</ContextualAnalyticsData>
		</UFOLabel>
	);
};

export default withReportErrors<Props>(IssueResults, {
	id: 'ui.issue-results.unhandled',
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
	sendToPrivacyUnsafeSplunk: true,
	attributes: isLoaderErrorAttributes,
});
