import { flush } from './sequences';
import { rootRegistry, type ActiveKeyMap } from './shortcut-registry';
import type { KeySequence } from './types';

const matches = (prefix: string) => (definedSequence: string) =>
	definedSequence.startsWith(`${prefix} `);
/**
 * Manages key sequence inputs to execute predefined actions or clear unmatched sequences. This function ensures efficient handling of user inputs by checking against existing sequences and preparing for potential future matches.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (keySequence?: KeySequence): void => {
	const { sequence, e } = keySequence || {};
	const activeKeyMap: ActiveKeyMap = rootRegistry.getActiveKeyMap();
	if (sequence !== undefined && activeKeyMap[sequence]) {
		activeKeyMap[sequence].callback(e);
		flush();
	} else {
		// Are there any sequences that might be activated with more key presses?
		const isFutureMatchPossible =
			sequence !== undefined && Object.keys(activeKeyMap).some(matches(sequence));
		if (!isFutureMatchPossible) {
			flush();
		}
	}
};
