import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useScrollState } from '@atlassian/jira-software-fast-virtual';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useTableSize } from '../../../../controllers/table-size';
import { BORDER_WIDTH } from '../../../constants';
import type { FooterProps } from '../../../types';

/**
 * Default footer element to display beneath the issue table.
 */
export const Footer = (props: FooterProps) => {
	const { start = SpacerElement, center = SpacerElement, end = SpacerElement } = props;

	let hasShadow = false;

	if (ff('jsv-one-issue-table-spreadsheet-ui_ygrnf')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { height: containerHeight, scrollTop } = useScrollState();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { height: tableHeight } = useTableSize();
		// Scroll measurements are fractional, while height measurements are integer, so we need to round before comparing
		hasShadow =
			containerHeight < tableHeight && Math.round(scrollTop) < tableHeight - containerHeight;
	}

	return (
		<FooterContainer
			data-testid="native-issue-table.common.ui.components.footer.footer-container"
			hasShadow={hasShadow}
		>
			{start}
			{center}
			{end}
		</FooterContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.div<{ hasShadow: boolean }>(
	{
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		position: 'sticky',
		left: 0,
		bottom: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface', colors.N0),
		/* adding 2px left and right padding for focus border of child elements */
		padding: `${token('space.075', '6px')} ${token('space.025', '2px')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	() =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		ff('jsv-one-issue-table-spreadsheet-ui_ygrnf')
			? {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					borderTop: `${BORDER_WIDTH} solid ${token('color.border', '#091e4221')}`,
					/* adding 4px left and right padding for focus border of child elements */
					padding: token('space.050', '4px'),
				}
			: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					borderTop: `2px solid ${token('color.border', colors.N40)}`,
				},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ hasShadow }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		ff('jsv-one-issue-table-spreadsheet-ui_ygrnf') &&
		hasShadow && {
			boxShadow: `
                0px -8px 8px -8px ${token('elevation.shadow.overflow.spread', '#091e4229')},
                0px -1px 1px -1px ${token('elevation.shadow.overflow.perimeter', '#091e421f')}
            `,
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Spacer = styled.div({
	display: 'flex',
	flex: 1,
});

const SpacerElement = <Spacer />;
