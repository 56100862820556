import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noIssues: {
		// i18n ID doesn't match package, as this message has been moved but we
		// do not want the translations to become invalidated
		// eslint-disable-next-line jira/i18n/id-named-by-package
		id: 'issue-navigator.common.empty-list-icon.no-issues',
		defaultMessage: 'No issues were found matching your search',
		description: 'This message is displayed when no issues matched the search criteria',
	},
});
