import type { LegacyShareClientConfig } from '@atlassian/jira-share-button';
import { INTEGRATION_TYPES } from '../constants';

export const shareSearchClientConfig: LegacyShareClientConfig = {
	getShareEndpoint: ({ link, type }) =>
		type === INTEGRATION_TYPES.FILTER
			? `/rest/share/1.0/filter/${new URL(link).searchParams.get('filter') || ''}`
			: '/rest/share/1.0/search',

	getSharePayload: ({ link, type }) => {
		const jql = new URL(link).searchParams.get('jql');

		return type === INTEGRATION_TYPES.SEARCH && jql !== null ? { jql } : {};
	},
};
