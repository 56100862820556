import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import { smallDurationMs, easeOut } from '@atlaskit/motion';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { visuallyHiddenStyles } from '@atlassian/jira-accessibility';
import { borderRadius } from '@atlassian/jira-common-styles/src';
import Option from './option';
import type { ToggleButtonsProps } from './types';
import { assertPrecondition, findDuplicateIds } from './utils';

let uniqueId = 0;

type OptionPosition = { left: number; top: number; width: number; height: number };

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ label, options, selectedOption, onChange }: ToggleButtonsProps) => {
	assertPrecondition(() => ({
		precondition: options.length > 0,
		message: `ToggleButtons requires at least one option, but received ${options.length}`,
	}));
	assertPrecondition(() => ({
		precondition: findDuplicateIds(options) === null,
		message: `ToggleButtons requires that every option has a unique ID, but received "${findDuplicateIds(
			options,
		)}" multiple times`,
	}));
	assertPrecondition(() => ({
		precondition: options.findIndex((option) => option.id === selectedOption) !== -1,
		message: `ToggleButtons requires that the selectedOption matches the ID of one of the passed options, but "${selectedOption}" did not`,
	}));

	const [id] = useState(() => `toggle-buttons-${uniqueId++}`);
	const fieldsetRef = useRef<HTMLFieldSetElement>(null);
	const selectedOptionRef = useRef<HTMLElement>();
	const [selectedOptionPosition, setSelectedOptionPosition] = useState<
		OptionPosition | undefined
	>();

	useEffect(() => {
		const fieldset = fieldsetRef.current;
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		if (!fieldset || !window.ResizeObserver) return () => {};

		const resizeObserver = new window.ResizeObserver(() => {
			const element = selectedOptionRef.current;
			if (element) {
				setSelectedOptionPosition({
					left: element.offsetLeft,
					top: element.offsetTop,
					width: element.offsetWidth,
					height: element.offsetHeight,
				});
			}
		});

		resizeObserver.observe(fieldset);
		return () => {
			resizeObserver.unobserve(fieldset);
		};
	}, [selectedOption]);

	return (
		<Fieldset ref={fieldsetRef} selectedOptionPosition={selectedOptionPosition}>
			<legend>{label}</legend>
			{options.map((option) => {
				const isChecked = selectedOption === option.id;
				return (
					<Option
						key={option.id}
						name={id}
						value={option.id}
						isChecked={isChecked}
						isDisabled={!!option.isDisabled}
						onChange={onChange}
						// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown> | undefined' is not assignable to type 'Ref<OptionProps> | undefined'.
						ref={isChecked ? selectedOptionRef : undefined}
						testId={option.testId}
					>
						{option.label}
					</Option>
				);
			})}
		</Fieldset>
	);
};

// The !important rules are required so that we can use this mixin on selectors
// with arbitrary specificity
const selectedOptionIndicator = {
	backgroundColor: `${token('color.background.accent.gray.subtler', colors.N0)} !important`,
	borderRadius: `${borderRadius}px !important`,
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Fieldset = styled.fieldset<{
	selectedOptionPosition?: OptionPosition;
}>(
	{
		display: 'flex',
		flexWrap: 'wrap',
		width: 'max-content',
		maxWidth: '100%',
		/*
		 * Creates a positioning context for the absolutely positioned &::before
		 * psuedo-element
		 */
		position: 'relative',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral', colors.N20),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${borderRadius}px`,
		padding: token('space.050', '4px'),
		font: token('font.body.UNSAFE_small'),
		fontWeight: token('font.weight.semibold'),
		lineHeight: 16 / 12,
		textTransform: 'uppercase',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N700),
		/*
		 * The legend is important for screen-readers, but does not need to be
		 * visually rendered
		 */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		legend: visuallyHiddenStyles,
		/*
		 * Ensures children are rendered above the absolutely positioned
		 * &::before pseudo-element
		 */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > *': {
			position: 'relative',
			marginTop: token('space.0', '0'),
			marginRight: token('space.050', '4px'),
			marginBottom: token('space.0', '0'),
			marginLeft: token('space.0', '0'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:last-child': {
				marginRight: 0,
			},
		},
		/*
		 * Pseudo element is used to create a selected option indicator which can
		 * be positioned independently from the actual selected option. This is so
		 * that we can animate the indicator "sliding" between options as
		 * they're selected
		 */
		'&::before': {
			content: "''",
			/*
			 * We only render this element when we have dimensions for it. This
			 * prevents the element "transitioning" into position on initial
			 * page load.
			 */
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			display: (props) => (props.selectedOptionPosition ? 'block' : 'none'),
			position: 'absolute',
			// Positionings are to handle the toggle effect and resizing, design tokens are not relevant here

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			left: (props) =>
				props.selectedOptionPosition?.left ? `${props.selectedOptionPosition?.left}px` : 0,

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			top: (props) =>
				props.selectedOptionPosition?.top ? `${props.selectedOptionPosition?.top}px` : 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			width: (props) =>
				props.selectedOptionPosition?.width ? `${props.selectedOptionPosition?.width}px` : 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			height: (props) =>
				props.selectedOptionPosition?.height ? `${props.selectedOptionPosition?.height}px` : 0,

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			...selectedOptionIndicator,
			transitionProperty: 'left, top, width, height',
			transitionDuration: `${smallDurationMs}ms`,
			transitionTimingFunction: easeOut,
		},
	},
	/*
	 * We render a fallback selected option indicator when we don't have
	 * dimensions. This allows us to indicate the selected option when
	 * rendering server-side.
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.selectedOptionPosition
			? undefined
			: {
					'input:checked + label': selectedOptionIndicator,
				},
);
