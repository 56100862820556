import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorState as BaseErrorState } from '@atlassian/jira-issue-navigator-actions-common';
import messages from './messages';

export type Props = {
	error: Error;
	onRetry: () => void;
};

export const ErrorState = ({ error, onRetry, ...props }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<BaseErrorState
			{...props}
			onRetry={onRetry}
			description={formatMessage(messages.errorMessage)}
			pageAction="detailsPopup"
		/>
	);
};
