import type { JiraJQLBuilderSearchMode } from '@atlassian/jira-relay/src/__generated__/searchModeJqlBuilder_updateJQLBuilderSearchModeMutation.graphql';
import {
	SEARCH_MODE_ADVANCED,
	DEFAULT_SEARCH_MODE,
	SERVER_SEARCH_MODE_ADVANCED,
	SERVER_SEARCH_MODE_BASIC,
} from './constants';
import type { SearchMode, ServerSearchMode } from './types';

/**
 * Used to take a search mode server response and map it to a valid search mode that is
 * recognised by jql-builder (typically 'basic' | 'advanced').
 *
 * We do this to decouple search mode keys from the backend. If the backend changes how search modes
 * are provided, the experience will not break for users, instead, they will be defaulted to 'basic' mode.
 *
 * If we see a spike in users landing on basic mode this could indicate issues with the value being
 * received from the backend.
 */
export function toSearchModeWithBasicModeFallback(
	value: JiraJQLBuilderSearchMode | null | undefined,
): SearchMode {
	switch (value) {
		case SERVER_SEARCH_MODE_ADVANCED:
			return SEARCH_MODE_ADVANCED;
		default:
			return DEFAULT_SEARCH_MODE;
	}
}

/**
 * The reverse of `toSearchModeWithBasicModeFallback` where a locally supported search mode (typically 'basic' | 'advanced')
 * is converted back to its server representation.
 * This function is typically used to store updated preferred search mode on the server for a user
 *
 * Note - We use a default fallback of basic mode here but this will never have unintended behaviour since
 * typescript prevents us from passing in anything other than `SearchMode` as an argument, which will **always**
 * have a value and never be falsey or undefined.
 */
export function toServerSearchMode(mode: SearchMode): ServerSearchMode {
	switch (mode) {
		case SEARCH_MODE_ADVANCED:
			return SERVER_SEARCH_MODE_ADVANCED;
		default:
			return SERVER_SEARCH_MODE_BASIC;
	}
}
