import { defineMessages } from '@atlassian/jira-intl'; // TODO - Port i18n string translations to new module ID keys
/* eslint-disable jira/i18n/id-named-by-package */

export default defineMessages({
	nextIssue: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.next-issue',
		defaultMessage: 'Next issue',
		description: '',
	},
	previousIssue: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.previous-issue',
		defaultMessage: 'Previous issue',
		description: '',
	},
	displaySortFields: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.display-sort-fields',
		defaultMessage: 'Detail view order by',
		description: 'Opens the sort field selector dropdown',
	},
	openIssueFullScreen: {
		id: 'issue-navigator.card-list.keyboard-shortcuts.open-issue-full-screen',
		defaultMessage: 'Open issue fullscreen',
		description: 'Opens the issue, taking up the entire screen',
	},
});
