/**
 * @generated SignedSource<<bd1a202c59b76d20d2e6f59479005e11>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_view$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_view">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_view";
};
export type main_issueNavigator_IssueNavigatorUI_view$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueResults_view"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "e8840d03c4ef04a7162079f3724c9689";

export default node;
