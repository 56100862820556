import type { IssueLinkDirection } from '@atlassian/jira-issue-shared-types';
import type { IssueId } from '@atlassian/jira-shared-types';

const FIELD_CHANGE_REQUESTED = 'FIELD_CHANGE_REQUESTED' as const;
const FIELD_CHANGED = 'FIELD_CHANGED' as const;
const FIELD_CHANGE_FAILED = 'FIELD_CHANGE_FAILED' as const;
const ISSUE_TYPE_CHANGED = 'ISSUE_TYPE_CHANGED' as const;
const ISSUE_RELATIONSHIP_UPDATED = 'ISSUE_RELATIONSHIP_UPDATED' as const;
const ISSUE_LINKS_CREATED = 'ISSUE_LINKS_CREATED' as const;
const ISSUE_LINK_DELETED = 'ISSUE_LINK_DELETED' as const;
const CHILD_ISSUE_ADDED = 'CHILD_ISSUE_ADDED' as const;
const SUBTASK_ADDED = 'SUBTASK_ADDED' as const;
const ISSUE_CHILDREN_ORDER_CHANGED = 'ISSUE_CHILDREN_ORDER_CHANGED' as const;
const CHILD_ISSUE_FIELD_UPDATED = 'CHILD_ISSUE_FIELD_UPDATED' as const;
const CREATE_CONFLUENCE_PAGE_LINK_SUCCESS = 'CREATE_CONFLUENCE_PAGE_LINK_SUCCESS' as const;
const DELETE_CONFLUENCE_PAGE_LINK_SUCCESS = 'DELETE_CONFLUENCE_PAGE_LINK_SUCCESS' as const;

export const ChangeEventTypes = {
	[FIELD_CHANGE_REQUESTED]: FIELD_CHANGE_REQUESTED,
	[FIELD_CHANGE_FAILED]: FIELD_CHANGE_FAILED,
	[FIELD_CHANGED]: FIELD_CHANGED,
	[ISSUE_TYPE_CHANGED]: ISSUE_TYPE_CHANGED,
	[ISSUE_RELATIONSHIP_UPDATED]: ISSUE_RELATIONSHIP_UPDATED,
	[ISSUE_LINKS_CREATED]: ISSUE_LINKS_CREATED,
	[ISSUE_LINK_DELETED]: ISSUE_LINK_DELETED,
	[CHILD_ISSUE_ADDED]: CHILD_ISSUE_ADDED,
	[SUBTASK_ADDED]: SUBTASK_ADDED,
	[ISSUE_CHILDREN_ORDER_CHANGED]: ISSUE_CHILDREN_ORDER_CHANGED,
	[CHILD_ISSUE_FIELD_UPDATED]: CHILD_ISSUE_FIELD_UPDATED,
	[CREATE_CONFLUENCE_PAGE_LINK_SUCCESS]: CREATE_CONFLUENCE_PAGE_LINK_SUCCESS,
	[DELETE_CONFLUENCE_PAGE_LINK_SUCCESS]: DELETE_CONFLUENCE_PAGE_LINK_SUCCESS,
} as const;

/**
 * Optimistic update event
 * Dispatched whenever a field change is initiated
 */
export type FieldChangeRequestedEvent = {
	issueId: IssueId;
	type: typeof FIELD_CHANGE_REQUESTED;
	meta: {
		fieldId: string;
		/**
		 * `fieldValue` should be used very carefully
		 * We can not guarantee type safety and the fact, that the value won't change in the future
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fieldValue: any;
	};
};

/**
 * Optimistic update event
 * Dispatched whenever a field change is failed on the server
 */
export type FieldChangeFailed = {
	issueId: IssueId;
	type: typeof FIELD_CHANGE_FAILED;
	meta: {
		fieldId: string;
	};
};

export type FieldChangedEvent = {
	issueId: IssueId;
	type: typeof FIELD_CHANGED;
	// field id is required to be compatible with v1 onChange API
	fieldId: string;
	meta: {
		fieldId: string;
		/**
		 * `fieldValue` should be used very carefully
		 * We can not guarantee type safety and the fact, that the value won't change in the future
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fieldValue: any;
	};
};

export type IssueTypeChangedEvent = {
	issueId: IssueId;
	type: typeof ISSUE_TYPE_CHANGED;
	// field id is required to be compatible with v1 onChange API
	fieldId: string;
	meta: {
		typeName: string;
	};
};

export type IssueRelationshipUpdatedEvent = {
	issueId: IssueId;
	type: typeof ISSUE_RELATIONSHIP_UPDATED;
	meta: {
		newParentIssueId: IssueId | null;
		oldParentIssueId: IssueId | null;
	};
};

export type ChangedIssueLink = {
	linkedIssueId: IssueId;
	direction: IssueLinkDirection;
};

export type IssueLinksCreatedEvent = {
	issueId: IssueId;
	type: typeof ISSUE_LINKS_CREATED;
	meta: {
		links: ChangedIssueLink[];
	};
};

export type IssueLinkDeletedEvent = {
	issueId: IssueId;
	type: typeof ISSUE_LINK_DELETED;
	meta: ChangedIssueLink;
};

export type ChildIssueAddedEvent = {
	issueId: IssueId;
	type: typeof CHILD_ISSUE_ADDED;
	meta: {
		childIssue: {
			id: IssueId;
			issueKey?: string;
			issueLink?: string;
			issueSummary?: string;
			issueTypeIconUrl?: string;
			issueTypeName?: string;
		};
	};
};

export type SubtaskAddedEvent = {
	issueId: IssueId;
	type: typeof SUBTASK_ADDED;
	meta: {
		childIssue: {
			id: IssueId;
			issueKey?: string;
			issueLink?: string;
			issueSummary?: string;
			issueTypeIconUrl?: string;
			issueTypeName?: string;
		};
	};
};

export type IssueChildrenOrderChangedEvent = {
	issueId: IssueId;
	type: typeof ISSUE_CHILDREN_ORDER_CHANGED;
	meta: {
		issueIds: IssueId[];
		rankId: number;
		isRankAfter: boolean;
	};
};

export type ChildIssueFieldUpdatedEvent = {
	issueId: IssueId;
	type: typeof CHILD_ISSUE_FIELD_UPDATED;
	meta: {
		childIssueId: IssueId;
	};
};

export type CreateConfluencePageLinkSuccess = {
	issueId: IssueId;
	type: typeof CREATE_CONFLUENCE_PAGE_LINK_SUCCESS;
	meta?: {};
};

export type DeleteConfluencePageLinkSuccess = {
	issueId: IssueId;
	type: typeof DELETE_CONFLUENCE_PAGE_LINK_SUCCESS;
	meta?: {};
};

export type ChangeEvent =
	| FieldChangeRequestedEvent
	| FieldChangedEvent
	| FieldChangeFailed
	| IssueTypeChangedEvent
	| IssueRelationshipUpdatedEvent
	| IssueLinksCreatedEvent
	| IssueLinkDeletedEvent
	| ChildIssueAddedEvent
	| SubtaskAddedEvent
	| IssueChildrenOrderChangedEvent
	| ChildIssueFieldUpdatedEvent
	| CreateConfluencePageLinkSuccess
	| DeleteConfluencePageLinkSuccess;

export type OnChangeCallback = (arg1: ChangeEvent) => void;
