import React, { createContext, type ReactNode, type RefObject, useContext } from 'react';
import { DEFAULT_SIZE, type Size, useElementSize } from '../element-size';

type Props = {
	children: ReactNode;
	tableRef: RefObject<HTMLTableElement>;
};

const TableSizeContext = createContext(DEFAULT_SIZE);

export const TableSizeProvider = ({ children, tableRef }: Props) => {
	const tableSize = useElementSize(tableRef);
	return <TableSizeContext.Provider value={tableSize}>{children}</TableSizeContext.Provider>;
};

export const useTableSize = (): Size => useContext(TableSizeContext);
