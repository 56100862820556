/**
 * @generated SignedSource<<355e9dd885145701d04f73ed32eadd92>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_FullPageIssueApp$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueAppWithData">;
  readonly " $fragmentType": "main_issueNavigator_FullPageIssueApp";
};
export type main_issueNavigator_FullPageIssueApp$key = {
  readonly " $data"?: main_issueNavigator_FullPageIssueApp$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_FullPageIssueApp">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_FullPageIssueApp",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueAppWithData"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "f4cc24bfb9dd3b8b33dee658cbe69429";

export default node;
