import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import DisabledButtonWithTooltip from '@atlassian/jira-disabled-button-with-tooltip';

const backgroundColorSelected = token('color.background.selected', colors.B50);
const backgroundColor = token('elevation.surface.raised', colors.N0);
const borderColor = token('color.border', colors.N30);
const boxShadowColor = `1px 0px ${token('elevation.shadow.raised', colors.N40)}`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardBaseStyle = styled.a<{ isSelected?: boolean }>({
	display: 'block',
	padding: token('space.100', '8px'),
	boxSizing: 'border-box',
	borderTop: 0,
	boxShadow: `1px 0px ${boxShadowColor}`,
	borderBottom: `1px solid ${borderColor}`,
	borderLeft: `1px solid ${borderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) => (props.isSelected ? backgroundColorSelected : backgroundColor),
	'&:hover': {
		textDecoration: 'none',
	},
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `${token('color.border.focused', colors.B200)} 0 0 0 2px inset`,
		textDecoration: 'none',
		outline: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		borderTop: `1px solid ${borderColor}`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${gridSize * 0.5}px ${gridSize * 0.5}px 0 0`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		boxShadow: `1px 1px ${boxShadowColor}`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `0 0 ${gridSize * 0.5}px ${gridSize * 0.5}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type:last-child': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${gridSize * 0.5}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FullHeightDisabledButton = styled(DisabledButtonWithTooltip)({
	height: '100%',
});
