// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { type ReactNode, Fragment, useEffect, memo, forwardRef } from 'react';
import { styled } from '@compiled/react';
import { type PreloadedQuery, graphql, usePreloadedQuery, useQueryLoader } from 'react-relay';
import Tooltip from '@atlaskit/tooltip';
import { ProjectBreadcrumb, BreadcrumbRenderer } from '@atlassian/jira-breadcrumbs/src';
import PageHeader from '@atlassian/jira-common-components-page-header';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling';
import { DocumentTitle } from '@atlassian/jira-global-document-title';
import { useIntl } from '@atlassian/jira-intl';
import type { CustomHeaderProps } from '@atlassian/jira-issue-navigator';
import { isCustomFilter, isFilterId } from '@atlassian/jira-issue-navigator-actions-common';
import {
	DetailsButtonHeaderWrapper,
	DetailsPopup,
	FavouriteButton,
	FavouriteButtonHeaderWrapper,
} from '@atlassian/jira-issue-navigator-custom-filters';
import Placeholder from '@atlassian/jira-placeholder';
import PROJECT_NAME_QUERY_AGG, {
	type mainProjectNameQuery,
} from '@atlassian/jira-relay/src/__generated__/mainProjectNameQuery.graphql';
import { useIsAnonymous, useCloudId } from '@atlassian/jira-tenant-context-controller';
import messages from './messages';
import PageActions from './page-actions';
import { HeaderContentWrapper } from './styled';

export const FilterTitleTooltipTag = forwardRef<HTMLSpanElement, { children?: ReactNode }>(
	(props, ref) => <FilterTitleTooltipTagStyled ref={ref} {...props} />,
);

export type Props = {
	projectKey: string;
	isQuickFiltersDisabled?: boolean;
} & CustomHeaderProps;

type ProjectBreadcrumbWithDataProps = {
	projectKey: string;
	queryReference: PreloadedQuery<mainProjectNameQuery>;
};

type BreadcrumbFallbackProps = {
	projectKey: string;
	projectName: string;
};

const ProjectBreadcrumbWithData = ({
	queryReference,
	projectKey,
}: ProjectBreadcrumbWithDataProps) => {
	const { formatMessage } = useIntl();

	const data = usePreloadedQuery<mainProjectNameQuery>(
		graphql`
			query mainProjectNameQuery($projectKey: String!, $cloudId: ID!) {
				jira {
					jiraProjectByKey(key: $projectKey, cloudId: $cloudId) {
						... on JiraProject {
							__typename
							name
						}
					}
				}
			}
		`,
		queryReference,
	);
	const projectName: string = data?.jira?.jiraProjectByKey?.name ?? '';

	return (
		<>
			<DocumentTitle title={formatMessage(messages.windowTitleMigration, { projectName })} />
			<ProjectBreadcrumb baseUrl="" projectKey={projectKey} projectName={projectName}>
				<BreadcrumbRenderer />
			</ProjectBreadcrumb>
		</>
	);
};

const BreadcrumbFallback = ({ projectKey, projectName }: BreadcrumbFallbackProps) => (
	<ProjectBreadcrumb baseUrl="" projectKey={projectKey} projectName={projectName}>
		<BreadcrumbRenderer />
	</ProjectBreadcrumb>
);

const Header = ({
	projectKey,
	jql,
	filterId,
	filterDetails,
	searchResultPageData,
	isQuickFiltersDisabled = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();
	const [queryReference, loadQuery] = useQueryLoader<mainProjectNameQuery>(PROJECT_NAME_QUERY_AGG);

	const cloudId = useCloudId();

	useEffect(() => {
		loadQuery({ projectKey, cloudId });
	}, [loadQuery, projectKey, cloudId]);

	const heading = formatMessage(messages.heading);

	return (
		<>
			<PageHeader
				breadcrumbs={
					<Placeholder
						name="page-header"
						fallback={<BreadcrumbFallback projectKey={projectKey} projectName="" />}
					>
						<JSErrorBoundary
							id="ui.project-name-query.boundary"
							packageName="projectIssueNavigator"
							teamName="empanada"
							sendToPrivacyUnsafeSplunk
							attributes={isLoaderErrorAttributes}
							fallback={() => (
								<BreadcrumbFallback projectKey={projectKey} projectName={projectKey} />
							)}
						>
							{queryReference !== null && queryReference !== undefined ? (
								<ProjectBreadcrumbWithData
									queryReference={queryReference}
									projectKey={projectKey}
								/>
							) : (
								<BreadcrumbFallback projectKey={projectKey} projectName="" />
							)}
						</JSErrorBoundary>
					</Placeholder>
				}
				actions={
					<PageActions
						jql={jql}
						filterId={filterId}
						isModifiedFilter={filterDetails?.jql !== jql}
						searchResultPageData={searchResultPageData}
						isQuickFiltersDisabled={isQuickFiltersDisabled}
					/>
				}
			>
				<HeaderContentWrapper>
					{isFilterId(filterId) && filterDetails ? (
						<>
							<Tooltip content={filterDetails.name} tag={FilterTitleTooltipTag} position="mouse">
								<FilterTitleTooltipContent>{filterDetails.name}</FilterTitleTooltipContent>
							</Tooltip>
							{!isAnonymous && (
								<FavouriteButtonHeaderWrapper>
									<FavouriteButton
										filterId={filterDetails.filterId}
										isFavouriteInitial={filterDetails.isFavourite}
									/>
								</FavouriteButtonHeaderWrapper>
							)}
							{isCustomFilter(filterDetails.filterId) && filterId !== undefined && (
								<DetailsButtonHeaderWrapper>
									<DetailsPopup filterId={filterId} />
								</DetailsButtonHeaderWrapper>
							)}
						</>
					) : (
						heading
					)}
				</HeaderContentWrapper>
			</PageHeader>
		</>
	);
};

const MemoHeader = memo<Props>(Header);

export default MemoHeader;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterTitleTooltipTagStyled = styled.span({
	display: 'flex',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterTitleTooltipContent = styled.span({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
