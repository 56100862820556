import React, { type ComponentType, useMemo } from 'react';
import { styled } from '@compiled/react';
import { ff } from '@atlassian/jira-feature-flagging';
import { components, type FooterProps } from '@atlassian/jira-native-issue-table';
import Feedback from '../../../../common/ui/feedback';
import { useIssueSearchQuery } from '../../../../services/issue-search-query';

/**
 * Override default issue table footer to conditionally hide pagination controls when issue data is stale.
 */
export const useFooterOverride = (
	isFeedbackButtonDisabled?: boolean,
): ComponentType<FooterProps> => {
	const { isStalePage } = useIssueSearchQuery();

	return useMemo(
		() =>
			({ end, ...props }: FooterProps) => {
				let start;
				// TODO EM-7315 Relocate feedback button
				if (ff('jsv-one-issue-table-issue-create')) {
					start = props.start;
				} else if (!isFeedbackButtonDisabled) {
					start = (
						<FeedbackContainer data-testid="issue-navigator.ui.issue-results.list-view.footer.feedback-wrapper">
							<Feedback />
						</FeedbackContainer>
					);
				}
				return (
					<components.Footer
						{...props}
						start={start}
						// Do not show pagination controls while issue data is stale as page cursor information will be inaccurate
						end={isStalePage ? undefined : end}
					/>
				);
			},
		[isFeedbackButtonDisabled, isStalePage],
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeedbackContainer = styled.div({
	display: 'flex',
	flex: 1,
});
