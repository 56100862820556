/**
 * @generated SignedSource<<11d520e79b36b2ae2ede3602da1ec555>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type selectedIssueState_issueNavigator_SelectedIssueContainer$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly key: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly issueNavigatorPageInfo: {
    readonly firstIssueKeyFromNextPage: string | null | undefined;
    readonly firstIssuePosition: number | null | undefined;
    readonly lastIssueKeyFromPreviousPage: string | null | undefined;
    readonly lastIssuePosition: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "selectedIssueState_issueNavigator_SelectedIssueContainer";
};
export type selectedIssueState_issueNavigator_SelectedIssueContainer$key = {
  readonly " $data"?: selectedIssueState_issueNavigator_SelectedIssueContainer$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectedIssueState_issueNavigator_SelectedIssueContainer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "selectedIssueState_issueNavigator_SelectedIssueContainer",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "key"
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraIssueNavigatorPageInfo",
      "kind": "LinkedField",
      "name": "issueNavigatorPageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "firstIssuePosition"
        },
        {
          "kind": "ScalarField",
          "name": "lastIssuePosition"
        },
        {
          "kind": "ScalarField",
          "name": "firstIssueKeyFromNextPage"
        },
        {
          "kind": "ScalarField",
          "name": "lastIssueKeyFromPreviousPage"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "44f9584373caeac4e6403c35d0ddb500";

export default node;
