/**
 * @generated SignedSource<<0a236f27c28dfc2d243f3bcac9e0ef56>>
 * @relayHash 929c7cfdabf85276ab021ca0aaf710c4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a3810891dc29174a6ad47903cc92470a66dff964fe69f524a8d29c29992b37a1

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type issueSearchTotalCountQuery$variables = {
  cloudId: string;
  issueSearchInput: JiraIssueSearchInput;
};
export type issueSearchTotalCountQuery$data = {
  readonly jira: {
    readonly issueSearchTotalCount: number | null | undefined;
  } | null | undefined;
};
export type issueSearchTotalCountQuery = {
  response: issueSearchTotalCountQuery$data;
  variables: issueSearchTotalCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueSearchInput"
  }
],
v1 = [
  {
    "concreteType": "JiraQuery",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "kind": "Variable",
            "name": "issueSearchInput",
            "variableName": "issueSearchInput"
          }
        ],
        "kind": "ScalarField",
        "name": "issueSearchTotalCount"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "issueSearchTotalCountQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "issueSearchTotalCountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "a3810891dc29174a6ad47903cc92470a66dff964fe69f524a8d29c29992b37a1",
    "metadata": {},
    "name": "issueSearchTotalCountQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "37773194a6f16231c4993e21b36ed872";

export default node;
