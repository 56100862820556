import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { SectionBody } from '../styled';
import messages from './messages';

type Props = {
	users: string[];
};

export const UsersGrant = ({ users }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<SectionBody>
			{formatMessage(messages.users)}:{' '}
			{users.map((user, index) => (
				<span key={user}>
					{user}
					{index < users.length - 1 ? ', ' : ''}
				</span>
			))}
		</SectionBody>
	);
};
