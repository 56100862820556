import { useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { fetchQuery, graphql } from 'relay-runtime';
import type { refetchIssue_IssueNavigatorQuery } from '@atlassian/jira-relay/src/__generated__/refetchIssue_IssueNavigatorQuery.graphql';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { MAX_AMOUNT_OF_COLUMNS } from '../../common/constants';

type OnIssueByFieldsRefetchType = (issuekey: string) => void;

export const useIssueByFieldsRefetch = (fieldSets: string[]): OnIssueByFieldsRefetchType => {
	const { cloudId } = useTenantContext();
	const environment = useRelayEnvironment();

	return useCallback(
		(issuekey: string) =>
			fetchQuery<refetchIssue_IssueNavigatorQuery>(
				environment,
				/* eslint-disable @atlassian/relay/unused-fields, @atlassian/relay/must-colocate-fragment-spreads */
				graphql`
					query refetchIssue_IssueNavigatorQuery(
						$cloudId: ID!
						$issueSearchInput: JiraIssueSearchInput!
						$fieldSetIds: [String!]!
						$amountOfColumns: Int!
					) {
						jira {
							issueSearchStable(cloudId: $cloudId, issueSearchInput: $issueSearchInput, first: 1) {
								edges {
									node {
										fieldSetsById(fieldSetIds: $fieldSetIds, first: $amountOfColumns) {
											...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetsForIssueSearchView
												@arguments(isInlineEditingEnabled: true, isDensityFull: false)
											...main_issueNavigator_Card_fieldSetsForIssueSearchView
										}
									}
								}
							}
						}
					}
				`,
				/* eslint-enable @atlassian/relay/unused-fields, @atlassian/relay/must-colocate-fragment-spreads */
				{
					cloudId,
					issueSearchInput: { jql: `issueKey=${issuekey}` },
					fieldSetIds: fieldSets,
					amountOfColumns: MAX_AMOUNT_OF_COLUMNS,
				},
				{ fetchPolicy: 'network-only' },
			).toPromise(),
		[cloudId, environment, fieldSets],
	);
};
