import React, { useRef, useMemo } from 'react';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueKey } from '@atlassian/jira-shared-types';
import { useOrderByOpenState } from '../../../../../controllers/order-by-open-state';
import {
	useSelectedIssueIndex,
	useSelectedIssueKey,
} from '../../../../../controllers/selected-issue-state';
import type { SelectedIndex } from '../../../../../controllers/selected-issue-state/types';
import messages from './messages';

type Props = {
	disableListControls?: boolean;
	onNavigateToIssue: (issueKey: string) => void;
	onIssueChangeEvent: (issueKey: IssueKey, issueIndex: number | null) => void;
	focusedIssueIndex: SelectedIndex;
};

const useUpdatingRef = <T,>(
	value: T,
): {
	current: T;
} => {
	const ref = useRef<T>(value);
	ref.current = value;
	return ref;
};

const KeyboardShortcuts = (props: Props) => {
	const { formatMessage } = useIntl();
	const propsRef = useUpdatingRef(props);
	const openStateRef = useUpdatingRef(useOrderByOpenState());
	const [
		selectedIssueKey,
		{ getNextIssue, selectNextIssue, getPreviousIssue, selectPreviousIssue },
	] = useSelectedIssueKey();
	const [selectedIssueIndex] = useSelectedIssueIndex();
	const selectedIssueKeyRef = useUpdatingRef(selectedIssueKey);
	const selectedIssueIndexRef = useUpdatingRef(selectedIssueIndex);

	return (
		<Shortcuts
			keyMap={useMemo(
				() => ({
					j: {
						callback: () => {
							const { disableListControls, onIssueChangeEvent } = propsRef.current;

							if (disableListControls === true) {
								return;
							}

							// getNextIssue and selectNextIssue need to be stable function references as
							// useMemo has an empty dependencies array
							const { issueKey, issueIndex } = getNextIssue();
							if (selectNextIssue(true)) {
								onIssueChangeEvent && onIssueChangeEvent(issueKey, issueIndex);
							}
						},
						label: <div>{formatMessage(messages.nextIssue)}</div>,
					},
					k: {
						callback: () => {
							const { disableListControls, onIssueChangeEvent } = propsRef.current;

							if (disableListControls === true) {
								return;
							}

							// getPreviousIssue and selectPreviousIssue need to be stable function references as
							// useMemo has an empty dependencies array
							const { issueKey, issueIndex } = getPreviousIssue();
							if (selectPreviousIssue(true)) {
								onIssueChangeEvent && onIssueChangeEvent(issueKey, issueIndex);
							}
						},
						label: <div>{formatMessage(messages.previousIssue)}</div>,
					},
					y: {
						callback: () => {
							const [{ isOpen }, { open, close }] = openStateRef.current;
							isOpen ? close() : open();
						},
						label: <div>{formatMessage(messages.displaySortFields)}</div>,
					},
					z: {
						callback: () => {
							if (selectedIssueKeyRef.current) {
								propsRef.current.onNavigateToIssue(selectedIssueKeyRef.current);
							}
						},
						label: <div>{formatMessage(messages.openIssueFullScreen)}</div>,
					},
					arrowup: {
						callback: () => {
							const { disableListControls, onIssueChangeEvent, focusedIssueIndex } =
								propsRef.current;

							if (disableListControls === true) {
								return;
							}

							const { issueKey, issueIndex } = getPreviousIssue();
							if (
								focusedIssueIndex !== null &&
								focusedIssueIndex === selectedIssueIndexRef.current
							) {
								if (selectPreviousIssue(true)) {
									onIssueChangeEvent && onIssueChangeEvent(issueKey, issueIndex);
								}
							}
						},
						label: <div>{formatMessage(messages.previousIssue)}</div>,
					},
					arrowdown: {
						callback: () => {
							const { disableListControls, onIssueChangeEvent, focusedIssueIndex } =
								propsRef.current;

							if (disableListControls === true) {
								return;
							}

							const { issueKey, issueIndex } = getNextIssue();
							if (
								focusedIssueIndex !== null &&
								focusedIssueIndex === selectedIssueIndexRef.current
							) {
								if (selectNextIssue(true)) {
									onIssueChangeEvent && onIssueChangeEvent(issueKey, issueIndex);
								}
							}
						},
						label: <div>{formatMessage(messages.nextIssue)}</div>,
					},
				}),
				[], // eslint-disable-line react-hooks/exhaustive-deps
			)}
		/>
	);
};

KeyboardShortcuts.defaultProps = {
	disableListControls: false,
};

export default KeyboardShortcuts;
