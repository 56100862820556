/**
 * @generated SignedSource<<4cd6e76e99270bd6d09f5899b7d9af74>>
 * @relayHash be052b019bfd1c93a16950437074382b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ae8845a2a014fa5b18b0dbb0fce3ac70b8726b42ad2762b25cdfd50b9215724e

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type refetchIssue_IssueNavigatorQuery$variables = {
  amountOfColumns: number;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  issueSearchInput: JiraIssueSearchInput;
};
export type refetchIssue_IssueNavigatorQuery$data = {
  readonly jira: {
    readonly issueSearchStable: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly fieldSetsById: {
            readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetsForIssueSearchView" | "main_issueNavigator_Card_fieldSetsForIssueSearchView">;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type refetchIssue_IssueNavigatorQuery = {
  response: refetchIssue_IssueNavigatorQuery$data;
  variables: refetchIssue_IssueNavigatorQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider": isDensityFullFFEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider": isSpreadsheetUIEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider": isIssueViewParentComponentEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
});

import isDensityFullFFEnabled_provider from '@atlassian/jira-relay-provider/src/is-density-enabled.relayprovider';
import isIssueViewParentComponentEnabled_provider from '@atlassian/jira-relay-provider/src/parent-from-issue-view.relayprovider';
import isSpreadsheetUIEnabled_provider from '@atlassian/jira-relay-provider/src/status-inline-edit.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v4 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "fieldSetIds",
    "variableName": "fieldSetIds"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "amountOfColumns"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "type"
},
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "kind": "ScalarField",
  "name": "name"
},
v11 = [
  (v10/*: any*/),
  (v9/*: any*/)
],
v12 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v13 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v14 = {
  "kind": "ScalarField",
  "name": "key"
},
v15 = {
  "kind": "ScalarField",
  "name": "text"
},
v16 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v17 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v16/*: any*/),
    (v9/*: any*/)
  ]
},
v18 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v19 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "small"
            }
          ]
        },
        (v10/*: any*/),
        (v9/*: any*/)
      ]
    }
  ],
  "type": "JiraIssueTypeField"
},
v20 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v9/*: any*/)
  ]
},
v21 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v22 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "concreteType": "JiraRichText",
        "kind": "LinkedField",
        "name": "richText",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraADF",
            "kind": "LinkedField",
            "name": "adfValue",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraAdfToConvertedPlainText",
                "kind": "LinkedField",
                "name": "convertedPlainText",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "plainText"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "JiraRichTextField"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "refetchIssue_IssueNavigatorQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearchStable",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": (v5/*: any*/),
                        "concreteType": "JiraIssueFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSetsById",
                        "plural": false,
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "isDensityFull",
                                "value": false
                              },
                              {
                                "kind": "Literal",
                                "name": "isInlineEditingEnabled",
                                "value": true
                              }
                            ],
                            "kind": "FragmentSpread",
                            "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSetsForIssueSearchView"
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "main_issueNavigator_Card_fieldSetsForIssueSearchView"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "refetchIssue_IssueNavigatorQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearchStable",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": (v5/*: any*/),
                        "concreteType": "JiraIssueFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSetsById",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "fieldSetId"
                                  },
                                  {
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fields",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v7/*: any*/),
                                              (v8/*: any*/),
                                              (v6/*: any*/),
                                              {
                                                "kind": "TypeDiscriminator",
                                                "abstractKey": "__isJiraIssueField"
                                              },
                                              (v9/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraAffectedServiceConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedAffectedServicesConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraAffectedServiceEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraAffectedService",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "serviceId"
                                                              },
                                                              (v10/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraAffectedServicesField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraCmdbObjectConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedCmdbObjectsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraCmdbObjectEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraCmdbObject",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "objectId"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "label"
                                                              },
                                                              {
                                                                "concreteType": "JiraCmdbAvatar",
                                                                "kind": "LinkedField",
                                                                "name": "avatar",
                                                                "plural": false,
                                                                "selections": [
                                                                  {
                                                                    "kind": "ScalarField",
                                                                    "name": "url48"
                                                                  }
                                                                ]
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        "concreteType": "QueryError",
                                                        "kind": "LinkedField",
                                                        "name": "errors",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "message"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraCMDBField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraComponentConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedComponentsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraComponentEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraComponent",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": (v11/*: any*/)
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraComponentsField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "date"
                                                  },
                                                  (v10/*: any*/),
                                                  (v12/*: any*/),
                                                  (v13/*: any*/)
                                                ],
                                                "type": "JiraDatePickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "dateTime"
                                                  }
                                                ],
                                                "type": "JiraDateTimePickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraEpic",
                                                    "kind": "LinkedField",
                                                    "name": "epic",
                                                    "plural": false,
                                                    "selections": [
                                                      (v14/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "summary"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "color"
                                                      },
                                                      (v9/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraEpicLinkField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "renderedFieldHtml"
                                                  }
                                                ],
                                                "type": "JiraFallbackField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v15/*: any*/),
                                                  (v10/*: any*/),
                                                  (v13/*: any*/)
                                                ],
                                                "type": "JiraSingleLineTextField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraStatusCategory",
                                                    "kind": "LinkedField",
                                                    "name": "statusCategory",
                                                    "plural": false,
                                                    "selections": [
                                                      (v10/*: any*/),
                                                      (v16/*: any*/),
                                                      (v9/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraStatusCategoryField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraStatus",
                                                    "kind": "LinkedField",
                                                    "name": "status",
                                                    "plural": false,
                                                    "selections": [
                                                      (v10/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "statusId"
                                                      },
                                                      (v17/*: any*/),
                                                      (v9/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraStatusField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "args": (v18/*: any*/),
                                                    "concreteType": "JiraLabelConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedLabelsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraLabelEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraLabel",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v10/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "totalCount"
                                                      }
                                                    ],
                                                    "storageKey": "selectedLabelsConnection(first:1000)"
                                                  },
                                                  (v10/*: any*/),
                                                  (v13/*: any*/)
                                                ],
                                                "type": "JiraLabelsField"
                                              },
                                              (v19/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssue",
                                                    "kind": "LinkedField",
                                                    "name": "parentIssue",
                                                    "plural": false,
                                                    "selections": [
                                                      (v14/*: any*/),
                                                      {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                          (v20/*: any*/)
                                                        ]
                                                      },
                                                      {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": false,
                                                        "selections": [
                                                          {
                                                            "args": [
                                                              {
                                                                "kind": "Literal",
                                                                "name": "ids",
                                                                "value": [
                                                                  "issuetype",
                                                                  "summary"
                                                                ]
                                                              }
                                                            ],
                                                            "concreteType": "JiraIssueFieldConnection",
                                                            "kind": "LinkedField",
                                                            "name": "fieldsById",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraIssueFieldEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                  {
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                      (v7/*: any*/),
                                                                      (v8/*: any*/),
                                                                      (v19/*: any*/),
                                                                      {
                                                                        "kind": "InlineFragment",
                                                                        "selections": [
                                                                          (v15/*: any*/)
                                                                        ],
                                                                        "type": "JiraSingleLineTextField"
                                                                      },
                                                                      (v9/*: any*/)
                                                                    ]
                                                                  }
                                                                ]
                                                              }
                                                            ],
                                                            "storageKey": "fieldsById(ids:[\"issuetype\",\"summary\"])"
                                                          }
                                                        ]
                                                      },
                                                      (v9/*: any*/)
                                                    ]
                                                  },
                                                  {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraIssue",
                                                        "kind": "LinkedField",
                                                        "name": "parentIssue",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraColorField",
                                                            "kind": "LinkedField",
                                                            "name": "issueColorField",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraColor",
                                                                "kind": "LinkedField",
                                                                "name": "color",
                                                                "plural": false,
                                                                "selections": [
                                                                  {
                                                                    "kind": "ScalarField",
                                                                    "name": "colorKey"
                                                                  },
                                                                  (v9/*: any*/)
                                                                ]
                                                              },
                                                              (v9/*: any*/)
                                                            ]
                                                          },
                                                          {
                                                            "concreteType": "JiraIssueTypeField",
                                                            "kind": "LinkedField",
                                                            "name": "issueTypeField",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraIssueType",
                                                                "kind": "LinkedField",
                                                                "name": "issueType",
                                                                "plural": false,
                                                                "selections": [
                                                                  (v21/*: any*/),
                                                                  (v10/*: any*/),
                                                                  (v9/*: any*/)
                                                                ]
                                                              },
                                                              (v9/*: any*/)
                                                            ]
                                                          },
                                                          (v20/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraParentIssueField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraPriority",
                                                    "kind": "LinkedField",
                                                    "name": "priority",
                                                    "plural": false,
                                                    "selections": [
                                                      (v10/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "iconUrl"
                                                      },
                                                      (v9/*: any*/)
                                                    ]
                                                  },
                                                  (v10/*: any*/),
                                                  (v13/*: any*/)
                                                ],
                                                "type": "JiraPriorityField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraProject",
                                                    "kind": "LinkedField",
                                                    "name": "project",
                                                    "plural": false,
                                                    "selections": [
                                                      (v10/*: any*/),
                                                      (v14/*: any*/),
                                                      {
                                                        "concreteType": "JiraAvatar",
                                                        "kind": "LinkedField",
                                                        "name": "avatar",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "large"
                                                          }
                                                        ]
                                                      },
                                                      (v9/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraProjectField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraResolution",
                                                    "kind": "LinkedField",
                                                    "name": "resolution",
                                                    "plural": false,
                                                    "selections": (v11/*: any*/)
                                                  }
                                                ],
                                                "type": "JiraResolutionField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraSprintConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedSprintsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraSprintEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraSprint",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v9/*: any*/),
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "sprintId"
                                                              },
                                                              (v10/*: any*/),
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "state"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "endDate"
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  (v10/*: any*/),
                                                  (v13/*: any*/)
                                                ],
                                                "type": "JiraSprintField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                      (v7/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "accountId"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "picture"
                                                      },
                                                      (v10/*: any*/),
                                                      (v9/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "accountStatus"
                                                      }
                                                    ]
                                                  },
                                                  (v10/*: any*/),
                                                  (v12/*: any*/),
                                                  (v13/*: any*/)
                                                ],
                                                "type": "JiraSingleSelectUserPickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v10/*: any*/),
                                                  {
                                                    "concreteType": "JiraVersionConnection",
                                                    "kind": "LinkedField",
                                                    "name": "selectedVersionsConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraVersionEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraVersion",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": (v11/*: any*/)
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraMultipleVersionPickerField"
                                              },
                                              {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": false,
                                                "selections": (v22/*: any*/)
                                              },
                                              {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": true,
                                                "selections": (v22/*: any*/)
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraServiceManagementRequestType",
                                                    "kind": "LinkedField",
                                                    "name": "requestType",
                                                    "plural": false,
                                                    "selections": [
                                                      (v10/*: any*/),
                                                      (v21/*: any*/),
                                                      (v9/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraServiceManagementRequestTypeField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "args": (v18/*: any*/),
                                                    "concreteType": "JiraIssueLinkConnection",
                                                    "kind": "LinkedField",
                                                    "name": "issueLinkConnection",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "PageInfo",
                                                        "kind": "LinkedField",
                                                        "name": "pageInfo",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "hasNextPage"
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        "concreteType": "JiraIssueLinkEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraIssueLink",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraIssue",
                                                                "kind": "LinkedField",
                                                                "name": "issue",
                                                                "plural": false,
                                                                "selections": [
                                                                  {
                                                                    "kind": "ScalarField",
                                                                    "name": "webUrl"
                                                                  },
                                                                  (v14/*: any*/),
                                                                  {
                                                                    "args": [
                                                                      {
                                                                        "kind": "Literal",
                                                                        "name": "ids",
                                                                        "value": [
                                                                          "statusCategory"
                                                                        ]
                                                                      }
                                                                    ],
                                                                    "concreteType": "JiraIssueFieldConnection",
                                                                    "kind": "LinkedField",
                                                                    "name": "fieldsById",
                                                                    "plural": false,
                                                                    "selections": [
                                                                      {
                                                                        "concreteType": "JiraIssueFieldEdge",
                                                                        "kind": "LinkedField",
                                                                        "name": "edges",
                                                                        "plural": true,
                                                                        "selections": [
                                                                          {
                                                                            "kind": "LinkedField",
                                                                            "name": "node",
                                                                            "plural": false,
                                                                            "selections": [
                                                                              (v7/*: any*/),
                                                                              {
                                                                                "kind": "InlineFragment",
                                                                                "selections": [
                                                                                  (v17/*: any*/)
                                                                                ],
                                                                                "type": "JiraStatusCategoryField"
                                                                              },
                                                                              (v9/*: any*/)
                                                                            ]
                                                                          }
                                                                        ]
                                                                      }
                                                                    ],
                                                                    "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                                                  },
                                                                  (v9/*: any*/)
                                                                ]
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "relationName"
                                                              },
                                                              (v9/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ],
                                                    "storageKey": "issueLinkConnection(first:1000)"
                                                  }
                                                ],
                                                "type": "JiraIssueLinkField"
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v9/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ae8845a2a014fa5b18b0dbb0fce3ac70b8726b42ad2762b25cdfd50b9215724e",
    "metadata": {},
    "name": "refetchIssue_IssueNavigatorQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisdensityenabledrelayprovider": isDensityFullFFEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcstatusinlineeditrelayprovider": isSpreadsheetUIEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcparentfromissueviewrelayprovider": isIssueViewParentComponentEnabled_provider
    }
  }
};
})();

(node as any).hash = "a1923415f412cb59e7b5d21b9573d30f";

export default node;
