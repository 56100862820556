import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	nextIssueTooltip: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.next-issue-tooltip',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: "Next issue ''{issueKey}'' (Type 'j')",
		description:
			'Tooltip shown on the button to select the next issue followed by the keyboard shortcut to perform the action.',
	},
	nextIssueWithoutKeyTooltip: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.next-issue-without-key-tooltip',
		defaultMessage: "Next issue (Type 'j')",
		description:
			'Tooltip shown on the button to select the next issue followed by the keyboard shortcut to perform the action.',
	},
	disabledButtonTooltip: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.next-button.disabled-button-tooltip',
		defaultMessage: 'We can’t load more pages. Refine your search criteria to see more issues.',
		description: 'Tooltip shown when last page is reached and the "Next page" button is disabled',
	},
});

export default messages;
