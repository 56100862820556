import { defineMessages } from '@atlassian/jira-intl'; // TODO - Port i18n string translations to new module ID keys
/* eslint-disable jira/i18n/id-named-by-package */

export default defineMessages({
	tooltipAsc: {
		id: 'issue-navigator.card-container.sort-button.tooltip-asc',
		defaultMessage: 'Sort ascending',
		description: '',
	},
	tooltipDesc: {
		id: 'issue-navigator.card-container.sort-button.tooltip-desc',
		defaultMessage: 'Sort descending',
		description: '',
	},
});
