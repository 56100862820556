import { useEffect } from 'react';
import type { ExperienceAttributes } from '@atlassian/jira-experience-tracker';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous';
import type { IssueNavigatorViewId } from '../../common/types';
import { useExperienceAnalytics } from '../../services/experience-analytics';
import { useIssueSearchQuery } from '../../services/issue-search-query';
import { useSelectedViewState } from '../selected-view-state';

type BaseExperienceProps = {
	/**
	 * Analytics attributes to be emitted as part of success/fail experience events.
	 */
	attributes?: ExperienceAttributes;
	/**
	 * Event emitted when page data has loaded and the key experience is interactive.
	 */
	onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
};

/**
 * Component to declaratively mark the issue search as successful when rendering search results to the user.
 */
export const IssueSearchSuccess = ({ attributes, onPageDataLoad }: BaseExperienceProps) => {
	const [{ view }] = useSelectedViewState();
	const { onIssueSearchSuccess } = useExperienceAnalytics(onPageDataLoad, view, attributes);
	const { isFetching, isNetworkError } = useIssueSearchQuery();
	const prevIsFetching = usePrevious(isFetching);

	useEffect(() => {
		if (prevIsFetching !== isFetching && !isFetching && !isNetworkError) {
			onIssueSearchSuccess();
		}
	}, [isFetching, isNetworkError, onIssueSearchSuccess, prevIsFetching]);

	return null;
};

type FailExperienceProps = BaseExperienceProps & {
	/**
	 * Location the failure originated from.
	 */
	location: string;
	/**
	 * Error associated with the failure.
	 */
	error: Error;
};

/**
 * Component to declaratively mark the issue search as failed when rendering the corresponding error UI to the user.
 */
export const IssueSearchFail = ({
	attributes,
	location,
	error,
	onPageDataLoad,
}: FailExperienceProps) => {
	const [{ view }] = useSelectedViewState();
	const { onIssueSearchFail } = useExperienceAnalytics(onPageDataLoad, view, attributes);
	const { isFetching } = useIssueSearchQuery();
	const prevIsFetching = usePrevious(isFetching);

	useEffect(() => {
		if (prevIsFetching !== isFetching && !isFetching) {
			onIssueSearchFail(location, error);
		}
	}, [error, isFetching, location, onIssueSearchFail, prevIsFetching]);

	return null;
};
