import { createStore, createContainer, createHook, type Action } from 'react-sweet-state';

export type State = {
	/**
	 * Flag to determine whether the user has clicked on the AI button and is in natural language mode
	 */
	isNaturalLanguageMode: boolean;
};

export type Actions = {
	/**
	 * Event emitted when the user clicks the AI button to either open/close natural language mode
	 */
	toggleNaturalLanguageMode: () => Action<State>;
};

const initialState: State = {
	isNaturalLanguageMode: false,
};

export const actions: Actions = {
	toggleNaturalLanguageMode:
		() =>
		({ setState, getState }) => {
			setState({ isNaturalLanguageMode: !getState().isNaturalLanguageMode });
		},
};

const Store = createStore<State, Actions>({ initialState, actions });

export const Container = createContainer<State, Actions>(Store, {
	onInit:
		() =>
		({ setState }) => {
			setState({
				isNaturalLanguageMode: false,
			});
		},
});

const getNaturalLanguageMode = (state: State): boolean => state.isNaturalLanguageMode;

export const useNaturalLanguageMode = createHook(Store, {
	selector: getNaturalLanguageMode,
});
