import React from 'react';
import { Box, media, xcss, type Breakpoint } from '@atlaskit/primitives';
import PageHeader from '@atlassian/jira-common-components-page-header';
import { ff } from '@atlassian/jira-feature-flagging';
import { PAGE_TOP_BAR_ACTIONS_TEST_ID, PAGE_TOP_BAR_TEST_ID } from '../constants';

// Target state: PageHeaderProps to be replaced with Props below when 'sea-2744-jsw-tabs-nav' is cleaned up
type PageHeaderProps = React.ComponentProps<typeof PageHeader> & {
	wrappingBreakpoint?: Breakpoint;
	// When below the `sm` breakpoint (~786px), the actions (right side) will wrap above the bottom bar (left side). Only applies when 'sea-2744-jsw-tabs-nav' feature flag is enabled.
	wrapAtMd?: boolean;
};

type Props = Pick<
	PageHeaderProps,
	// The content to go on the left side of the PageTopBar
	| 'bottomBar'
	// The content to go on the right side of the PageTopBar, usually a ButtonGroup
	| 'actions'
	| 'wrapAtMd'
>;

const SporkPageTopBar = ({ bottomBar, actions, wrapAtMd = false }: Props) => (
	<Box testId={PAGE_TOP_BAR_TEST_ID} xcss={[wrapperStyles, wrapAtMd ? wrapAtMdStyle : noWrapStyle]}>
		{bottomBar}
		{actions && (
			<Box
				testId={PAGE_TOP_BAR_ACTIONS_TEST_ID}
				xcss={[actionsBoxStyles, wrapAtMd && actionsBoxAtMdStyles]}
			>
				{actions}
			</Box>
		)}
	</Box>
);

export const PageTopBar = (props: PageHeaderProps) =>
	ff('sea-2744-jsw-tabs-nav') ? (
		<SporkPageTopBar
			bottomBar={props.bottomBar}
			actions={props.actions}
			wrapAtMd={props.wrapAtMd}
		/>
	) : (
		<PageHeader {...props} />
	);

const noWrapStyle = xcss({
	flexWrap: 'nowrap',
});

const wrapAtMdStyle = xcss({
	flexWrap: 'wrap-reverse',
	[media.above.md]: {
		flexWrap: 'nowrap',
	},
});

const wrapperStyles = xcss({
	display: 'flex',
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	justifyContent: 'space-between',
	gap: 'space.100',
	[media.above.md]: {
		gap: 'space.300',
	},
});
const actionsBoxAtMdStyles = xcss({
	flex: '1 1 auto',
	[media.above.md]: {
		flex: '0 1 auto',
	},
});
const actionsBoxStyles = xcss({
	marginLeft: 'auto',
	display: 'flex',
	justifyContent: 'flex-end',
	flex: '0 0 auto',
});
