import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useColumnPickerOpenState } from '@atlassian/jira-issue-table-column-picker';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { NoColumnsProps } from '../../../types';
import messages from './messages';

export const NoColumns = ({ defaultNoColumnsGlyph, hasColumnConfiguration }: NoColumnsProps) => {
	const { formatMessage } = useIntl();

	const [{ isOpen }, actions] = useColumnPickerOpenState();

	const onOpenColumnPicker = useCallback(
		(event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'button clicked', 'noColumnsButton');
			if (!isOpen) {
				actions.open();
			}
		},
		[isOpen, actions],
	);

	return (
		<Box paddingBlock="space.800">
			<EmptyState
				header={formatMessage(messages.noColumnsHeaderText)}
				description={formatMessage(messages.noColumnsDescriptionText)}
				testId="native-issue-table.common.ui.components.no-columns.no-columns"
				primaryAction={
					hasColumnConfiguration ? (
						<Button appearance="primary" onClick={onOpenColumnPicker}>
							{formatMessage(messages.configureColumns)}
						</Button>
					) : undefined
				}
				renderImage={() => defaultNoColumnsGlyph}
			/>
		</Box>
	);
};
