import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { ModalBoundary } from '@atlassian/jira-modal';
import type AttachFileDialog from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncAttachFileModal = lazy<typeof AttachFileDialog>(
	() => import(/* webpackChunkName: "async-issue-operations-attach-file-modal" */ './index'),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<ModalBoundary id="attach-file-dialog" packageName="native-issue-table">
		<AsyncAttachFileModal />
	</ModalBoundary>
);
