import type { ComponentType, ReactNode } from 'react';
import { CLOSING_TAG_DELIMITER } from './constants';

type ComponentsMapping = {
	[key: string]: ComponentType<{
		children: ReactNode;
	}>;
};

export const getTags = (componentsMapping: ComponentsMapping) =>
	Object.entries(componentsMapping).reduce(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(acc, [key, value]: [any, any]) => {
			const open = `{${key}Start}`;
			const close = `{${key}End}`;

			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
			acc.tags[open] = {
				close,
				component: value,
			};

			// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
			acc.openTags.push(open);
			// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
			acc.closeTags.push(close);

			return acc;
		},
		{ tags: {}, openTags: [], closeTags: [] },
	);

export const isAnyTag = (
	text: string,
	{
		tags,
	}: {
		tags: string[];
	},
) => tags.some((tag) => text.includes(tag));

export const findTag = (
	text: string,
	{
		tags,
	}: {
		tags: string[];
	},
) => tags.find((tag) => text.includes(tag));

export const isCloseTagDelimiter = (letter: string) => letter === CLOSING_TAG_DELIMITER;
