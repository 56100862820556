import { components as columnPicker } from '@atlassian/jira-issue-table-column-picker';
import { Footer } from './footer';
import { NoColumns } from './no-columns';

// Default components that can be overridden and composed by the consumer.
export const components = {
	columnPicker,
	Footer,
	NoColumns,
};
