import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { permissions_issueNavigatorCustomFilters$key } from '@atlassian/jira-relay/src/__generated__/permissions_issueNavigatorCustomFilters.graphql';
import { SectionHeader } from '../../../../common/ui/details-popup/section-header';
import { Section } from '../../../../common/ui/details-popup/styled';
import { Access } from './access';
import { Edit } from './edit';
import messages from './messages';

type Props = {
	filter: permissions_issueNavigatorCustomFilters$key;
	onOpenEditFilterModal: () => void;
};

export const Permissions = ({ filter, onOpenEditFilterModal }: Props) => {
	const { formatMessage } = useIntl();

	const filterData = useFragment(
		graphql`
			fragment permissions_issueNavigatorCustomFilters on JiraCustomFilter {
				...access_issueNavigatorCustomFilters
				...edit_issueNavigatorCustomFilters
				isEditable
			}
		`,
		filter,
	);

	const onOpenEditPermissionsModal = useCallback(
		(_: unknown, analyticsEvent: UIAnalyticsEvent) => {
			onOpenEditFilterModal();
			fireUIAnalytics(analyticsEvent, 'editFilterPermissions');
		},
		[onOpenEditFilterModal],
	);

	return (
		<Section>
			<SectionHeader
				actions={
					filterData.isEditable === true ? (
						<Button
							onClick={onOpenEditPermissionsModal}
							appearance="link"
							spacing="none"
							testId="issue-navigator-custom-filters.ui.details-popup.popup-content.permissions.edit-permissions-button"
						>
							{formatMessage(messages.editPermissions)}
						</Button>
					) : null
				}
			>
				{formatMessage(messages.permissions)}
			</SectionHeader>
			<Access filter={filterData} />
			<Edit filter={filterData} />
		</Section>
	);
};
