import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fontSize } from '@atlassian/jira-common-styles/src';
import BaseFavouriteButton from '@atlassian/jira-favourite-button';
import { FILTERS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider';

type Props = {
	isFavouriteInitial: boolean;
	filterId: string;
	favouriteItemName?: string | undefined;
};

export const FavouriteButton = ({ filterId, isFavouriteInitial, favouriteItemName }: Props) => (
	<BaseFavouriteButton
		key={filterId}
		baseUrl=""
		itemType={FILTERS_ITEM_TYPE}
		itemId={filterId}
		isFavouriteInitial={isFavouriteInitial}
		favouriteItemName={favouriteItemName}
		isShownInList
	/>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FavouriteButtonHeaderWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	height: '32px',
	margin: `${token('space.0', '0')} ${token('space.050', '4px')}`,
});
