import React, { createContext, type ReactNode, useContext, useMemo } from 'react';
import { stringify } from 'query-string';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common';

type ActiveJqlContextProps = {
	jql: string | undefined;
	filterId?: string | undefined;
	isFilterEditable?: boolean | undefined;
	filterJql?: string;
};

type ActiveJqlProviderProps = {
	jql: string | undefined;
	filterJql?: string;
	filterId: string | undefined;
	isFilterEditable: boolean | undefined;
	children: ReactNode;
};

const ActiveJqlContext = createContext<ActiveJqlContextProps>({
	jql: '',
});
ActiveJqlContext.displayName = 'ActiveJqlContext';

export const useActiveJql = () => useContext(ActiveJqlContext);

export const ActiveJqlProvider = ({
	jql,
	filterId,
	isFilterEditable,
	filterJql,
	children,
}: ActiveJqlProviderProps) => {
	const contextValue: ActiveJqlContextProps = useMemo(
		() => ({
			jql,
			filterId,
			isFilterEditable,
			filterJql,
		}),
		[jql, filterId, isFilterEditable, filterJql],
	);

	return <ActiveJqlContext.Provider value={contextValue}>{children}</ActiveJqlContext.Provider>;
};

/**
 * Returns the **resolved** JQL used for the current search, even if a filter was
 * used to perform the search and `useJql` would return `''`. The JQL passed to
 * the IssueNavigator component takes precedence because it is less likely to be
 * out of date.
 * We return empty jql as error fallback, because we have multiple render cycles where jql and filterJql are undefined
 * ,see ticket https://jdog.jira-dev.com/browse/EM-5632
 */
export const useResolvedJql = () => {
	const { jql, filterJql } = useActiveJql();
	const filter = filterJql !== undefined ? filterJql : '';
	return jql !== undefined ? jql : filter;
};

/**
 * Used by components that provide a link redirecting the user to GIN. This should not be needed once GIN is replaced
 * with NIN/
 */
export const useGlobalIssueNavigatorUrl = () => {
	const { filterId, jql } = useActiveJql();
	return `/issues/?${stringify({
		jql,
		...(isFilterId(filterId) ? { filter: filterId } : {}),
	})}`;
};
