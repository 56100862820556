import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fontSize } from '@atlassian/jira-common-styles/src';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DetailsButtonHeaderWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	fontWeight: 'normal',
	margin: `0 ${token('space.050', '4px')}`,
	marginTop: token('space.025', '2px'),
});
