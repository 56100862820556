import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	columnGroupHeader: {
		/* eslint-disable-next-line jira/i18n/id-named-by-package */
		id: 'issue-table-column-picker.common.column-group-header',
		defaultMessage: 'All columns',
		description: 'Select group header for all unselected field type options',
	},
	deprecatedParentyLinkMessage: {
		id: 'issue-navigator.services.fetch-column-picker-options.deprecated-parenty-link-message',
		defaultMessage:
			'<strong>{field}</strong> is replaced by <strong>Parent</strong>. Use the <strong>Parent</strong> field instead.',
		description: 'Info message to warn of upcoming deprecation of Epic Link',
	},
});
