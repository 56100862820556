// eslint-disable-next-line jira/restricted/react-component-props
import React, { memo, type ComponentProps } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import {
	categoryIdForStatusCategory,
	DONE,
} from '@atlassian/jira-common-constants/src/status-categories';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueKey } from '@atlassian/jira-shared-types';
import messages from './messages';

type IssueType = {
	name?: string | null;
	iconSrc?: string | null;
};

type Props = {
	issueKey: IssueKey;
	isSelected?: boolean;
	issueType: IssueType;
	statusCategoryId?: number;
	avatarSrc: string;
	avatarDisplayName: string;
};

const Footer = ({
	issueKey,
	isSelected,
	statusCategoryId,
	avatarSrc,
	avatarDisplayName,
	issueType,
}: Props) => {
	const { formatMessage } = useIntl();
	const isDone = statusCategoryId === categoryIdForStatusCategory(DONE);

	return (
		<StyledContainer>
			{/* @ts-expect-error types should not be null */}
			<TypeIcon src={issueType?.iconSrc} alt={issueType?.name} />
			<KeyText
				aria-label={
					isDone ? formatMessage(messages.screenReaderFooterKeyDone, { issueKey }) : undefined
				}
				isSelected={isSelected}
				isDone={isDone}
			>
				{issueKey}
			</KeyText>
			<AvatarStyledContainer>
				<Tooltip content={avatarDisplayName}>
					<Avatar src={avatarSrc} name={avatarDisplayName} size="small" />
				</Tooltip>
			</AvatarStyledContainer>
		</StyledContainer>
	);
};

export default memo<JSX.LibraryManagedAttributes<typeof Footer, ComponentProps<typeof Footer>>>(
	Footer,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TypeIcon = styled.img<{ src: string | null; alt: string | null }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	marginRight: token('space.050', '4px'),
	borderRadius: '2px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarStyledContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3.5}px`,
	paddingLeft: token('space.100', '8px'),
	marginLeft: 'auto',
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KeyText = styled.div({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.bold'),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props: any) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		props.isSelected ? token('color.text.selected', colors.B400) : token('color.text', colors.N200),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textDecoration: (props: any) => (props.isDone ? 'line-through' : 'none'),
});
