import { useEffect, useState, type RefObject } from 'react';

export type Size = {
	height: number;
	width: number;
	scrollHeight: number;
	scrollWidth: number;
};

export const DEFAULT_SIZE: Size = {
	height: 0,
	width: 0,
	scrollHeight: 0,
	scrollWidth: 0,
};

export const useElementSize = (elementRef: RefObject<HTMLElement>) => {
	const [size, setSize] = useState(DEFAULT_SIZE);

	useEffect(() => {
		const element = elementRef.current;

		if (element) {
			const resizeObserver = new ResizeObserver(() => {
				setSize({
					height: element.clientHeight,
					width: element.clientWidth,
					scrollHeight: element.scrollHeight,
					scrollWidth: element.scrollWidth,
				});
			});

			resizeObserver.observe(element);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, [elementRef]);

	return size;
};
