import React, { type ReactNode } from 'react';
import { SpotlightManager, SpotlightTransition, SpotlightTarget } from '@atlaskit/onboarding';
import { useSpotlightCoordinationActions, type SpotlightCardKey } from '../../controllers';

type SpotlightCardFnArgs = {
	/**
	 * Dismiss the current spotlight tour and notify the coordination client that the message has stopped (if a `stop`
	 * function was provided when starting the tour).
	 */
	onEndTour: () => void;

	/**
	 * Set the cardKey into state to identify which spotlight card should be rendered in the UI. When a new spotlight
	 * tour is started a `stop` function should also be provided from the `CoordinationClient`.
	 * See playbook/coordination-service.md for more details.
	 *
	 * @param cardKey Key of the spotlight tour card to show.
	 * @param stop `CoordinationClient` stop function, to notify the client that a tour has completed/finished.
	 */
	onSetActiveCard: (cardKey: SpotlightCardKey, stop?: () => void) => void;
};

export type Props = {
	/**
	 * Target children to render.
	 */
	children: ReactNode;

	/**
	 * Name of the spotlight target.
	 */
	name: string;

	/**
	 * React node or render function of the spotlight card to render. If a function is provided then it will be invoked
	 * with `onEndTour` and `onSetActiveCard` arguments which can be used within the spotlight card actions. For ease of
	 * use the rendered React node should be a `SpotlightTourCard`.
	 */
	renderSpotlightCard: ReactNode | ((args: SpotlightCardFnArgs) => ReactNode);
};

/**
 *
 * Renders a spotlight target and associated spotlight card.
 */
const SpotlightTourTarget = ({ children, name, renderSpotlightCard }: Props) => {
	const { onEndTour, onSetActiveCard } = useSpotlightCoordinationActions();
	return (
		<SpotlightManager>
			<SpotlightTarget name={name}>{children}</SpotlightTarget>
			<SpotlightTransition>
				{typeof renderSpotlightCard === 'function'
					? renderSpotlightCard({ onEndTour, onSetActiveCard })
					: renderSpotlightCard}
			</SpotlightTransition>
		</SpotlightManager>
	);
};

export default SpotlightTourTarget;
