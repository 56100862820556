import React, { useCallback, useLayoutEffect } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import BulletListIcon from '@atlaskit/icon/glyph/bullet-list';
import DetailViewIcon from '@atlaskit/icon/glyph/detail-view';
import { Box, Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts';
import { ff } from '@atlassian/jira-feature-flagging';
import { type IntlShapeV2 as Intl, useIntlV2 as useIntl } from '@atlassian/jira-intl';
import { isCustomFilter } from '@atlassian/jira-issue-navigator-actions-common';
import {
	NinChangeboardingTourTarget,
	SpotlightTourTarget,
	type SpotlightTourTargetProps,
	SpotlightTourCard,
	spotlightCardKeys,
} from '@atlassian/jira-issue-navigator-changeboarding';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types';
import ToggleButtons from '@atlassian/jira-toggle-buttons';
import { views } from '../../common/constants';
import type { View } from '../../common/types';
import {
	convertToIssueNavigatorId,
	convertFilterIdToIssueNavigatorId,
	convertToView,
} from '../../common/utils';
import { markOnce, marks } from '../../common/utils/performance-analytics';
import { useSelectedIssueKey } from '../../controllers/selected-issue-state';
import { useSelectedViewState } from '../../controllers/selected-view-state';
import messages from './messages';

type Props = {
	view: View;
	onToggle: (view: View, isKeyboardShortcut: boolean) => boolean;
	intl: Intl;
};

const KeyboardShortcuts = ({ view, onToggle, intl: { formatMessage } }: Props) => {
	const isDetailView = view === views.detail;
	return (
		<Shortcuts
			keyMap={{
				t: {
					callback: () => {
						const newView = isDetailView ? views.list : views.detail;
						onToggle(newView, true);
					},
					label: <>{formatMessage(isDetailView ? messages.listText : messages.detailText)}</>,
				},
			}}
		/>
	);
};

export const ViewSwitcher = ({ view, onToggle, intl }: Props) => {
	const { formatMessage } = intl;
	const testIdPrefix = 'issue-navigator.ui.refinement-bar.view-switcher.toggle-button.';

	const options = ff('sea-2744-jsw-tabs-nav')
		? [
				{
					id: views.list,
					label: (
						<Text size="UNSAFE_small" weight="semibold">
							{formatMessage(messages.listOptionLabel)}
						</Text>
					),
					testId: `${testIdPrefix}${views.list}`,
				},
				{
					id: views.detail,
					label: (
						<Text size="UNSAFE_small" weight="semibold">
							{formatMessage(messages.detailOptionLabel)}
						</Text>
					),
					testId: `${testIdPrefix}${views.detail}`,
				},
			]
		: [
				{
					id: views.list,
					label: (
						<IconLabel>
							<Box as="span">
								<Text size="UNSAFE_small" weight="semibold">
									{formatMessage(messages.listOptionLabel)}
								</Text>
							</Box>
							<BulletListIcon label="" size="small" />
						</IconLabel>
					),
					testId: `${testIdPrefix}${views.list}`,
				},
				{
					id: views.detail,
					label: (
						<IconLabel>
							<Box as="span">
								<Text size="UNSAFE_small" weight="semibold">
									{formatMessage(messages.detailOptionLabel)}
								</Text>
							</Box>
							{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
							<DetailViewIcon label="" size="small" />
						</IconLabel>
					),
					testId: `${testIdPrefix}${views.detail}`,
				},
			];

	return (
		<>
			<KeyboardShortcuts view={view} onToggle={onToggle} intl={intl} />
			<ToggleButtons
				label={formatMessage(messages.toggleButtonsLabel)}
				options={options}
				selectedOption={view}
				onChange={(newView) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					onToggle(newView as View, false);
				}}
			/>
		</>
	);
};

ViewSwitcher.defaultProps = {
	onToggle: noop,
};

export const ViewSwitcherWithState = ({ filterId }: { filterId?: string }) => {
	markOnce(marks.PAGE_ACTIONS_VIEW_SWITCHER_START);
	useLayoutEffect(() => {
		markOnce(marks.PAGE_ACTIONS_VIEW_SWITCHER_END);
	}, []);

	const [selectedIssueKey] = useSelectedIssueKey();

	const [{ view }, { setView }] = useSelectedViewState();
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleOnToggle = useCallback(
		(newView: View, isKeyboardShortcut: boolean) => {
			// When toggling to detail view we want to update the issue key in the URL to match the currently selected
			// issue. When toggling to list view we want to discard the issue key from the URL.
			const newIssueKey = newView === views.detail ? selectedIssueKey : toIssueKey('');

			let resolvedNewView = convertToIssueNavigatorId(newView);

			if (newView === views.list && filterId && isCustomFilter(filterId)) {
				resolvedNewView = convertFilterIdToIssueNavigatorId(filterId);
			}

			setView(resolvedNewView, newIssueKey);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});

			fireUIAnalytics(analyticsEvent, 'switchIssueNavigatorView', {
				keyboardShortcut: isKeyboardShortcut,
				switchToView: newView,
			});

			return true;
		},
		[selectedIssueKey, filterId, setView, createAnalyticsEvent],
	);

	const children = (
		<NinChangeboardingTourTarget engagementId="nin.view-switcher">
			<ViewSwitcher view={convertToView(view)} onToggle={handleOnToggle} intl={intl} />
		</NinChangeboardingTourTarget>
	);

	const renderSpotlightCard: SpotlightTourTargetProps['renderSpotlightCard'] = ({
		onEndTour,
		onSetActiveCard,
	}) => (
		<SpotlightTourCard
			cardKey={spotlightCardKeys.GLOBAL_SCOPE_VIEW_SWITCHER}
			dialogWidth={272}
			dialogPlacement="bottom right"
			actions={
				view === 'detail'
					? [
							{
								onClick: () => {
									onEndTour();
								},
								text: intl.formatMessage(messages.done),
							},
						]
					: [
							{
								onClick: () => {
									onSetActiveCard(spotlightCardKeys.GLOBAL_SCOPE_COLUMN_PICKER);
								},
								text: intl.formatMessage(messages.next),
							},
							{
								appearance: 'subtle',
								onClick: () => {
									onEndTour();
								},
								text: intl.formatMessage(messages.skip),
							},
						]
			}
			heading={intl.formatMessage(messages.tourHeading)}
			target="nin.spotlight.view-switcher"
			targetBgColor={token('elevation.surface', colors.N0)}
			targetRadius={3}
		>
			{intl.formatMessage(messages.tourBody)}
		</SpotlightTourCard>
	);

	return (
		<SpotlightTourTarget
			name="nin.spotlight.view-switcher"
			renderSpotlightCard={renderSpotlightCard}
		>
			{children}
		</SpotlightTourTarget>
	);
};

export default ViewSwitcherWithState;

// clean up this styled element with sea-2744-jsw-tabs-nav
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconLabel = styled.span({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'*': {
		marginRight: token('space.050', '4px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:last-child': {
			marginRight: 0,
		},
	},
});
