/**
 * @generated SignedSource<<33bcb3d331e273f82c777a2a3114ae5c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_ListView_view$data = {
  readonly fieldSets: {
    readonly __id: string;
    readonly totalCount: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_columns">;
  };
  readonly hasDefaultFieldSets: boolean | null | undefined;
  readonly id: string;
  readonly viewId: string;
  readonly " $fragmentType": "main_issueNavigator_ListView_view";
};
export type main_issueNavigator_ListView_view$key = {
  readonly " $data"?: main_issueNavigator_ListView_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcninresizablecolumnsrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_ListView_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "viewId"
      },
      "action": "THROW",
      "path": "viewId"
    },
    {
      "kind": "ScalarField",
      "name": "hasDefaultFieldSets"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "fieldSetSelectedState": "SELECTED"
            }
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "amountOfColumns"
          }
        ],
        "concreteType": "JiraIssueSearchFieldSetConnection",
        "kind": "LinkedField",
        "name": "fieldSets",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "totalCount"
          },
          {
            "args": [
              {
                "kind": "Variable",
                "name": "fetchColumnWidths",
                "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcninresizablecolumnsrelayprovider"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ui_nativeIssueTable_NativeIssueTable_columns"
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__id"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "fieldSets"
    }
  ],
  "type": "JiraIssueSearchView"
};

(node as any).hash = "6ca3f23cd7cf7a69a494b3691fb80460";

export default node;
