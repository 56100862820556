import React, { useCallback } from 'react';
import AppPermissionsCheck from '@atlassian/jira-app-permissions-check';
import { ff } from '@atlassian/jira-feature-flagging';
import IssueNavigator, {
	type Props as IssueNavigatorProps,
	type CustomHeaderProps,
} from '@atlassian/jira-issue-navigator';
import { JsdGettingStartedPanelShouldRender } from '@atlassian/jira-servicedesk-getting-started-panel/src/async';
import type { ProjectKey } from '@atlassian/jira-shared-types';
import { ProjectIssueNavigatorHeaderSkeleton } from '@atlassian/jira-skeletons';
import Header from './ui';
import PageActions from './ui/page-actions';

type InnerProps = {
	CustomHeader: IssueNavigatorProps['CustomHeader'];
	HeaderSkeletonImage: IssueNavigatorProps['HeaderSkeletonImage'];
};

export type Props = Omit<IssueNavigatorProps, keyof InnerProps> & {
	projectKey: ProjectKey;
	showNav3Header?: boolean;
};

const ActionMenu = (customHeaderProps: CustomHeaderProps) => {
	const { jql, filterId, filterDetails, searchResultPageData } = customHeaderProps;
	return (
		<PageActions
			jql={jql}
			filterId={filterId}
			isModifiedFilter={filterDetails?.jql !== jql}
			searchResultPageData={searchResultPageData}
		/>
	);
};

const ProjectIssueNavigator = ({ projectKey, showNav3Header = false, ...rest }: Props) => {
	const CustomHeader = useCallback(
		(customHeaderProps: CustomHeaderProps) => (
			<Header
				projectKey={projectKey}
				{...customHeaderProps}
				isQuickFiltersDisabled={showNav3Header}
			/>
		),
		[projectKey, showNav3Header],
	);

	const showNewNav = ff('sea-2744-jsw-tabs-nav') && !showNav3Header;

	return (
		<>
			<IssueNavigator
				{...(!showNewNav && { CustomHeader })}
				{...(showNewNav && { ActionMenu })}
				HeaderSkeletonImage={ProjectIssueNavigatorHeaderSkeleton}
				isFeedbackButtonDisabled
				{...rest}
			/>
			<AppPermissionsCheck hasServiceDeskAccess>
				<JsdGettingStartedPanelShouldRender />
			</AppPermissionsCheck>
		</>
	);
};

export default ProjectIssueNavigator;
