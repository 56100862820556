import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { NoIssuesProps } from '@atlassian/jira-native-issue-table';
import messages from './messages';

export const NoIssues = ({ defaultNoIssuesGlyph }: NoIssuesProps) => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingBlock="space.800">
			<EmptyState
				header={formatMessage(messages.noIssues)}
				renderImage={() => defaultNoIssuesGlyph}
				testId="issue-navigator.ui.issue-results.list-view.no-issues.empty-state"
			/>
		</Box>
	);
};
