import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Spinner from '@atlaskit/spinner';
import Placeholder from '@atlassian/jira-placeholder';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { viewDetailsPopupExperience } from '../../../experiences';
import { LoadingWrapper, type Props } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPopupContent = lazy(
	() =>
		import(
			/* webpackChunkName: "async-issue-navigator-actions-custom-filters-details-popup-content" */ './index'
		),
);

export const preloadPopupContentComponent = () => LazyPopupContent.preload();

const LoadingFallback = () => (
	<LoadingWrapper>
		<Spinner />
	</LoadingWrapper>
);

export const PopupContent = (props: Props) => {
	useUFOComponentExperience(viewDetailsPopupExperience);
	return (
		<Placeholder name="popup-content" fallback={<LoadingFallback />}>
			<LazyPopupContent {...props} />
		</Placeholder>
	);
};
