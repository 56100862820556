import React, {
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type ComponentType,
	type ReactNode,
	useLayoutEffect,
	memo,
} from 'react';
import {
	graphql,
	type PreloadedQuery,
	type RefetchFn,
	usePreloadedQuery,
	useRefetchableFragment,
	useFragment,
} from 'react-relay';
import { ff } from '@atlassian/jira-feature-flagging';
import type { FilterRefetchQuery } from '@atlassian/jira-relay/src/__generated__/FilterRefetchQuery.graphql';
import IssueNavigatorRefetchQuery, {
	type IssueNavigatorResultsRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/IssueNavigatorResultsRefetchQuery.graphql';
import type { main_FilterQuery as FilterQueryType } from '@atlassian/jira-relay/src/__generated__/main_FilterQuery.graphql';
import type { main_issueNavigator_IssueNavigator_filterQuery$key as FilterFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_filterQuery.graphql';
import type { main_issueNavigator_IssueNavigator_issueQuery$key as ResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_issueQuery.graphql';
import type { main_issueNavigator_IssueNavigator_jira$key as JqlBuilderWithAiKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_jira.graphql';
import type { main_issueNavigator_IssueNavigator_userPreferences$key as UserPreferencesFragmentKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueNavigator_userPreferences.graphql';
import type { main_IssueNavigatorQuery as IssueNavigatorQueryType } from '@atlassian/jira-relay/src/__generated__/main_IssueNavigatorQuery.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types';
import { FilterTypes, SearchInputTypes } from './common/constants';
import type {
	CustomHeaderProps,
	FilterId,
	FilterJql,
	SearchInputType,
	IssueNavigatorViewId,
	OverridableIssueTableProps,
} from './common/types';
import { markOnce, marks } from './common/utils/performance-analytics';
import { AppContextualAnalytics } from './controllers/app-contextual-analytics';
import { SelectedIssueContainer } from './controllers/selected-issue-state';
import { ActiveJqlProvider } from './services/active-jql';
import { FilterQueryProvider } from './services/filter-query';
import { IssueSearchQueryProvider } from './services/issue-search-query';
import IssueNavigatorUI from './ui';
import type { OverridableJqlBuilderProps } from './ui/jql-builder';
import { SelectedViewContainer } from './ui/selected-view';

export type Props = {
	issueKey: IssueKey;
	CustomHeader?: ComponentType<CustomHeaderProps>;
	ActionMenu?: ComponentType<CustomHeaderProps>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HeaderSkeletonImage: ComponentType<Record<any, any>>;
	defaultJql: string;
	searchInput: SearchInputType;
	queryReference: PreloadedQuery<IssueNavigatorQueryType>;
	filterQueryReference: PreloadedQuery<FilterQueryType>;
	onChangeIssue: JSX.LibraryManagedAttributes<
		typeof IssueNavigatorUI,
		ComponentProps<typeof IssueNavigatorUI>
	>['onChangeIssue'];
	onChangePage?: () => void;
	onChangeFilter?: (filterId?: string) => void;
	onRefinement?: () => void;
	/**
	 * Event emitted when the page has loaded and the key experience is interactive. There are several different user
	 * landing states this encompasses:
	 * - Detail view is rendered with issues loaded
	 * - List view is rendered with issues loaded
	 * - Full page issue app pagination controls are rendered (i.e. list view with an issue selected)
	 */
	onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
	onChangeColumnConfiguration?: () => void;
	onChangeJql?: JSX.LibraryManagedAttributes<
		typeof IssueNavigatorUI,
		ComponentProps<typeof IssueNavigatorUI>
	>['onChangeJql'];
	onSetView: (view: IssueNavigatorViewId, issueKey?: IssueKey) => void;
	jqlBuilderProps?: OverridableJqlBuilderProps;
	/**
	 * Removes give feedback button from footer
	 */
	isFeedbackButtonDisabled?: boolean;
	/**
	 * This overrides a subset of issue table props when rendering the issue table in NIN.
	 */
	issueTableProps?: OverridableIssueTableProps;
};

export const FilterQuery = graphql`
	query main_FilterQuery($filterAri: ID!, $includeFilter: Boolean!) {
		...main_issueNavigator_IssueNavigator_filterQuery
	}
`;

type FilterAndJqlProviderProps = {
	children: ReactNode;
	jql: string | undefined;
	refetch: RefetchFn<FilterRefetchQuery>;
	filterId: string | undefined;
	isFilterEditable: boolean | undefined;
	filterJql?: string;
};

// We memo as an optimisation to reduce forced re-renders when the immediate parent renders. In this case it's
// AppContextualAnalytics which will re-render when interacting with the JQL builder, e.g. opening picker.
const FilterAndJqlProvider = memo(
	({
		children,
		jql,
		refetch,
		filterId,
		isFilterEditable,
		filterJql,
	}: FilterAndJqlProviderProps) => (
		<FilterQueryProvider query={FilterQuery} refetch={refetch} filterId={filterId}>
			<ActiveJqlProvider
				filterId={filterId}
				filterJql={filterJql}
				jql={jql}
				isFilterEditable={isFilterEditable}
			>
				{children}
			</ActiveJqlProvider>
		</FilterQueryProvider>
	),
);

const getFilter = (searchParam: SearchInputType): FilterJql | FilterId | undefined => {
	if (searchParam?.type === SearchInputTypes.JQL) {
		return undefined;
	}
	return searchParam.type === SearchInputTypes.FILTER_AND_JQL ? searchParam.filter : searchParam;
};

const IssueNavigator = ({
	issueKey,
	queryReference,
	filterQueryReference,
	CustomHeader,
	ActionMenu,
	HeaderSkeletonImage,
	onChangeIssue,
	onChangeFilter,
	onRefinement,
	onChangePage,
	onPageDataLoad,
	onChangeColumnConfiguration,
	onChangeJql,
	onSetView,
	defaultJql,
	searchInput,
	jqlBuilderProps,
	isFeedbackButtonDisabled,
	issueTableProps,
}: Props) => {
	markOnce(marks.ISSUE_NAVIGATOR_START);
	useLayoutEffect(() => {
		markOnce(marks.ISSUE_NAVIGATOR_END);
	}, []);

	const filterInput = searchInput ? getFilter(searchInput) : undefined;

	const issueQuery = usePreloadedQuery<IssueNavigatorQueryType>(
		graphql`
			query main_IssueNavigatorQuery(
				$cloudId: ID!
				$issueSearchInput: JiraIssueSearchInput!
				$first: Int
				$last: Int
				$before: String
				$after: String
				$namespace: String
				$viewId: String
				$options: JiraIssueSearchOptions
				$filterId: String
				$fieldSetIds: [String!]!
				$shouldQueryFieldSetsById: Boolean!
				$amountOfColumns: Int!
				$atlassianIntelligenceProductFeature: JiraAtlassianIntelligenceFeatureEnum!
			) {
				...main_issueNavigator_IssueNavigator_issueQuery
				jira {
					userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
						...main_issueNavigator_IssueNavigator_userPreferences
					}
					...main_issueNavigator_IssueNavigator_jira
				}
			}
		`,
		queryReference,
	);

	/* eslint-disable @atlassian/relay/unused-fields */
	const [resultsData, refetchIssues] = useRefetchableFragment<
		IssueNavigatorResultsRefetchQuery,
		ResultsFragment
	>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_issueQuery on Query
			@refetchable(queryName: "IssueNavigatorResultsRefetchQuery")
			@argumentDefinitions(
				isIssueSearchViewResultEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/issue-search-view-result.relayprovider"
				}
			) {
				jira {
					issueSearchStable(
						cloudId: $cloudId
						issueSearchInput: $issueSearchInput
						first: $first
						last: $last
						before: $before
						after: $after
						options: $options
					) {
						edges {
							node {
								key
							}
						}
						...issueSearchQuery_issueNavigator_IssueSearchQueryProvider_issueResults
						...selectedIssueState_issueNavigator_SelectedIssueContainer
						...main_issueNavigator_IssueNavigatorUI_issueResults
					}
					issueSearchViewByNamespaceAndViewId(
						cloudId: $cloudId
						namespace: $namespace
						viewId: $viewId
						filterId: $filterId
					) @skip(if: $isIssueSearchViewResultEnabled) {
						...issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view
						...selectedView_issueNavigator_SelectedViewContainer
						...main_issueNavigator_IssueNavigatorUI_view
					}
					issueSearchViewResult(
						cloudId: $cloudId
						namespace: $namespace
						viewId: $viewId
						filterId: $filterId
					) @include(if: $isIssueSearchViewResultEnabled) @optIn(to: "JiraIssueSearch") {
						... on JiraIssueSearchView {
							__typename
							...issueSearchQuery_issueNavigator_IssueSearchQueryProvider_view
							...selectedView_issueNavigator_SelectedViewContainer
						}
						...main_issueNavigator_IssueNavigatorUI_viewResult
					}
				}
			}
		`,
		issueQuery,
	);
	/* eslint-enable @atlassian/relay/unused-fields */

	const userPreferencesData = useFragment<UserPreferencesFragmentKey>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_userPreferences on JiraUserPreferences {
				...main_issueNavigator_IssueNavigatorUI_userPreferences
			}
		`,
		issueQuery.jira?.userPreferences ?? null,
	);

	const jqlBuilderWithAiData = useFragment<JqlBuilderWithAiKey>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_jira on JiraQuery {
				...main_issueNavigator_IssueNavigatorUI_jqlBuilderWithAiKey
			}
		`,
		// @ts-expect-error - No overload matches this call.
		issueQuery.jira,
	);

	const issueResultsData = resultsData.jira?.issueSearchStable ?? null;
	const viewData = resultsData.jira?.issueSearchViewByNamespaceAndViewId ?? null;
	const viewResultData = resultsData.jira?.issueSearchViewResult ?? null;

	const filterQuery = usePreloadedQuery<FilterQueryType>(FilterQuery, filterQueryReference);
	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const [filterData, refetchFilter] = useRefetchableFragment<FilterRefetchQuery, FilterFragment>(
		graphql`
			fragment main_issueNavigator_IssueNavigator_filterQuery on Query
			@refetchable(queryName: "FilterRefetchQuery") {
				jira {
					filter(id: $filterAri) @include(if: $includeFilter) {
						... on JiraFilter {
							jql
							... on JiraCustomFilter {
								isEditable
							}
							...main_issueNavigator_Header_filter
							...topBar_issueNavigator_filter
							...jqlBuilder_issueNavigator_JQLBuilderWrapper_filter
							...main_issueNavigator_ListView_filter
						}
					}
				}
			}
		`,
		filterQuery,
	);

	/*
	 * IssueSearchQueryProvider and SelectedViewContainer expect viewData as a JiraIssueSearchView | null type.
	 * When viewResultData is a QueryError, we null it so it receives the expected type.
	 */
	let issueSearchViewData = null;
	if (ff('handle-404-filter-errors-gracefully-in-the-ui-by-showing-filter-not-found-view_t3d2m')) {
		issueSearchViewData =
			viewResultData?.__typename === 'JiraIssueSearchView' ? viewResultData : null;
	} else {
		issueSearchViewData = viewData;
	}
	/* eslint-enable @atlassian/relay/must-colocate-fragment-spreads */
	return (
		<AppContextualAnalytics>
			<FilterAndJqlProvider
				refetch={refetchFilter}
				filterId={filterInput?.type === FilterTypes.ID ? filterInput?.value : undefined}
				filterJql={
					filterInput?.type === FilterTypes.JQL ? filterInput?.value : filterData?.jira?.filter?.jql
				}
				isFilterEditable={
					filterData.jira?.filter?.isEditable !== null
						? filterData.jira?.filter?.isEditable
						: undefined
				}
				jql={
					searchInput?.type === SearchInputTypes.FILTER_AND_JQL ||
					searchInput?.type === SearchInputTypes.JQL
						? searchInput?.jql
						: undefined
				}
			>
				<IssueSearchQueryProvider
					refetch={refetchIssues}
					issueResults={issueResultsData}
					view={issueSearchViewData}
					query={IssueNavigatorRefetchQuery}
					onPageDataLoad={onPageDataLoad}
					onChangePage={onChangePage}
				>
					<SelectedViewContainer onSetView={onSetView} fragment={issueSearchViewData}>
						<SelectedIssueContainer
							selectedIssueKey={issueKey}
							onChange={onChangeIssue}
							issueResults={issueResultsData}
						>
							<IssueNavigatorUI
								CustomHeader={CustomHeader}
								ActionMenu={ActionMenu}
								HeaderSkeletonImage={HeaderSkeletonImage}
								filter={filterData?.jira?.filter ?? null}
								onChangeIssue={onChangeIssue}
								onChangeFilter={onChangeFilter}
								onPageDataLoad={onPageDataLoad}
								onChangeColumnConfiguration={onChangeColumnConfiguration}
								onChangeJql={onChangeJql}
								onRefinement={onRefinement}
								defaultJql={defaultJql}
								issueResults={issueResultsData}
								view={viewData}
								viewResult={viewResultData}
								userPreferences={userPreferencesData}
								jqlBuilderProps={jqlBuilderProps}
								jqlBuilderWithAiKey={jqlBuilderWithAiData}
								isFeedbackButtonDisabled={isFeedbackButtonDisabled}
								issueTableProps={issueTableProps}
							/>
						</SelectedIssueContainer>
					</SelectedViewContainer>
				</IssueSearchQueryProvider>
			</FilterAndJqlProvider>
		</AppContextualAnalytics>
	);
};

export default memo<Props>(IssueNavigator);
