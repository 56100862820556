import React, { forwardRef, type MouseEvent, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors, fontSizeSmall } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { CardBaseStyle } from '../../../../../../common/ui/styled';
import messages from './messages';

type Props = {
	isSelected?: boolean;
	onClick: (event: MouseEvent) => void;
};

const ContainerFG = ({ children, isSelected }: { children: ReactNode; isSelected: boolean }) =>
	fg('nin_critical_accessibility_issues') ? (
		<Box
			as="li"
			role="option"
			aria-selected={isSelected}
			xcss={ListItemStyle}
			tabIndex={isSelected ? 0 : -1}
		>
			{children}
		</Box>
	) : (
		<>{children}</>
	);

const EmptyCard = forwardRef<HTMLAnchorElement, Props>(
	({ isSelected = false, onClick }: Props, ref) => {
		const { formatMessage } = useIntl();

		return (
			<ContainerFG isSelected={isSelected}>
				<EmptyCardContainer
					ref={ref ?? undefined}
					isSelected={isSelected}
					onClick={onClick}
					href="#"
				>
					{formatMessage(messages.emptyCardText)}
					<FireUiAnalytics action="viewed" actionSubject="blankIssue" />
				</EmptyCardContainer>
			</ContainerFG>
		);
	},
);

export default EmptyCard;

const textColorActiveState = token('color.text.selected', colors.N600);
const textColor = token('color.text', colors.N200);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyCardContainer = styled(CardBaseStyle)<{ isSelected: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => (props.isSelected ? textColorActiveState : textColor),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSizeSmall()}px`,
	cursor: 'pointer',
	'&:hover, &:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props) => (props.isSelected ? textColorActiveState : textColor),
	},
});

const ListItemStyle = xcss({
	marginBlockStart: 'space.0',
});
