import type { ProjectType, ProjectStyle } from '@atlassian/jira-common-constants/src/project-types';
import type { RouteResourceError } from '@atlassian/jira-router';
import type { BoardType, ProjectId } from '@atlassian/jira-shared-types';

export type Scope = {
	id: number;
	type: BoardType | undefined;
	name: string | undefined;
};

export type ProjectContext = {
	contextType: 'project';
	project: {
		key: string;
		id: ProjectId;
		name: string;
		type: ProjectType;
		isSimplified: boolean;
		avatarUrl: string;
		style: ProjectStyle;
	};
	scope?: Scope;
	portalId?: number;
};

export type UserContext = {
	contextType: 'user';
	user: {
		id: number;
		name: string;
		avatarUrl: string | undefined;
		profileUrl: string;
		accountId: string;
	};
	scope: Scope;
};

export type ContainerContext = ProjectContext | UserContext;
// Use for type refinement of ContainerContext
export const isProjectContext = (
	containerContext: ContainerContext, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
): containerContext is ProjectContext => (containerContext as ProjectContext).project !== undefined;
export const isUserContext = (
	containerContext: ContainerContext, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
): containerContext is UserContext => (containerContext as UserContext).user !== undefined;

export type ContainerContextData = {
	loading: boolean;
	data: ContainerContext | null;
	error: Error | null;
};

export type UseContainerContextReturn = [
	{ data: ContainerContext | null; error: RouteResourceError | null; loading: boolean },
	{ fetch: () => void; refetch: () => void },
];
