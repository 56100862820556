import React, { type ComponentType, type FC } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const UpdateFilterDialog: ComponentType<Props> = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-update-filter-dialog" */ './index'),
	{ ssr: false },
);

export const AsyncUpdateFilterDialog: FC<Props> = (props: Props) => (
	<Placeholder name="async-update-filter-dialog" fallback={<></>}>
		<UpdateFilterDialog {...props} />
	</Placeholder>
);
