import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { IssueCountProps } from '@atlassian/jira-native-issue-table';
import PageText from '../../../../common/ui/page-text';
import RefreshButton from '../../../../common/ui/refresh-button';
import { useIssueSearchQuery } from '../../../../services/issue-search-query';

/**
 * Override default issue table issue count to support a refresh button and fetching the 'real' total issue count.
 */
export const IssueCount = ({
	startIssueRange,
	endIssueRange,
	total,
	countExceedsMaxResults,
}: IssueCountProps) => {
	const { isStalePage } = useIssueSearchQuery();

	return (
		<IssueCountContainer data-testid="issue-navigator.ui.issue-results.list-view.issue-count">
			{/* Do not show issue count while issue data is stale as page information will be inaccurate */}
			{!isStalePage && (
				<span data-testid="issue-navigator.ui.issue-results.list-view.issue-count.text">
					<PageText
						startIssueRange={startIssueRange}
						endIssueRange={endIssueRange}
						total={total}
						countExceedsMaxResults={countExceedsMaxResults}
					/>
				</span>
			)}
			<RefreshButton />
		</IssueCountContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCountContainer = styled.div({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	justifyContent: 'center',
	fontWeight: 600,
	gap: token('space.050', '4px'),
});
