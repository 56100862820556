import React, { type ReactNode } from 'react';
import { styled, css } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { withRecoverableErrorBoundary } from '@atlassian/jira-error-boundaries';
import type { headerActions_issueNavigator$key as HeaderActionsKey } from '@atlassian/jira-relay/src/__generated__/headerActions_issueNavigator.graphql';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useSelectedIssueStateActions } from '../../../../controllers/selected-issue-state';
import IssuePagination from './issue-pagination';

export type Props = {
	/**
	 * Optional react node to render in the header above the issue app.
	 */
	extraHeaderActions?: ReactNode;
	/**
	 * Flag to adjust the container spacing based on whether the component is in an embedded or full page layout.
	 */
	isEmbedView: boolean;
	/**
	 * Relay fragment for issue search data.
	 */
	issueResults: HeaderActionsKey | null;
	/**
	 * Max-width applied to the issue app that should be set on the header actions container to ensure correct alignment.
	 */
	maxWidth?: number;
};

/**
 * Render extraHeaderActions if provided or otherwise return an empty render.
 */
const HeaderActionsFallback = ({ extraHeaderActions, isEmbedView, maxWidth }: Props) =>
	extraHeaderActions ? (
		<HeaderActionsContainer maxWidth={maxWidth} isEmbedView={isEmbedView}>
			{extraHeaderActions}
		</HeaderActionsContainer>
	) : null;

/**
 * Render issue pagination controls and extraHeaderActions if provided.
 */
const HeaderActions = (props: Props) => {
	const { extraHeaderActions, issueResults, maxWidth, isEmbedView } = props;
	const issueResultsData = useFragment<HeaderActionsKey>(
		graphql`
			fragment headerActions_issueNavigator on JiraIssueConnection {
				...issuePagination_issueNavigator
			}
		`,
		issueResults,
	);

	const [, { getSelectedIssuePosition }] = useSelectedIssueStateActions();
	const selectedIssuePosition = getSelectedIssuePosition();

	// If selectedIssuePosition is not defined then our selected issue key does not exist in the result set, so there
	// are no pagination controls to show.
	if (selectedIssuePosition == null || issueResultsData == null) {
		return <HeaderActionsFallback {...props} />;
	}

	return (
		<HeaderActionsContainer maxWidth={maxWidth} isEmbedView={isEmbedView}>
			{extraHeaderActions ?? null}
			<IssuePagination
				issueResults={issueResultsData}
				selectedIssuePosition={selectedIssuePosition}
			/>
		</HeaderActionsContainer>
	);
};

export default withRecoverableErrorBoundary<Props>(HeaderActions, {
	recoveryProp: 'issueResults',
	Fallback: HeaderActionsFallback,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderActionsContainer = styled.div<{
	isEmbedView: boolean;
	maxWidth?: number;
}>(
	{
		alignItems: 'center',
		justifyContent: 'flex-end',
		margin: '0 auto',
		display: 'flex',
		width: '100%',
		boxSizing: 'border-box',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEmbedView }) =>
		isEmbedView
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					paddingBottom: token('space.100', '8px'),
				})
			: undefined,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ maxWidth }) =>
		maxWidth
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					maxWidth: `${maxWidth}px`,
				})
			: undefined,
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> * + *': {
			marginLeft: token('space.100', '8px'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'> :last-child': {
			// Extra right margin is added to ensure our controls align with the issue app's right sidebar
			marginRight: token('space.250', '20px'),
		},
	},
);
