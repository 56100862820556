import { createStore, createHook, createActionsHook } from 'react-sweet-state';
import type { State, Actions, CardKey } from './types';

const initialState: State = {
	cardKey: null,
	onStopCoordination: null,
};

const actions: Actions = {
	onSetActiveCard:
		(cardKey, stop) =>
		({ getState, setState }) => {
			const { onStopCoordination } = getState();
			setState({
				cardKey,
				onStopCoordination: stop ?? onStopCoordination,
			});
		},

	onEndTour:
		() =>
		({ getState, setState }) => {
			const { onStopCoordination } = getState();
			onStopCoordination && onStopCoordination();
			setState({
				cardKey: null,
				onStopCoordination: null,
			});
		},
};

const Store = createStore({ initialState, actions });

/**
 * Return `true` if the provided `cardKey` should be displayed as an active spotlight card.
 */
export const useIsActiveSpotlightCard = createHook<State, Actions, boolean, CardKey | CardKey[]>(
	Store,
	{
		selector: (state, cardKeys) =>
			Array.isArray(cardKeys)
				? state.cardKey !== null && cardKeys.includes(state.cardKey)
				: state.cardKey === cardKeys,
	},
);

export const useSpotlightCoordinationActions = createActionsHook<State, Actions>(Store);

export type { Actions as SpotlightCoordinationActions, CardKey as SpotlightCardKey } from './types';
export { cardKeys as spotlightCardKeys } from './constants';
