import React, { useEffect, memo, forwardRef, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	main_issueNavigator_Card_fieldSetsForIssueSearchView$key as FieldSetsForIssueSearchViewKey,
	main_issueNavigator_Card_fieldSetsForIssueSearchView$data as FieldSetsForIssueSearchViewData,
} from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_Card_fieldSetsForIssueSearchView.graphql';
import type { main_issueNavigator_Card_fragment$key as CardFragmentKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_Card_fragment.graphql';
import { toIssueKey } from '@atlassian/jira-shared-types';
import { CardBaseStyle } from '../../../../../common/ui/styled';
import { isNonNullish } from '../../../../../common/utils';
import { useFocusedIssueIndex } from '../../../../../controllers/selected-issue-state';
import FooterDI from './footer';

export type Props = {
	isSelected: boolean;
	fragment: CardFragmentKey;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	style?: any;
	// Footer shouldn't actually be optional, but Flow is complaining despite the default value
	Footer?: typeof FooterDI;
	onClick: (event: MouseEvent) => void;
	onFocus: (() => void) | undefined;
	onMounted?: () => void;
	onContextMenu: () => void;
};

type FieldSetEdges = FieldSetsForIssueSearchViewData['edges'];
type FieldSetEdge = NonNullable<FieldSetEdges>[number];

type FieldSetNode = NonNullable<FieldSetEdge>['node'];
type FieldSetNodeFields = NonNullable<FieldSetNode>['fields'];

type FieldEdges = NonNullable<FieldSetNodeFields>['edges'];
type FieldEdge = NonNullable<FieldEdges>[number];

const backgroundColorSelected = token('color.background.selected', colors.B50);
const backgroundColorHovered = token('color.background.neutral.subtle.hovered', colors.N10);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledListElement = styled.li({
	marginTop: '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledContainer = styled(CardBaseStyle)<{ isSelected?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: (props) => (props.isSelected ? token('color.text.selected', colors.B400) : 'inherit'),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: (props) =>
			props.isSelected ? backgroundColorSelected : backgroundColorHovered,
		color: 'inherit',
	},
	'&:focus': {
		color: 'inherit',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummaryStyledContainer = styled.div({
	wordWrap: 'break-word',
	overflowWrap: 'break-word',
	wordBreak: 'break-word',
	marginBottom: token('space.050', '4px'),
});

/**
 * Find a field edge by fieldId and run it through the provided mapper function.
 */
const byFieldId = <T,>(
	edges: FieldSetEdges,
	fieldId: string,
	mapperFn: (arg1: FieldEdge) => T,
): T | undefined =>
	edges
		?.map((edge: FieldSetEdge) => edge?.node?.fields?.edges?.[0])
		.filter((edge?: FieldEdge) => edge?.node?.fieldId === fieldId)
		.filter(isNonNullish)
		.map(mapperFn)[0];

const Card = forwardRef<HTMLElement, Props>(
	(
		{
			style,
			Footer = FooterDI,
			onMounted,
			onClick,
			onFocus,
			fragment,
			isSelected,
			onContextMenu,
		}: Props,
		ref,
	) => {
		const fragmentData = useFragment<CardFragmentKey>(
			graphql`
				fragment main_issueNavigator_Card_fragment on JiraIssue {
					key @required(action: THROW)
					fieldSetsForIssueSearchView(
						namespace: $namespace
						viewId: $viewId
						first: $amountOfColumns
						filterId: $filterId
					) @required(action: THROW) {
						...main_issueNavigator_Card_fieldSetsForIssueSearchView
					}
				}
			`,
			fragment,
		);
		const fieldSetsForIssueSearchViewData = useFragment<FieldSetsForIssueSearchViewKey>(
			graphql`
				fragment main_issueNavigator_Card_fieldSetsForIssueSearchView on JiraIssueFieldSetConnection {
					edges {
						node {
							fields {
								edges {
									node {
										... on JiraSingleLineTextField {
											fieldId
											name
											text
										}
										... on JiraSingleSelectUserPickerField {
											fieldId
											name
											user {
												name
												picture
											}
										}
										... on JiraIssueTypeField {
											fieldId
											issueType {
												name
												avatar {
													small
												}
											}
										}
										... on JiraStatusCategoryField {
											fieldId
											statusCategory {
												statusCategoryId
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			fragmentData.fieldSetsForIssueSearchView,
		);

		const issueKey = fragmentData.key;

		useEffect(() => {
			onMounted && onMounted();
		}, [onMounted]);

		const fieldEdges = fieldSetsForIssueSearchViewData?.edges ?? null;

		const summary = byFieldId(fieldEdges, 'summary', (summaryEdge) => summaryEdge?.node?.text);

		const avatarUser = byFieldId(
			fieldEdges,
			'assignee',
			(assigneeEdge) => assigneeEdge?.node?.user,
		);

		const avatarSrc = avatarUser?.picture || '';
		const avatarDisplayName = avatarUser?.name || '';

		const type = byFieldId(
			fieldEdges,
			'issuetype',
			(issueTypeEdge) => issueTypeEdge?.node?.issueType,
		);

		const issueType = { iconSrc: type?.avatar?.small || '', name: type?.name || '' };

		const statusCategoryId =
			byFieldId(
				fieldEdges,
				'statusCategory',
				(statusCategoryEdge) => statusCategoryEdge?.node?.statusCategory?.statusCategoryId,
			) ?? -1;
		const [, { resetFocusedIssue }] = useFocusedIssueIndex();

		return (
			<StyledListElement
				{...(fg('nin_critical_accessibility_issues')
					? { role: 'option', 'aria-selected': isSelected }
					: { 'aria-current': isSelected, 'aria-label': issueType.name })}
				data-testid="issue-navigator.ui.issue-results.detail-view.card-list.card.list-item"
			>
				<StyledContainer
					// @ts-expect-error incorrect ref type
					ref={ref ?? undefined}
					onContextMenu={onContextMenu}
					isSelected={isSelected}
					onBlur={resetFocusedIssue}
					tabIndex={isSelected ? 0 : -1}
					onClick={onClick}
					onFocus={onFocus}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={/* eslint-disable-line jira/react/no-style-attribute */ style}
					data-testid="issue-navigator.ui.issue-results.detail-view.card-list.card"
					href={`/browse/${issueKey}`}
				>
					<SummaryStyledContainer data-testid="issue-navigator.ui.issue-results.detail-view.card-list.card.summary">
						{summary}
					</SummaryStyledContainer>
					<Footer
						issueKey={toIssueKey(issueKey)}
						isSelected={isSelected}
						statusCategoryId={Number(statusCategoryId)}
						avatarSrc={avatarSrc}
						avatarDisplayName={avatarDisplayName}
						issueType={issueType}
					/>
				</StyledContainer>
			</StyledListElement>
		);
	},
);

export default memo(Card);
