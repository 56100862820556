import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	blankText: {
		id: 'issue-navigator.issue-results.detail-view.card-list.card.empty-card.blank-text',
		defaultMessage: "We can't display this issue",
		description: 'Text that shows when an issue can no longer be opened from the issue navigator',
	},
	emptyCardText: {
		id: 'issue-navigator.issue-results.detail-view.card-list.card.empty-card.empty-card-text',
		defaultMessage:
			'This issue cannot be opened because it may have been deleted or you do not have access to it',
		description: 'Text that shows when an issue can no longer be opened from the issue navigator',
	},
	tooltip: {
		id: 'issue-navigator.issue-results.detail-view.card-list.card.empty-card.tooltip',
		defaultMessage: 'Either this issue has been deleted, or you don’t have access to it.',
		description: 'Tooltip text for blank issue information icon',
	},
});
