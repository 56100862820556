import { createStore, createSubscriber, createHook, type Action } from 'react-sweet-state';

type StoreType = {
	isOpen: boolean;
};
type PropType = StoreType;
type ActionType = {
	open: () => Action<StoreType, PropType>;
	close: () => Action<StoreType, PropType>;
};

const Store = createStore<StoreType, ActionType>({
	initialState: { isOpen: false },
	actions: {
		open:
			() =>
			({ setState }) => {
				setState({ isOpen: true });
			},
		close:
			() =>
			({ setState }) => {
				setState({ isOpen: false });
			},
	},
	name: 'IssueNavigatorOrderByOpenState',
});

export const OrderByOpenSubscriber = createSubscriber<StoreType, ActionType>(Store);
export const useOrderByOpenState = createHook(Store);
