import { useMemo, useCallback } from 'react';
import type { EventHandlers } from '@atlassian/jira-native-issue-table';
import { toIssueKey } from '@atlassian/jira-shared-types';
import { useSelectedIssueDebouncedState } from '../../../../controllers/selected-issue-state';
import { useIssueSearchQuery } from '../../../../services/issue-search-query';

export const useEventHandlers = (): EventHandlers => {
	const [, { setSelectedIssueByKey, deselectIssue }] = useSelectedIssueDebouncedState();

	const { onDeleteIssue, onIssueByFieldsRefetch } = useIssueSearchQuery();
	const onIssueRefetch = onIssueByFieldsRefetch;
	// While we don't have a mutation setup for inline editing, use issue refetch as a substitute
	const onUpdateCache = onIssueByFieldsRefetch;
	const onIssueDelete = useCallback(
		(deletedIssueKey: string) => {
			const issueKeyToSelect = onDeleteIssue(deletedIssueKey);
			if (!issueKeyToSelect.length) {
				deselectIssue(false, false, false);
				return;
			}
			setSelectedIssueByKey(toIssueKey(issueKeyToSelect), false, false, false);
		},
		[onDeleteIssue, setSelectedIssueByKey, deselectIssue],
	);

	const onIssueLinkSelected = useCallback(
		(issueKey: string): boolean => {
			setSelectedIssueByKey(toIssueKey(issueKey));
			return true;
		},
		[setSelectedIssueByKey],
	);

	return useMemo(
		() => ({
			onIssueRefetch,
			onUpdateCache,
			onIssueDelete,
			onIssueLinkSelected,
		}),
		[onIssueRefetch, onUpdateCache, onIssueDelete, onIssueLinkSelected],
	);
};
