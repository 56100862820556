import findLast from 'lodash/findLast';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	type SelectOption,
	type SelectValue,
	isSingleValueOption,
} from '@atlassian/jira-native-issue-table';
import { COLUMN_TYPE_EPIC_LINK, COLUMN_TYPE_PARENT_LINK } from '../../common/constants';

const isNotinSelectedList = (selectedOptions: SelectValue, option: SelectOption) => {
	const optionFound = selectedOptions.find(
		(select) =>
			isSingleValueOption(select) && isSingleValueOption(option) && select.cursor === option.cursor,
	);
	return optionFound === undefined;
};

export const getCursorOfLastUnselectedOption = (
	options: SelectValue,
	selectedOptions: SelectValue,
	search?: string,
	endCursor?: string,
): string | undefined => {
	const flatten = options.flatMap((option) =>
		option.optionType === 'group' ? option.options : option,
	);

	const lastUnselectedOption = findLast(
		flatten,
		(option) =>
			isSingleValueOption(option) &&
			!!option.cursor &&
			isNotinSelectedList(selectedOptions, option),
	);

	let cursor =
		lastUnselectedOption && isSingleValueOption(lastUnselectedOption)
			? lastUnselectedOption?.cursor
			: undefined;

	// with a given search input, don't set cursor to undefined when there are only selected options in the list, because we query with 'ALL' options
	if (search && cursor === undefined && endCursor) {
		cursor = endCursor;
	}
	return cursor;
};

export const isOnlyUnselectableField = (fieldType: string, canEditColumns: boolean) => {
	if (ff('nin-show-field-options-as-only-unselectable_0s1y2')) {
		if (ff('com.atlassian.rm.jpo.deprecate-epic-link-nin') && fieldType === COLUMN_TYPE_EPIC_LINK) {
			return canEditColumns;
		}

		if (
			ff('com.atlassian.rm.jpo.deprecate-parent-link-nin') &&
			fieldType === COLUMN_TYPE_PARENT_LINK
		) {
			return canEditColumns;
		}
	}
	return false;
};
