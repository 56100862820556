import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		/* eslint-disable-next-line jira/i18n/id-named-by-package */
		id: 'issue-table-column-picker.column-picker.no-options-message.title',
		defaultMessage: 'We couldn’t find the field you’re looking for.',
		description: 'The title for when no options could be loaded for a search input',
	},
});
