/**
 * @generated SignedSource<<ab81aec20e5f61a93f15d19e6809fd62>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueResults_issueResults$data = {
  readonly issueSearchError: {
    readonly __typename: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_DetailView" | "main_issueNavigator_ListView_issueResults">;
  readonly " $fragmentType": "main_issueNavigator_IssueResults_issueResults";
};
export type main_issueNavigator_IssueResults_issueResults$key = {
  readonly " $data"?: main_issueNavigator_IssueResults_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_issueResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueResults_issueResults",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_ListView_issueResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_DetailView"
    },
    {
      "kind": "LinkedField",
      "name": "issueSearchError",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "6f6b389b588e5588c2faec8fc0d850b8";

export default node;
