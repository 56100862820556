/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { Box, xcss } from '@atlaskit/primitives';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { NoOptionComponentType } from '@atlassian/jira-jql-builder-basic';
import messages from './messages';

export const NoOptionsMessage: NoOptionComponentType = () => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={boxStyles}>
			<div css={iconContainerStyles}>
				<ErrorIcon primaryColor={token('color.icon.subtle', N200)} label="" />
			</div>
			<p css={titleStyles}>{formatMessage(messages.fieldNotFoundMessage)}</p>
		</Box>
	);
};

const boxStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	paddingTop: 'space.100',
	paddingLeft: 'space.250',
	paddingRight: 'space.250',
	paddingBottom: 'space.100',
	color: 'color.text.subtle',
	textAlign: 'center',
});

const titleStyles = css({
	margin: `0 0 ${token('space.100', '8px')}`,
	overflowWrap: 'anywhere',
});

const iconContainerStyles = css({
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		width: '72px',
		height: '72px',
	},
});
