// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md
// brandfolder asset id = mmwr66tqxzps49fhmn4694w
// brandfolder url = https://brandfolder.com/atlassian-brand-creative-library/atlassian-public-asset-library/#!asset/mmwr66tqxzps49fhmn4694w
import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/lock-closed-darkmode.svg'; // eslint-disable-line jira/import/no-parent-imports
import srcLight from '../../assets/lock-closed.svg'; // eslint-disable-line jira/import/no-parent-imports

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 191, 222];
export { srcLight, srcDark, viewBox };
