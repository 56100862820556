/**
 * @generated SignedSource<<11fe4bc47636ae9b25c10a6a88812a6f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_DetailView$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cardContainer_issueNavigator" | "main_issueNavigator_CardList" | "main_issueNavigator_IssueAppWithData">;
  readonly " $fragmentType": "main_issueNavigator_DetailView";
};
export type main_issueNavigator_DetailView$key = {
  readonly " $data"?: main_issueNavigator_DetailView$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_DetailView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_DetailView",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_CardList"
    },
    {
      "kind": "FragmentSpread",
      "name": "cardContainer_issueNavigator"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueAppWithData"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "da0812b0d182da0fa9208b1de105024f";

export default node;
