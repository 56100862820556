import { createStore, createContainer, createHook, type Action } from 'react-sweet-state';
import type {
	PropType,
	StoreType,
	ActionType,
	ContainerProps,
	UploadContext,
	Dialog,
	IssueId,
	IssueKey,
} from './types';

const initialState = {
	openDialog: null,
	issueKey: null,
	issueId: null,
	mediaUploadContext: null,
} as const;

const actions = {
	setDialogContext:
		(
			openDialog: Dialog | null,
			issueId: IssueId | null,
			issueKey: IssueKey | null,
			mediaUploadContext: UploadContext | null,
		): Action<StoreType, PropType> =>
		({ setState }) => {
			setState({ openDialog, issueId, issueKey, mediaUploadContext });
		},
} as const;

const Store = createStore<StoreType, ActionType>({
	initialState,
	actions,
	name: 'IssueNavigatorIssueOperationsDialogContext',
});

export const DialogContextContainer = createContainer<StoreType, ActionType, ContainerProps>(Store);

export const useIssueOperationsDialogContext = createHook(Store);

export type { UploadContext };
