import React from 'react';
import {
	ContainerContextSubscriber,
	isProjectContext,
} from '@atlassian/jira-providers-container-context';
import { SubProductUpdater } from '../sub-product-updater';
import { subProductFromProjectType } from '../transformations';

export const ProjectSubProductUpdater: Flow.AbstractComponent<{
	allowMonolithDeferral?: boolean;
}> = ({ allowMonolithDeferral = false }) => (
	<ContainerContextSubscriber>
		{({ data }) => (
			<>
				{data && isProjectContext(data) && (
					<SubProductUpdater
						subProduct={subProductFromProjectType(data.project.type)}
						allowMonolithDeferral={allowMonolithDeferral}
					/>
				)}
			</>
		)}
	</ContainerContextSubscriber>
);
