import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	flagTitle: {
		id: 'issue-navigator-actions-common.success-flag.flag-title',
		defaultMessage: '"{name}" filter saved',
		description: 'Title for the flag shown on successful filter save',
	},
	flagDescription: {
		id: 'issue-navigator-actions-common.success-flag.flag-description',
		defaultMessage: 'We’ve saved this filter successfully.',
		description: 'Description for the flag shown on successful filter save',
	},
	viewAction: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		id: 'issue-navigator-actions-common.success-flag.view-action',
		defaultMessage: 'View filter',
		description: 'Text for the link to view a filter on successful filter save',
	},
	copyAction: {
		id: 'issue-navigator-actions-common.success-flag.copy-action',
		defaultMessage: 'Copy link',
		description: 'Test for the button to copy the filter URL on successful save',
	},
	copyActionCopied: {
		id: 'issue-navigator-actions-common.success-flag.copy-action-copied',
		defaultMessage: 'Copied',
		description: 'Feedback text to user when the link was copied to clipboard successfully',
	},
});
