import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Inline } from '@atlaskit/primitives';
import { fontSize } from '@atlassian/jira-common-styles/src';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller';

type Props = {
	actions?: ReactNode;
	children: ReactNode;
};

export const SectionHeader = ({ actions, children }: Props) => {
	const isAnonymous = useIsAnonymous();

	return (
		<Inline alignBlock="center" spread="space-between" space="space.100" shouldWrap>
			<HeaderText>{children}</HeaderText>
			{actions != null && !isAnonymous && <ActionsContainer>{actions}</ActionsContainer>}
		</Inline>
	);
};

const HEADER_FONT_SIZE = fontSize;
const LINE_HEIGHT = 1.25;
export const HEADER_HEIGHT = HEADER_FONT_SIZE * LINE_HEIGHT;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderText = styled.h4({
	lineHeight: LINE_HEIGHT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${HEADER_FONT_SIZE}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsContainer = styled.div({
	lineHeight: LINE_HEIGHT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${HEADER_FONT_SIZE}px`,
	fontWeight: 'normal',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button': {
		fontWeight: 'normal',
	},
});
