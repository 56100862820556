import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	previousIssueTooltip: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.previous-button.previous-issue-tooltip',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: "Previous issue ''{issueKey}'' (Type 'k')",
		description:
			'Tooltip shown on the button to select the previous issue followed by the keyboard shortcut to perform the action.',
	},
	previousIssueWithoutKeyTooltip: {
		id: 'issue-navigator.common.issue-app.header-actions.issue-pagination.previous-button.previous-issue-without-key-tooltip',
		defaultMessage: "Previous issue (Type 'k')",
		description:
			'Tooltip shown on the button to select the previous issue followed by the keyboard shortcut to perform the action.',
	},
});

export default messages;
