import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ChevronUp from '@atlaskit/icon/glyph/chevron-up';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/import/no-restricted-paths
import { useSelectedIssueStateActions } from '../../../../../../controllers/selected-issue-state';
import messages from './messages';

export type Props = {
	/**
	 * Position of the currently selected issue in the search results.
	 */
	selectedIssuePosition: number;
};

const IssuePaginationPreviousButton = ({ selectedIssuePosition }: Props) => {
	const { formatMessage } = useIntl();
	const [, { getPreviousIssue, selectPreviousIssue }] = useSelectedIssueStateActions();
	const { issueKey } = getPreviousIssue();
	const previousIssueMessage = issueKey
		? formatMessage(messages.previousIssueTooltip, { issueKey })
		: formatMessage(messages.previousIssueWithoutKeyTooltip);

	const onClick = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			if (selectPreviousIssue()) {
				fireUIAnalytics(analyticsEvent, 'previousIssue');
			}
		},
		[selectPreviousIssue],
	);

	const isDisabled = selectedIssuePosition === 1;

	return (
		<Tooltip content={isDisabled ? null : previousIssueMessage}>
			{(tooltipProps) => (
				<Button
					appearance="subtle"
					{...tooltipProps}
					testId="issue-navigator.common.ui.issue-app.header-actions.issue-pagination.previous-button"
					iconAfter={<ChevronUp size="medium" label="" />}
					isDisabled={isDisabled}
					onClick={onClick}
					spacing="none"
				/>
			)}
		</Tooltip>
	);
};

export default IssuePaginationPreviousButton;
