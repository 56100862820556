/**
 * @generated SignedSource<<ebb5efcd0a2ac44d95acc3957f7ff9a5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_issueResults$data = {
  readonly isCappingIssueSearchResult: boolean | null | undefined;
  readonly issueSearchError: {
    readonly __typename: string;
  } | null | undefined;
  readonly totalIssueSearchResultCount: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults" | "main_issueNavigator_FullPageIssueApp" | "main_issueNavigator_Header_issueResults" | "main_issueNavigator_IssueResults_issueResults" | "topBar_issueNavigator_issueResults">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_issueResults";
};
export type main_issueNavigator_IssueNavigatorUI_issueResults$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_issueResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_issueResults",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "topBar_issueNavigator_issueResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_Header_issueResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "jqlBuilder_issueNavigator_JQLBuilderWrapper_issueResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueResults_issueResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_FullPageIssueApp"
    },
    {
      "kind": "LinkedField",
      "name": "issueSearchError",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "totalIssueSearchResultCount"
    },
    {
      "kind": "ScalarField",
      "name": "isCappingIssueSearchResult"
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "2badab2f4a91c7cb41cd705ebf77707f";

export default node;
