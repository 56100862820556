import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { ButtonGroup } from '@atlaskit/button';
import type { issuePagination_issueNavigator$key as IssuePaginationKey } from '@atlassian/jira-relay/src/__generated__/issuePagination_issueNavigator.graphql';
import TotalIssueCount from '../../../total-issue-count';
import messages from './messages';
import IssuePaginationNextButton from './next-button';
import IssuePaginationPreviousButton from './previous-button';

export type Props = {
	/**
	 * Relay fragment for issue search data.
	 */
	issueResults: IssuePaginationKey;
	/**
	 * Position of the currently selected issue in the search results.
	 */
	selectedIssuePosition: number;
};

const IssuePagination = ({ issueResults, selectedIssuePosition }: Props) => {
	const issueResultsData = useFragment<IssuePaginationKey>(
		graphql`
			fragment issuePagination_issueNavigator on JiraIssueConnection {
				totalIssueSearchResultCount @required(action: THROW)
				isCappingIssueSearchResult
			}
		`,
		issueResults,
	);

	const { totalIssueSearchResultCount, isCappingIssueSearchResult } = issueResultsData;

	return (
		<>
			{/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */}
			<PaginationTextContainer data-testid="issue-pagination.pagination-text">
				{totalIssueSearchResultCount <= 0 ? (
					selectedIssuePosition
				) : (
					<TotalIssueCount
						prependMessage={messages.issueIndexTotalKnown}
						prependMessageValues={{
							issueIndex: selectedIssuePosition,
						}}
						isCapped={isCappingIssueSearchResult ?? false}
						stableCount={totalIssueSearchResultCount}
					/>
				)}
			</PaginationTextContainer>

			<ButtonGroup>
				<IssuePaginationPreviousButton selectedIssuePosition={selectedIssuePosition} />
				<IssuePaginationNextButton
					totalResultCount={totalIssueSearchResultCount}
					selectedIssuePosition={selectedIssuePosition}
					isCappingIssueSearchResult={isCappingIssueSearchResult ?? false}
				/>
			</ButtonGroup>
		</>
	);
};

export default IssuePagination;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PaginationTextContainer = styled.span({
	display: 'flex',
	alignItems: 'center',
});
