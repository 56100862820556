import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { useTab } from '@atlaskit/tabs';
import { ConditionalTooltip } from '@atlassian/jira-jql-builder-common';

type Props = {
	label: string;
	maxWidth?: string;
};

const Tab = ({ label, maxWidth = '100%' }: Props) => {
	const [labelOverflows, setLabelOverflows] = useState<boolean>(false);

	const tabAttributes = useTab();

	const onLabelRef = useCallback(
		(node: HTMLElement | null) => {
			if (node != null) {
				const isOverflow = Boolean(node.offsetWidth < node.scrollWidth);

				isOverflow !== labelOverflows && setLabelOverflows(isOverflow);
			}
		},
		[labelOverflows],
	);
	return (
		<TabStyled maxWidthValue={maxWidth} {...tabAttributes}>
			<ConditionalTooltip condition={labelOverflows} content={label} position="top">
				<Box ref={onLabelRef} xcss={textStyles}>
					{label}
				</Box>
			</ConditionalTooltip>
		</TabStyled>
	);
};

const textStyles = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabStyled = styled.div<{
	maxWidthValue: string;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ maxWidthValue }) => maxWidthValue,
	boxSizing: 'border-box',
});

export default Tab;
